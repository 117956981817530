<template>
  <v-app>
    <v-main class="d-flex align-center justify-center full-size">
      <v-container style="max-width: 1024px;">

        <v-app-bar class="mb-6 app-bar pa-2" style="background-color: var(--black-1);" elevation="0" app>
          <v-toolbar-title>
            <v-img :src="require('@/assets/ic_logo.png')" alt="Logo" width="40" height="40" contain></v-img>
          </v-toolbar-title>
        </v-app-bar>
        
        <p class="white--text text-body-1 mt-4"><v-icon color="green" size="22" class="mb-1 mr-2">mdi-check-circle</v-icon>구매가 완료되었습니다.</p>
        
        <div class="countdown-container d-flex align-center justify-center mt-4">
          <p class="white--text text-body-2">{{ countdown }}</p>
        </div>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'PayCompleteView',
  data() {
    return {
      countdown: 3
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.startCountdown();
  },
  methods: {
    startCountdown() {
      const timer = setInterval(() => {
        this.countdown--;
        if (this.countdown === 0) {
          clearInterval(timer);
          this.$router.push('/');
        }
      }, 1000);
    }
  }
}
</script>

<style scoped>
.countdown-container {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--black-2);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto; /* 중앙 정렬을 위한 margin 설정 */
}
</style>
<template>
  <v-app>
    <v-main class="d-flex align-center justify-center full-size">
      <v-container style="max-width: 1024px;">

        <v-container class="d-flex align-center justify-center full-size mb-2" style="max-width: 1024px;">
          <v-img :src="require('@/assets/ic_logo.png')" alt="Logo" height="80" width="80" contain></v-img>
        </v-container>

        <v-container>
            <p class="white--text font-weight-bold">인터넷 연결 끊김</p>
            <br>
            <p class="grey--text" style="font-size: 14px !important;">인터넷 연결을 기다리고 있어요 ···</p>
        </v-container>
        
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'NoInternetView',
  mounted() {
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>

</style>
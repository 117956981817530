export default {
    state: {
      isLoading: false,
    },
    mutations: {
      SET_LOADING(state, isLoading) {
        state.isLoading = isLoading;
      },
    },
    actions: {
      setLoading({ commit }, isLoading) {
        commit('SET_LOADING', isLoading);
      },
    },
    getters: {
      isLoading: state => state.isLoading,
    }
  }
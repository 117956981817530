<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1024px;">
        <v-app-bar 
          color="var(--black-1)" 
          :style="{ borderBottom: '1px solid var(--black-2)' }" 
          dark 
          flat 
          class="app-bar" 
          app
        >
          <v-btn icon @click="backBtnCkd">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <p>{{ $t('filterTitle') }}</p>
          <v-spacer></v-spacer>
          <v-btn 
            text 
            @click="resetBtnCkd" 
            class="pa-0 reset-btn"
          >
            {{ $t('filterClear') }}
          </v-btn>
        </v-app-bar>
        
        <v-container>
          <v-row>
            <v-col class="pb-0 mb-2">
              <v-select
                :label="$t('filterGenderLabel')"
                dark
                :items="genders" 
                v-model="selectedGender" 
                item-text="text"
                item-value="value"
                outlined 
                hide-details 
                color="white" 
                class="ipt mt-0 profile-edit-gender-ipt"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pb-0 mb-2">
              <v-select
                :label="$t('filterOrientationTypeLabel')"
                dark
                :items="orientationTypes" 
                v-model="selectedOrientationType" 
                item-text="text"
                item-value="value"
                outlined 
                hide-details 
                color="white" 
                class="ipt mt-0 profile-edit-gender-ipt"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pb-0 mb-2">
              <v-select
                :label="$t('filterAgeLabel')"
                dark
                :items="ages" 
                v-model="selectedAge" 
                item-text="text"
                item-value="value"
                outlined 
                hide-details 
                color="white" 
                class="ipt mt-0 profile-edit-gender-ipt"
              ></v-select>
            </v-col>
          </v-row>
          
          <v-row>
            <v-col class="pb-0">
              <v-select
                :label="$t('filterLocationLabel')"
                dark
                :items="locations" 
                v-model="selectedLocation" 
                item-text="text"
                item-value="value"
                outlined 
                hide-details 
                color="white" 
                class="ipt mt-0 profile-edit-gender-ipt"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-btn 
            large
            color="var(--red)" 
            class="mt-2 white--text font-weight-bold" 
            @click="completeBtnCkd" 
            block
          >
            {{ $t('filterApply') }}
          </v-btn>
        </v-container>

        <v-snackbar 
          :min-width="0" 
          v-model="snackbar.visibility"
        >
          <div class="text-center">
            <span style="white-space: normal; word-break: break-word;">
              {{ snackbar.text }}
            </span>
          </div>
        </v-snackbar>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import shared from '@/rest/shared';

export default {
  name: 'FilterView',
  data() {
    return {
      selectedGender: 'all',
      genders: [
        { text: this.$t('filterGenderAll'), value: 'all' },
        { text: this.$t('filterGenderM'), value: 'male' },
        { text: this.$t('filterGenderF'), value: 'female' }
      ],
      selectedOrientationType: 'all',
      orientationTypes: [
        { text: this.$t('filterOrientationTypeAll'), value: 'all' },
        { text: this.$t('filterOrientationTypeTop'), value: 'top' },
        { text: this.$t('filterOrientationTypeBottom'), value: 'bottom' },
        { text: this.$t('filterOrientationTypeSwitch'), value: 'switch' }
      ],
      selectedAge: 'all',
      ages: [
        { text: this.$t('filterAgeAll'), value: 'all' },
        { text: this.$t('filterAge20s'), value: '20s' },
        { text: this.$t('filterAge30s'), value: '30s' },
        { text: this.$t('filterAge40s'), value: '40s' }
      ],
      selectedLocation: 'all',
      locations: [
        { text: this.$t('filterLocationAll'), value: 'all' },
        { text: '서울', value: '서울' },
        { text: '경기', value: '경기' },
        { text: '인천', value: '인천' },
        { text: '강원', value: '강원' },
        { text: '충북', value: '충북' },
        { text: '충남', value: '충남' },
        { text: '대전', value: '대전' },
        { text: '경북', value: '경북' },
        { text: '경남', value: '경남' },
        { text: '대구', value: '대구' },
        { text: '울산', value: '울산' },
        { text: '부산', value: '부산' },
        { text: '전북', value: '전북' },
        { text: '전남', value: '전남' },
        { text: '광주', value: '광주' },
        { text: '제주', value: '제주' }
      ],
      snackbar: {
        text: "",
        visibility: false,
      }
    };
  },
  methods: {
    completeBtnCkd() {
      let filter = '';
      if (this.selectedGender !== 'all') {
        filter = `AND u.gender = '${this.selectedGender}' `;
      }
      if (this.selectedLocation !== 'all') {
        filter += `AND ue.location = '${this.selectedLocation}' `;
      }
      if (this.selectedOrientationType !== 'all') {
        filter += `AND u.orientation_type = '${this.selectedOrientationType}' `;
      }
      if (this.selectedAge !== 'all') {
        switch(this.selectedAge) {
          case '20s':
            filter += `AND FLOOR((YEAR(CURDATE()) - LEFT(birth, 4)) - (DATE_FORMAT(CURDATE(), '00-%m-%d') < DATE_FORMAT(STR_TO_DATE(birth, '%Y%m%d'), '00-%m-%d'))) BETWEEN 20 AND 29 `;
            break;
          case '30s':
            filter += `AND FLOOR((YEAR(CURDATE()) - LEFT(birth, 4)) - (DATE_FORMAT(CURDATE(), '00-%m-%d') < DATE_FORMAT(STR_TO_DATE(birth, '%Y%m%d'), '00-%m-%d'))) BETWEEN 30 AND 39 `;
            break;
          case '40s':
            filter += `AND FLOOR((YEAR(CURDATE()) - LEFT(birth, 4)) - (DATE_FORMAT(CURDATE(), '00-%m-%d') < DATE_FORMAT(STR_TO_DATE(birth, '%Y%m%d'), '00-%m-%d'))) >= 40 `;
            break;
          default:
            this.selectedAge = 'all'
            break;
        }
      }
      shared.IS_FILTER_APPLYED = true;
      this.$store.commit('setFilter', filter);
      this.$router.go(-1);
    },
    resetBtnCkd() {
      this.selectedGender = 'all';
      this.selectedOrientationType = 'all';
      this.selectedAge = 'all';
      this.selectedLocation = 'all';
    },
    backBtnCkd() {
      this.$router.go(-1);
    }
  },
  created() {
    if (this.$store.getters.isLoggedIn == null) {
      this.$router.push('/');
      return;
    }
    const savedFilter = this.$store.getters.getFilter;
    if (savedFilter) {
      if (savedFilter.includes("u.gender = 'male'")) {
        this.selectedGender = 'male';
      } else if (savedFilter.includes("u.gender = 'female'")) {
        this.selectedGender = 'female';
      }

      this.locations.forEach(location => {
        if (location.value !== 'all' && savedFilter.includes(`ue.location = '${location.value}'`)) {
          this.selectedLocation = location.value;
        }
      });

      if (savedFilter.includes("u.orientation_type = 'top'")) {
        this.selectedOrientationType = 'top';
      } else if (savedFilter.includes("u.orientation_type = 'bottom'")) {
        this.selectedOrientationType = 'bottom';
      }
      else if (savedFilter.includes("u.orientation_type = 'switch'")) {
        this.selectedOrientationType = 'switch';
      }

      if (savedFilter.includes("FLOOR((YEAR(CURDATE()) - LEFT(birth, 4)) - (DATE_FORMAT(CURDATE(), '00-%m-%d') < DATE_FORMAT(STR_TO_DATE(birth, '%Y%m%d'), '00-%m-%d'))) BETWEEN 20 AND 29")) {
        this.selectedAge = '20s';
      } else if (savedFilter.includes("FLOOR((YEAR(CURDATE()) - LEFT(birth, 4)) - (DATE_FORMAT(CURDATE(), '00-%m-%d') < DATE_FORMAT(STR_TO_DATE(birth, '%Y%m%d'), '00-%m-%d'))) BETWEEN 30 AND 39")) {
        this.selectedAge = '30s';
      } else if (savedFilter.includes("AND FLOOR((YEAR(CURDATE()) - LEFT(birth, 4)) - (DATE_FORMAT(CURDATE(), '00-%m-%d') < DATE_FORMAT(STR_TO_DATE(birth, '%Y%m%d'), '00-%m-%d'))) >= 40")) {
        this.selectedAge = '40s';
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
</style>
<template>
    <v-app>
    <v-main>
      <v-container class="d-flex align-center justify-center full-size" style="max-width: 1024px;">
        <v-img :src="require('@/assets/ic_logo.png')" alt="Logo" height="80" width="80" contain></v-img>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'SplashView',
  mounted() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      if (this.$store.getters.isLoggedIn) {
        this.$router.push({ name: 'MainView' });
      } else {
        this.$router.push({ name: 'LoginView' });
      }
    }, 1000);
  }
}
</script>

<style scoped>

</style>

<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1024px;">

        <v-app-bar color="var(--black-1)" :style="{ borderBottom: '1px solid var(--black-2)' }" dark flat class="app-bar" app>
          <v-btn icon @click="backBtnCkd">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <p>{{ $t('billingsListTitle') }}</p>
        </v-app-bar>

        <v-container class="pa-0" fluid>
          <v-card v-for="(billing, index) in billings" :key="index" class="container--black2 text-left pa-0" :class="{ 'mt-3': index > 0 }">
            <v-card-text class="d-flex align-center justify-space-between" style="color:white; height: 100%;">
              <div class="d-flex align-center">
                <v-img
                  :src="require('@/assets/ic_coin.png')"
                  max-height="24"
                  max-width="24"
                  contain
                  class="d-inline-flex align-center mr-1"
                ></v-img>
                <span class="text-h6"><b>{{ billing.name }}</b></span>
              </div>
              <div class="d-flex align-center">
                <span class="mr-3"></span>
                <p class="mr-4">{{ billing.price }}₩</p>
                <v-btn style="background-color: var(--black-4) !important;color: white;" @click="refundBtnCkd(billing)" v-if="billing.can_refund == 1">
                  {{ $t('billingsRefund') }}
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-container>

        <v-snackbar 
          :min-width="0" 
          v-model="snackbar.visibility"
        >
          <div class="text-center">
            <span style="white-space: normal; word-break: break-word;">
              {{ snackbar.text }}
            </span>
          </div>
        </v-snackbar>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// import { AnalyticsFunctions } from '../rest/analytics/functions.js';
// import { AnalyticsParams } from '../rest/analytics/params.js';

export default {
  name: 'BillingsListView',
  data() {
    return {
      point: 0,
      billings : [],
      snackbar: {
        text: "",
        visibility: false,
      }
    };
  },
  methods: {
    getBillings() {
      this.$store.dispatch('billingsGet', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID
      })
      .then((result) => {
        if(result.success) {
          this.billings = this.$store.getters.getBillings;
        }
        else {
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
        }
      })
    },
    refundBtnCkd(billing) {
      this.$store.dispatch('billingsCancelRequest', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
        oid: billing.oid
      })
      .then((result) => {
        if(result.success) {
          this.snackbar.text = this.$t('billingsCancelRequestSuccess');
          this.snackbar.visibility = true;
        }
        else {
          switch (result.code) {
            case '409':
              this.snackbar.text = this.$t('billingsCancelRequestAlready');
              break;
            default:
              this.snackbar.text = `${this.$t('serverError')} code: ${result.code}`;
              break;
          }
          this.snackbar.visibility = true;
        }
      })
    },
    backBtnCkd() {
      this.$router.go(-1);
    }
  },
  created() {
    if(this.$store.getters.isLoggedIn == null) {
      this.$router.push('/');
      return;
    }
    this.getBillings();
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>

</style>
<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container class="pa-0" style="max-width: 1024px;">

        <v-app-bar color="var(--black-1)" :style="{ borderBottom: '1px solid var(--black-2)' }" dark flat class="app-bar" app>
          <v-btn icon @click="backBtnCkd">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <p>{{ $t('profileInitTitle') }}</p>
        </v-app-bar>
        
        <v-container class="">

          <v-container class="ma-0 pa-0 mb-3" fluid>
            <v-row 
              align="center" 
              justify="center" 
              no-gutters 
              class="py-3 ma-0" 
              style="color:white;background-color: var(--black-2); cursor: pointer;"
              @click="openBDSMTest"
            >
              <v-col cols="auto" class="d-flex align-center">
                <p class="font-weight-bold caption ma-0">{{ $t('profileInitBDSMTest') }}</p>
              </v-col>
            </v-row>
          </v-container>

          <v-row>
            <v-col class="d-flex justify-start pt-5">
              <v-btn
                  @click="profileImageSelectBtnCkd" 
                  height="100"
                  width="100"
                  icon>
                <v-img
                  :src="selectedProfileImage"
                  height="100"
                  width="100"
                  class="rounded-circle"/>
              </v-btn>
            </v-col>
            <input type="file" ref="fileInput" @change="onFileChange" style="display: none" />
          </v-row>

          <v-row class="mb-2">
            <v-col class="pb-0">
              <v-text-field 
                :label="$t('profileInitNickname')"
                dark
                v-model="nickname"
                outlined 
                hide-details 
                color="white" 
                class="ipt"></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mb-2">
            <v-col class="pb-0">
              <v-select 
                dark 
                :label="$t('profileInitOrientation')" 
                :items="orientations" 
                v-model="selectedOrientation" 
                item-text="text"
                item-value="value"
                outlined 
                hide-details 
                color="white" 
                class="ipt mt-0"></v-select>
            </v-col>
          </v-row>
          
          <v-row class="mb-2">
            <v-col class="pb-0">
              <v-select 
                dark
                :label="$t('profileInitGender')" 
                :items="genders" 
                v-model="selectedGender" 
                item-text="text"
                item-value="value"
                outlined 
                hide-details 
                color="white" 
                class="ipt mt-0"></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="pb-1">
              <v-textarea 
                dark 
                :label="$t('profileInitBio')"
                :placeholder="$t('profileInitBioPlaceholder')"
                rows="5" 
                auto-grow 
                v-model="bio" 
                outlined 
                hide-details 
                color="white" 
                class="ipt mt-0"></v-textarea>
            </v-col>
          </v-row>

          <v-divider class="mt-8 mb-6" dark></v-divider>

          <v-row class="mb-2">
            <v-col class="pb-0">
              <v-row no-gutters>
                <v-col cols="4" class="pr-2">
                  <v-select
                    dark
                    outlined
                    hide-details
                    color="white"
                    class="ipt mt-0"
                    v-model="selectedBirthYear"
                    :items="birthYears"
                    :label="$t('profileInitBirthYear')"
                  ></v-select>
                </v-col>
                <v-col cols="4" class="px-1">
                  <v-select
                    dark
                    outlined
                    hide-details
                    color="white"
                    class="ipt mt-0"
                    v-model="selectedBirthMonth"
                    :items="birthMonths"
                    :label="$t('profileInitBirthMonth')"
                  ></v-select>
                </v-col>
                <v-col cols="4" class="pl-2">
                  <v-select
                    dark
                    outlined
                    hide-details
                    color="white"
                    class="ipt mt-0"
                    v-model="selectedBirthDay"
                    :items="birthDays"
                    :label="$t('profileInitBirthDay')"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="mb-2">
            <v-col class="pb-0">
              <v-select
                dark
                :label="$t('profileInitLocation')"
                :items="locations"
                v-model="selectedLocation"
                outlined
                hide-details
                color="white"
                class="ipt mt-0"
              ></v-select>
            </v-col>
          </v-row>

          <v-row class="mb-2">
            <v-col class="pb-0">
              <v-combobox
                dark
                :label="$t('profileInitPreferences')"
                :items="preferences"
                v-model="selectedPlayPreferences"
                outlined
                hide-details
                color="white"
                class="ipt mt-0"
                multiple
                chips
                deletable-chips
                small-chips
                ref="combobox1"
                readonly
                @focus="toggleCombobox1"
              ></v-combobox>
            </v-col>
          </v-row>

          <v-row class="mb-2">
            <v-col class="pb-0">
              <v-combobox
                dark
                :label="$t('profileInitExperience')"
                :items="expriences"
                v-model="selectedPlayExperiences"
                @change="experienceBtnCkd"
                outlined
                hide-details
                color="white"
                class="ipt mt-0"
                multiple
                chips
                deletable-chips
                small-chips
                ref="combobox2"
                readonly
                @focus="toggleCombobox2"
              ></v-combobox>
            </v-col>
          </v-row>

          <v-container class="mt-5 mb-1 pa-0 d-flex justify-end" fluid>
            <v-checkbox
              dark
              color="var(--red)"
              label="경험없음"
              v-model="noExperience"
              @change="noExperienceBtnCkd"
              class="ma-0 pa-0"
            ></v-checkbox>
          </v-container>

          <v-row class="mb-2">
            <v-col class="pb-0">
              <v-select
                dark
                :label="$t('profileInitRelationType')"
                :items="relationshipTypes"
                v-model="selectedRelationshipType"
                outlined
                hide-details
                color="white"
                class="ipt mt-0"
              ></v-select>
            </v-col>
          </v-row>

          <v-btn 
            color="var(--red)" 
            large
            class="mt-6 white--text font-weight-bold" 
            style="margin-bottom: 60px !important;"
            @click="completeBtnCkd"
            :disabled="isCompleteBtnDisabled"
            block
          >
            {{ $t('profileInitComplete') }}
          </v-btn>
          
        </v-container>

        <v-snackbar 
          :min-width="0" 
          v-model="snackbar.visibility"
        >
          <div class="text-center">
            <span style="white-space: normal; word-break: break-word;">
              {{ snackbar.text }}
            </span>
          </div>
        </v-snackbar>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import shared from '@/rest/shared';

export default {
  name: 'ProfileInitView',
  data() {
    return {
      isSubmitting: false,
      selectedProfileImage: require('@/assets/ic_profile_placeholder.png'),
      selectedProfileImageFile: null,
      nickname: '',
      selectedOrientation: null,
      orientations: [
        { text: this.$t('orientation.owner'), value: 'owner' },
        { text: this.$t('orientation.daddy'), value: 'daddy' },
        { text: this.$t('orientation.mommy'), value: 'mommy' },
        { text: this.$t('orientation.dominant'), value: 'dominant' },
        { text: this.$t('orientation.master'), value: 'master' },
        { text: this.$t('orientation.hunter'), value: 'hunter' },
        { text: this.$t('orientation.brattamer'), value: 'brattamer' },
        { text: this.$t('orientation.degrader'), value: 'degrader' },
        { text: this.$t('orientation.rigger'), value: 'rigger' },
        { text: this.$t('orientation.boss'), value: 'boss' },
        { text: this.$t('orientation.sadist'), value: 'sadist' },
        { text: this.$t('orientation.spanker'), value: 'spanker' },
        { text: this.$t('orientation.pet'), value: 'pet' },
        { text: this.$t('orientation.little'), value: 'little' },
        { text: this.$t('orientation.submissive'), value: 'submissive' },
        { text: this.$t('orientation.slave'), value: 'slave' },
        { text: this.$t('orientation.prey'), value: 'prey' },
        { text: this.$t('orientation.brat'), value: 'brat' },
        { text: this.$t('orientation.degradee'), value: 'degradee' },
        { text: this.$t('orientation.ropebunny'), value: 'ropebunny' },
        { text: this.$t('orientation.servant'), value: 'servant' },
        { text: this.$t('orientation.masochist'), value: 'masochist' },
        { text: this.$t('orientation.spankee'), value: 'spankee' },
        { text: this.$t('orientation.switch'), value: 'switch' },
      ],
      selectedGender: null,
      genders: [
        { text: this.$t('profileInitGenderM'), value: 'male' },
        { text: this.$t('profileInitGenderF'), value: 'female' }
      ],
      bio: '',
      selectedBirthYear: null,
      selectedBirthMonth: null,
      selectedBirthDay: null,
      birthYears: [],
      birthMonths: Array.from({length: 12}, (_, i) => i + 1),
      birthDays: Array.from({length: 31}, (_, i) => i + 1),
      selectedLocation: null,
      locations: [
        { text: '서울', value: '서울' },
        { text: '경기', value: '경기' },
        { text: '인천', value: '인천' },
        { text: '강원', value: '강원' },
        { text: '충북', value: '충북' },
        { text: '충남', value: '충남' },
        { text: '대전', value: '대전' },
        { text: '경북', value: '경북' },
        { text: '경남', value: '경남' },
        { text: '대구', value: '대구' },
        { text: '울산', value: '울산' },
        { text: '부산', value: '부산' },
        { text: '전북', value: '전북' },
        { text: '전남', value: '전남' },
        { text: '광주', value: '광주' },
        { text: '제주', value: '제주' }
      ],
      selectedPlayPreferences: null,
      preferences: [
        { text: this.$t('playAbuse'), value: 'abuse' },
        { text: this.$t('playMassage'), value: 'massage' },
        { text: this.$t('playWorship'), value: 'worship' },
        { text: this.$t('playBiting'), value: 'biting' },
        { text: this.$t('playGrooming'), value: 'grooming' },
        { text: this.$t('playOnline'), value: 'online' },
        { text: this.$t('playCosplay'), value: 'cosplay' },
        { text: this.$t('playRoleplay'), value: 'roleplay' },
        { text: this.$t('playClown'), value: 'clown' },
        { text: this.$t('playMannequin'), value: 'mannequin' },
        { text: this.$t('playAnimal'), value: 'animal' },
        { text: this.$t('playFurniture'), value: 'furniture' },
        { text: this.$t('playFood'), value: 'food' },
        { text: this.$t('playMission'), value: 'mission' },
        { text: this.$t('playShame'), value: 'shame' },
        { text: this.$t('playNeglect'), value: 'neglect' },
        { text: this.$t('playResist'), value: 'resist' },
        { text: this.$t('playBreathControl'), value: 'breath_control' },
        { text: this.$t('playOrgasmControl'), value: 'orgasm_control' },
        { text: this.$t('playMindControl'), value: 'mind_control' },
        { text: this.$t('playBlind'), value: 'blind' },
        { text: this.$t('playBondage'), value: 'bondage' },
        { text: this.$t('playHanging'), value: 'hanging' },
        { text: this.$t('playSpank'), value: 'spank' },
        { text: this.$t('playFingering'), value: 'fingering' },
        { text: this.$t('playAnal'), value: 'anal' },
        { text: this.$t('playEnema'), value: 'enema' },
        { text: this.$t('playSpit'), value: 'spit' },
        { text: this.$t('playGoldenShower'), value: 'golden_shower' },
        { text: this.$t('playScat'), value: 'scat' }
      ],
      noExperience: false,
      selectedPlayExperiences: null,
      expriences: [
        { text: this.$t('playAbuse'), value: 'abuse' },
        { text: this.$t('playMassage'), value: 'massage' },
        { text: this.$t('playWorship'), value: 'worship' },
        { text: this.$t('playBiting'), value: 'biting' },
        { text: this.$t('playGrooming'), value: 'grooming' },
        { text: this.$t('playOnline'), value: 'online' },
        { text: this.$t('playCosplay'), value: 'cosplay' },
        { text: this.$t('playRoleplay'), value: 'roleplay' },
        { text: this.$t('playClown'), value: 'clown' },
        { text: this.$t('playMannequin'), value: 'mannequin' },
        { text: this.$t('playAnimal'), value: 'animal' },
        { text: this.$t('playFurniture'), value: 'furniture' },
        { text: this.$t('playFood'), value: 'food' },
        { text: this.$t('playMission'), value: 'mission' },
        { text: this.$t('playShame'), value: 'shame' },
        { text: this.$t('playNeglect'), value: 'neglect' },
        { text: this.$t('playResist'), value: 'resist' },
        { text: this.$t('playBreathControl'), value: 'breath_control' },
        { text: this.$t('playOrgasmControl'), value: 'orgasm_control' },
        { text: this.$t('playMindControl'), value: 'mind_control' },
        { text: this.$t('playBlind'), value: 'blind' },
        { text: this.$t('playBondage'), value: 'bondage' },
        { text: this.$t('playHanging'), value: 'hanging' },
        { text: this.$t('playSpank'), value: 'spank' },
        { text: this.$t('playFingering'), value: 'fingering' },
        { text: this.$t('playAnal'), value: 'anal' },
        { text: this.$t('playEnema'), value: 'enema' },
        { text: this.$t('playSpit'), value: 'spit' },
        { text: this.$t('playGoldenShower'), value: 'golden_shower' },
        { text: this.$t('playScat'), value: 'scat' }
      ],
      selectedRelationshipType: null,
      relationshipTypes: [
        {text: this.$t('relationTypeFriend'), value:'friend' },
        {text: this.$t('relationTypePartner'), value:'partner' },
        {text: this.$t('relationTypeCouple'), value:'couple' },
      ],
      snackbar: {
        text: "",
        visibility: false,
      }
    };
  },
  computed: {
    formattedBirthdate() {
      if (this.selectedBirthYear && this.selectedBirthMonth && this.selectedBirthDay) {
        return `${this.selectedBirthYear}${this.selectedBirthMonth.toString().padStart(2, '0')}${this.selectedBirthDay.toString().padStart(2, '0')}`;
      }
      return '';
    },
    isFormValid() {
      return this.selectedProfileImageFile !== null &&
            this.nickname !== "" &&
            this.nickname.length <= 10 &&
            this.selectedOrientation !== null &&
            this.selectedGender !== null &&
            this.bio !== "" &&
            this.bio.length <= 140 &&
            this.selectedBirthYear !== null &&
            this.selectedBirthMonth !== null &&
            this.selectedBirthDay !== null &&
            this.selectedLocation !== null &&
            (this.selectedPlayPreferences !== null && this.selectedPlayPreferences.length > 0) &&
            (this.noExperience || (this.selectedPlayExperiences !== null && this.selectedPlayExperiences.length > 0)) &&
            this.selectedRelationshipType !== null;
    },
    isCompleteBtnDisabled() {
      return !this.isFormValid || this.isSubmitting;
    }
  },
  methods: {
    completeBtnCkd() {
      if (this.isSubmitting) return;
      if (!this.isFormValid) {
        if (this.nickname === "") {
          this.snackbar.text = this.$t('nicknameEmpty');
        } else if (this.nickname.length > 10) {
          this.snackbar.text = this.$t('nicknameTooLong');
        } else if (this.selectedOrientation === "") {
          this.snackbar.text = this.$t('orientationEmpty');
        } else if (this.bio === "") {
          this.snackbar.text = this.$t('bioEmpty');
        } else if (this.bio.length > 140) {
          this.snackbar.text = this.$t('bioTooLong');
        } else if (this.selectedBirthYear === null) {
          this.snackbar.text = this.$t('birthYearEmpty');
        } else if (this.selectedBirthMonth === null) {
          this.snackbar.text = this.$t('birthMonthEmpty');
        } else if (this.selectedBirthDay === null) {
          this.snackbar.text = this.$t('birtDayEmpty');
        } else if (this.selectedLocation === null) {
          this.snackbar.text = this.$t('locationEmpty');
        } else if (this.selectedPlayPreferences === null || this.selectedPlayPreferences.length === 0) {
          this.snackbar.text = this.$t('playPreferencesEmpty');
        } else if (!this.noExperience && (this.selectedPlayExperiences === null || this.selectedPlayExperiences.length === 0)) {
          this.snackbar.text = this.$t('playExperiencesEmpty');
        } else if (this.selectedRelationshipType === null) {
          this.snackbar.text = this.$t('relationTypeEmpty');
        }
      }
      this.isSubmitting = true;

      if (['owner', 'daddy', 'mommy', 'dominant', 'master', 'hunter', 'brattamer', 'degrader', 'rigger', 'boss', 'sadist', 'spanker'].includes(this.selectedOrientation)) {
        this.orientation_type = 'top';
      } else if (['pet', 'little', 'submissive', 'slave', 'prey', 'brat', 'degradee', 'ropebunny', 'servant', 'masochist', 'spankee'].includes(this.selectedOrientation)) {
        this.orientation_type = 'bottom';
      } else if (this.selectedOrientation === 'switch') {
        this.orientation_type = 'switch';
      }

      this.$store.dispatch('userInit', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
        gender: this.selectedGender,
        nickname: this.nickname,
        orientation_type: this.orientation_type,
        orientation: this.selectedOrientation,
        bio: this.bio,
        birth: this.formattedBirthdate,
        location: this.selectedLocation,
        playPreferences: this.selectedPlayPreferences.map(item => item.value).join(','),
        playExperiences: this.noExperience ? 'null' : this.selectedPlayExperiences.map(item => item.value).join(','),
        relationshipType: this.selectedRelationshipType,
        profileImage: this.selectedProfileImageFile,
      })
      .then(result => {
        this.isSubmitting = false;
        if (result.success) {
          shared.IS_PROFILE_UNSET = false;
          this.$router.push('/main');
        } else {
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
        }
      })
      .catch(error => {
        this.isSubmitting = false;
        console.error('User init error:', error);
        this.snackbar.text = this.$t('serverError');
        this.snackbar.visibility = true;
      });
    },
    profileImageSelectBtnCkd() {
      this.$refs.fileInput.click();
    },
    experienceBtnCkd() {
      this.noExperience = false;
    },
    noExperienceBtnCkd() {
      if(this.noExperience) {
        this.selectedPlayExperiences = null;
      }
    },
    backBtnCkd() {
      this.$router.go(-1);
    },
    onFileChange(event) {
      this.selectedProfileImageFile = event.target.files[0];
      if (this.selectedProfileImageFile) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.selectedProfileImage = e.target.result;
        };
        reader.readAsDataURL(this.selectedProfileImageFile);
      }
    },
    generateBirthYears() {
      const currentYear = new Date().getFullYear()
      for (let year = currentYear - 19; year >= 1950; year--) {
        this.birthYears.push(year.toString())
      }
    },
    toggleCombobox1() {
      this.$refs.combobox1.isMenuActive = true;
    },
    toggleCombobox2() {
      this.$refs.combobox2.isMenuActive = true;
    },
    openBDSMTest() {
      window.open('https://m.site.naver.com/1xkuB', '_blank');
    }
  },
  created() {
    if(this.$store.getters.isLoggedIn == null) {
      this.$router.push('/');
      return;
    }
    this.generateBirthYears()
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.v-btn-toggle {
  width: 100%;
}
.v-btn-toggle .v-btn {
  width: 50%;
}
.selected-btn {
  background-color: var(--red) !important;
  color: white !important;
}
.v-btn-toggle .v-btn:not(.selected-btn) {
  background-color: var(--black-2) !important;
  color: white !important;
}
</style>
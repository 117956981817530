import api from '../../rest/api';

const initialState = {
  threads: []
};

export default {
  state: { ...initialState },
  mutations: {
    setThreads(state, threads) {
      state.threads = threads;
    },
  },
  getters: {
    getThreads(state) {
      return state.threads;
    },
  },
  actions: {
    async threadGet({ commit }, { accessToken, uid, offset }) {
      try {
        const response = await api.threadGet(accessToken, uid, offset);
        const code = response.data.code;
        if(code == "200") {
          commit('setThreads', response.data.data);
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed', error);
        return { success: false, code: "500"};
      }
    },
    async threadLike( _, { accessToken, uid, ouid, threadId }) {
      try {
        const response = await api.threadLike(accessToken, uid, ouid, threadId);
        const code = response.data.code;
        console.log(response)
        if(code == "200") {
          return { success: true, data: response.data.data };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed', error);
        return { success: false, code: "500"};
      }
    },
    async threadAdd( _, { accessToken, uid, image }) {
      try {
        const response = await api.threadAdd(accessToken, uid, image);
        console.log(response)
        const code = response.data.code;
        if(code == "200") {
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed', error);
        return { success: false, code: "500"};
      }
    },
    async threadReport( _, { accessToken, uid, threadId }) {
      try {
        const response = await api.threadReport(accessToken, uid, threadId);
        const code = response.data.code;
        if(code == "200") {
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed', error);
        return { success: false, code: "500"};
      }
    },
    async threadDelete( _, { accessToken, uid, threadId }) {
      try {
        const response = await api.threadDelete(accessToken, uid, threadId);
        const code = response.data.code;
        if(code == "200") {
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed', error);
        return { success: false, code: "500"};
      }
    },
  },
};
import Vue from 'vue'
import VueRouter from 'vue-router'

import SplashView from '../components/SplashView.vue'
import LoginView from '../components/LoginView.vue'
import RegisterView from '../components/RegisterView.vue'
import MainView from '../components/MainView.vue'
import ProfileInitView from '../components/ProfileInitView.vue'
import ProfileEditView from '../components/ProfileEditView.vue'
import ProfileView from '../components/ProfileView.vue'
import ChatListView from '../components/ChatListView.vue'
import ChatView from '../components/ChatView.vue'
import SettingsView from '../components/SettingsView.vue'
import StoreView from '../components/StoreView.vue'
import PayView from '../components/PayView.vue'
import PWAView from '../components/PWAView.vue'
import NoInternetView from '../components/NoInternetView.vue'
import AccountView from '@/components/AccountView.vue'
import BillingsListView from '@/components/BillingsListView.vue'
import PayCompleteView from '@/components/PayCompleteView.vue'
import TermsView from '@/components/TermsView.vue'
import FilterView from '@/components/FilterView.vue'
import InquiryView from '@/components/InquiryView.vue'
import UserReportView from '@/components/userReportView.vue'
import ThreadView from '@/components/ThreadView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'SplashView',
    component: SplashView
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/register',
    name: 'RegisterView',
    component: RegisterView
  },
  {
    path: '/main',
    name: 'MainView',
    component: MainView,
    meta: { keepAlive: true }
  },
  {
    path: '/filter',
    name: 'FilterView',
    component: FilterView
  },
  {
    path: '/thread',
    name: 'ThreadView',
    component: ThreadView,
  },
  {
    path: '/profile-init',
    name: 'ProfileInitView',
    component: ProfileInitView
  },
  {
    path: '/profile-edit',
    name: 'ProfileEditView',
    component: ProfileEditView
  },
  {
    path: '/profile',
    name: 'ProfileView',
    component: ProfileView
  },
  {
    path: '/user-report',
    name: 'UserReport',
    component: UserReportView
  },
  {
    path: '/chat-list',
    name: 'ChatListView',
    component: ChatListView
  },
  {
    path: '/chat',
    name: 'ChatView',
    component: ChatView
  },
  {
    path: '/account',
    name: 'AccountView',
    component: AccountView
  },
  {
    path: '/settings',
    name: 'SettingsView',
    component: SettingsView
  },
  {
    path: '/inquiry',
    name: 'InquiryView',
    component: InquiryView
  },
  {
    path: '/store',
    name: 'StoreView',
    component: StoreView
  },
  {
    path: '/pay',
    name: 'PayView',
    component: PayView,
    props: (route) => ({ encodedSku: route.query.sku })
  },
  {
    path: '/pay-complete',
    name: 'PayCompleteView',
    component: PayCompleteView
  },
  {
    path: '/billings-list',
    name: 'BillingsListView',
    component: BillingsListView
  },
  {
    path: '/pwa',
    name: 'PWAView',
    component: PWAView
  },
  {
    path: '/terms',
    name: 'TermsView',
    component: TermsView
  },
  {
    path: '/no-internet',
    name: 'NoInternetView',
    component: NoInternetView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (navigator.onLine) {
    if (to.path === '/no-internet') {
      next('/');
    } else {
      next();
    }
  } else {
    if (to.path !== '/no-internet') {
      next('/no-internet');
    } else {
      next();
    }
  }
});

export default router

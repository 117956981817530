<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1024px;">

        <v-app-bar color="var(--black-1)" :style="{ borderBottom: '1px solid var(--black-2)' }" dark flat class="app-bar" app>
          <v-btn icon @click="backBtnCkd">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <p>{{ $t('settingsTitle') }}</p>
        </v-app-bar>
        
        <v-container class="pa-0" fluid>
          
          <v-card class="container--black2 text-left" @click="$router.push('/profile-edit')">
            <v-card-text class="text-body-2 mt-0" style="color:white">
              {{ $t('settingsProfileEdit') }}
            </v-card-text>
          </v-card>

          <v-card class="container--black2 text-left mt-3" @click="$router.push('/billings-list')">
            <v-card-text class="text-body-2 mt-0" style="color:white">
              {{ $t('settingBillingsList') }}
            </v-card-text>
          </v-card>

          <v-card class="container--black2 text-left mt-3" to="/account">
            <v-card-text class="text-body-2 mt-0" style="color:white">
              {{ $t('settingsAccount') }}
            </v-card-text>
          </v-card>

          <v-card class="container--black2 text-left mt-3" to="/inquiry">
            <v-card-text class="text-body-2 mt-0" style="color:white">
              {{ $t('settingsInquiry') }}
            </v-card-text>
          </v-card>

        </v-container>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'SettingsView',
  data() {
    return {
    };
  },
  methods: {
    backBtnCkd() {
      this.$router.go(-1);
    }
  },
  created() {
    if(this.$store.getters.isLoggedIn == null) {
      this.$router.push('/');
      return;
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
</style>
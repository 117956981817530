import { getAnalytics, logEvent } from "firebase/analytics";

export const AnalyticsFunctions = {
  analytics: null,

  initAnalytics(app) {
    this.analytics = getAnalytics(app);
  },

  logging(event, params = {}) {
    if (this.analytics) {
      logEvent(this.analytics, event, params);
    }
  }
  
};
<template>
  <v-app class="overflow-x-hidden">
    <v-main class="full-size overflow-x-hidden">
      <v-container class="pa-0" style="max-width: 720px;">

        <v-app-bar class="app-bar" color="var(--black-1)" :style="{ borderBottom: '1px solid var(--black-2)' }" dark flat app>
          <v-btn icon @click="backBtnCkd">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <p>{{ $t('threadTitle') }}</p>
        </v-app-bar>

        <v-container>
          <p class="text-left caption" style="color:gray">
            <span class="mdi mdi-clock-outline"></span> {{ $t('threadTimer') }} {{ initial.remainingTime }}
          </p>
        </v-container>

        <v-container class="pt-0">
          <v-row align="center" justify="center" no-gutters class="py-3 ma-0" style="color:white;background-color: var(--red);border-radius: 8px;">
            <v-col cols="auto" class="d-flex align-center">
              <p class="caption ma-0 font-weight-bold">{{ $t('threadPromotionBanner') }}</p>
            </v-col>
          </v-row>
        </v-container>

        <v-container class="pa-0 ma-0" style="margin-bottom: 100px !important;">
          <v-row>
            <v-col class="mx-0" cols="12" v-for="item in threads" :key="item.id" style="padding-top: 0px !important;padding-bottom: 0px !important;">
              <v-card style="background-color: transparent; overflow: hidden;" elevation="0">
                <v-card-title class="d-flex align-center" style="margin-bottom: -5px;">
                  <v-btn icon small class="mr-0" @click="profileBtnCkd(item.uid)">
                    <v-img :src="item.profile_image_thumbnail" class="rounded-circle" alt="my profile image" width="30" height="30" cover></v-img>
                  </v-btn>
                  <span class="text-body-2 white--text ml-2">{{ item.nickname }}</span>
                  <span class="white--text text-caption ml-2">{{ formatDate(item.created_at) }}</span>
                  <v-spacer></v-spacer>
                  <v-menu bottom left offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon color="grey" small>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-if="$store.getters.getUID != item.uid" @click="reportBtnCkd(item.id)">
                        <v-list-item-title class="text-body-2">{{ $t('threadReport') }}</v-list-item-title>
                      </v-list-item>
                      <v-list-item v-if="$store.getters.getUID == item.uid" @click="deleteBtnCkd(item.id)">
                        <v-list-item-title class="text-body-2">{{ $t('threadDelete') }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card-title>

                <v-container class="my-0 py-0">
                  <v-img
                  :src="item.image"
                  :alt="'Thread image by ' + item.nickname"
                  cover
                  class="thread-image rounded-image"
                  ></v-img>
                </v-container>

                <v-card-actions class="pt-3 px-6 d-flex justify-end">
                  <v-btn icon x-small class="mr-1" @click="likeBtnCkd(item.uid, item.id)">
                    <v-icon :color="item.my_like == 'true' ? 'var(--red)' : 'white'" size="18">
                      {{ item.my_like == 'true' ? 'mdi-heart' : 'mdi-heart-outline' }}
                    </v-icon>
                  </v-btn>
                  <span class="text-caption grey--text">{{ item.total_likes }}</span>
                </v-card-actions>
              </v-card>
              <v-divider class="px-4" style="border-color: var(--black-2);"/>
            </v-col>
          </v-row>

        </v-container>

        <v-container class="pa-0 ma-0" style="position: fixed; bottom: 32px; right: 20px; width: auto; z-index: 1000;" fluid>
          <v-col class="pa-0">
            <v-row dense justify="center" align="center" class="flex-column">
              <v-col cols="auto" class="pa-0 mb-1">
                <v-btn fab x-small class="floating-btn rounded-circle floating-btn-size" to="/thread" @click="openImageUpload">
                  <v-img class=" floating-btn-size" :src="require('@/assets/ic_add.png')" alt="Chat Icon"></v-img>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-container>

        <input type="file" ref="fileInput" @change="handleImageChange" style="display: none;" accept="image/*"/>

        <img
          v-if="thread.isLoading"
          :src="require('@/assets/ic_spinner.png')"
          width="20"
          height="20"
          alt="Loading..."
          class="mt-5 mb-5"/>

        <v-snackbar 
          :min-width="0" 
          v-model="snackbar.visibility"
        >
          <div class="text-center">
            <span style="white-space: normal; word-break: break-word;">
              {{ snackbar.text }}
            </span>
          </div>
        </v-snackbar>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// import { AnalyticsFunctions } from '../rest/analytics/functions.js';
// import { AnalyticsParams } from '../rest/analytics/params.js';

const loadCount = 10;

export default {
  name: 'ThreadView',
  data() {
    return {
      threads: [],
      thread: {
        isLoading: false,
        isDataEnd: false,
        users: [],
        selectedOrder: this.$t('orderRecent'),
        orders: [this.$t('orderRecent'), this.$t('orderLatest')],
        offset: 0,
        scrollPosition: 0,
      },
      initial: {
        remainingTime: '',
        timer: null,
      },
      snackbar: {
        text: "",
        visibility: false,
      }
    };
  },
  methods: {
    threadGet() {
      this.thread.isLoading = true;
      this.$store.dispatch('threadGet', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
        offset: this.thread.offset
      })
      .then((result) => {
        if (result.success) {
          const newThreads = this.$store.getters.getThreads;
          if(newThreads.length == 0) {
            this.thread.isDataEnd = true;
          }
          else {
            this.threads = [...this.threads, ...newThreads];
            this.thread.offset += loadCount;
          }
          setTimeout(() => {
            this.thread.isLoading = false;
          }, 1000);
        }
        else {
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
        }
      });
    },
    likeBtnCkd(ouid, id) {
      this.$store.dispatch('threadLike', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
        ouid: ouid,
        threadId: id
      })
      .then((result) => {
        if (result.success) {
          const threadItem = this.threads.find(item => item.id === id);
          if(result.data == "like") {
            threadItem.total_likes = (parseInt(threadItem.total_likes, 10) + 1).toString();
            threadItem.my_like = 'true';
          }
          else {
            threadItem.total_likes = (parseInt(threadItem.total_likes, 10) - 1).toString();
            threadItem.my_like = 'false';
          }
        }
        else {
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
        }
      });
    },
    reportBtnCkd(id) {
      this.$store.dispatch('threadReport', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
        threadId: id
      })
      .then((result) => {
        if (result.success) {
          this.snackbar.text = this.$t('threadReportSuccess');
          this.snackbar.visibility = true;
        }
        else {
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
        }
      });
    },
    deleteBtnCkd(id) {
      this.$store.dispatch('threadDelete', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
        threadId: id
      })
      .then((result) => {
        if (result.success) {
          this.$router.go(0);
        }
        else {
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
        }
      });
    },
    profileBtnCkd(ouid) {
      this.$store.commit('setOUID', ouid);
      this.$router.push('/profile');
    },
    backBtnCkd() {
      this.$router.go(-1);
    },
    // add
    openImageUpload() {
      this.$refs.fileInput.click();
    },
    handleImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.$store.dispatch('setLoading', true);
        this.$store.dispatch('threadAdd', {
          accessToken: this.$store.getters.getAccessToken,
          uid: this.$store.getters.getUID,
          image: file
        })
        .then((result) => {
          if (result.success) {
            this.$router.go(0);
          } else {
            switch (result.code) {
              case '5002':
                this.snackbar.text = this.$t('threadIntervalTooShort');
                break;
              default:
                this.snackbar.text = this.$t('serverError');
                break;
            }
            this.snackbar.visibility = true;
          }
        })
        .finally(() => {
          this.$store.dispatch('setLoading', false);
        });
      }else {
        this.snackbar.text = this.$t('serverError');
        this.snackbar.visibility = true;
      }
    },
    // rest
    updateRemainingTime() {
      const now = new Date();
      const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
      const diff = midnight - now;
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))+1;
      this.initial.remainingTime = `${hours.toString()}시간 ${minutes.toString().padStart(2, '0')}분`;
    },
    formatDate(dateString) {
      function getRelativeDate(date) {
        const hDate = date.substring(11);
        const hDateArr = hDate.split(":");
        const hDateString = `${hDateArr[0]}:${hDateArr[1]}`;
        const dDate = date.substring(0, 10);
        const dDateArr = dDate.split("-");
        const dDateString = `${dDateArr[1]}.${dDateArr[2]} ${hDateArr[0]}:${hDateArr[1]}`;
        return date.substring(0, 10) === getCurrentDate().substring(0, 10)
          ? hDateString
          : dDateString;
      }
      function getCurrentDate() {
        const date = new Date();
        const format = new Intl.DateTimeFormat('ko-KR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false
        });
        return format.format(date).replace(/\. /g, '-').replace(/:/g, ':').replace(/ /g, ' ');
      }
      return getRelativeDate(dateString);
    },
    scrolled() {
      this.thread.scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      if (documentHeight - (this.thread.scrollPosition + windowHeight) < 100 && !this.thread.isLoading && !this.thread.isDataEnd) {
        this.threadGet();
      }
    },
  },
  created() {
    window.scrollTo(0, 0);
    window.addEventListener('scroll', this.scrolled);
    if(this.$store.getters.isLoggedIn == null) {
      this.$router.push('/');
      return;
    }
    this.updateRemainingTime();
    this.initial.timer = setInterval(this.updateRemainingTime, 60000);
    this.threadGet()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrolled);
    if (this.initial.timer) {
      clearInterval(this.initial.timer);
    }
  },
};
</script>

<style scoped>

.app-bar {
  max-width: 720px !important;
}

.floating-btn {
  background-color: rgba(0, 0, 0, 0.7) !important;
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
}
.floating-btn-size {
  width: 48px;
  height: 48px;
}
@media (max-width: 600px) {
  .floating-btn-size {
    width: 42px;
    height: 42px;
  }
}

.rounded-image {
  border-radius: 8px;
}
.v-card {
  border-radius: 8px;
}

.thread-image {
  height: 500px;
}
@media (max-width: 600px) {
  .thread-image {
    height: 200px;
  }
}

</style>
export default {
  emailEmpty: '이메일을 입력해주세요.',
  emailInvalid: '유효한 이메일을 입력해주세요.',
  pwEmpty: '비밀번호를 입력해주세요.',
  pwUnmatched: '비밀번호가 일치하지 않습니다.',
  termsRequired: '서비스 이용약관에 동의해주세요.',
  privacyRequired: '개인정보 처리방침에 동의해주세요.',
  userInvalid: '로그인 정보가 일치하지 않습니다.',
  userNotExist: '존재하지 않는 회원입니다.',
  userExist: '이미 존재하는 회원입니다.',
  userBlocked: '계정이 차단되었습니다.',
  userDeleted: '계정이 삭제되었습니다.',
  reLoginPlease: '다시 로그인해주세요.',
  profileImageEmpty: '프로필 이미지를 선택해주세요.',
  nicknameEmpty: '닉네임을 입력해주세요.',
  nicknameTooLong: '닉네임을 10자 이하로 입력해주세요.',
  orientationEmpty: '성향을 선택해주세요.',
  genderEmpty: '성별을 선택해주세요.',
  birthYearEmpty: '생년을 선택해주세요.',
  birthMonthEmpty: '생년월을 선택해주세요.',
  birthDayEmpty: '생년일을 선택해주세요.',
  locationEmpty: '지역을 선택해주세요.',
  playPreferencesEmpty: '선호하는 플레이를 선택해주세요.',
  playExperiencesEmpty: '경험한 플레이를 선택해주세요.',
  relationTypeEmpty: '선호하는 관계를 선택해주세요.',
  bioEmpty: '자기소개를 입력해주세요.',
  bioTooLong: '자기소개를 140자 이하로 입력해주세요.',
  chatIntervalTooShort: '너무 빠른 시간 안에 다른 회원에게 채팅할 수 없습니다.',
  threadIntervalTooShort: '너무 빠른 시간 안에 여러 스레드를 게시할 수 없습니다.',
  pointShortage: '포인트가 부족합니다.',
  pwaCannotInstall: '앱을 설치할 수 없습니다.',
  notificationAppAllow: '앱 설정에서 알림을 허용해주세요.',
  notificationBrowserAllow: '브라우저 설정에서 알림을 허용해주세요.',
  serverError: '잠시 후 다시 시도해주세요.',

  orderRecent: '접속순',
  orderLatest: '최신순',

  filterTitle: '필터',
  filterGenderLabel: '성별',
  filterGenderAll: '전체',
  filterGenderM: '남성',
  filterGenderF: '여성',
  filterOrientationTypeLabel: '성향',
  filterOrientationTypeAll: '전체',
  filterOrientationTypeTop: '탑',
  filterOrientationTypeBottom: '바텀',
  filterOrientationTypeSwitch: '스위치',
  filterAgeLabel: '나이',
  filterAgeAll: '전체',
  filterAge20s: '20대',
  filterAge30s: '30대',
  filterAge40s: '40대+',
  filterLocationLabel: '지역',
  filterLocationAll: '전체',
  filterClear: '초기화',
  filterApply: '적용',

  inquiryTitle: '문의',
  inquiryEmail: '이메일',
  inquiryContents: '문의 내용',
  inquirySubmit: '제출',
  inquiryEmailEmpty: '이메일을 입력해주세요.',
  inquiryEmailInvalid: '올바른 이메일을 입력해주세요.',
  inquiryContentsEmpty: '문의 내용을 입력해주세요.',
  inquiryContentsTooLong: '문의 내용을 5000자 이하로 입력해주세요.',

  //pwa
  pwaBannerTitle: 'Deeep* 앱 설치하기 →',
  pwaTitle: '공식 앱 설치 가이드',
  pwaSubTitle: '앱을 홈 화면에 추가하고<br>새로운 채팅 알림을 받으세요.',
  pwaAndroidBrowser: '<a href="https://www.google.com/chrome/" target="_blank"><u>Chrome</u></a>에서 이 단계를 따르세요.',
  pwaAndroid1: '1. <span class="highlight">더보기</span> 아이콘을 탭하세요.',
  pwaAndroid2: '2. <span class="highlight">홈 화면에 추가</span> 를 탭하세요.',
  pwaAndroid3: '3. 팝업에서 <span class="highlight">설치</span> 를 탭하세요.',
  pwaAndroid4: '4. 팝업에서 <span class="highlight">설치</span> 를 탭하세요.',
  pwaIPhone1: '1. <span class="highlight">공유</span> 아이콘을 탭하세요.',
  pwaIPhone2: '2.<span class="highlight">홈 화면에 추가</span> 를 탭하세요.',
  pwaIPhone3: '3. 오른쪽 상단 <span class="highlight">추가</span> 를 탭하세요.',
  pwaDesktopBrowser: '<a href="https://www.google.com/chrome/" target="_blank"><u>Chrome</u></a> 또는 <a href="https://www.microsoft.com/edge" target="_blank"><u>Edge</u></a>에서 이 단계를 따르세요.',
  pwaDesktop1: '상단 주소창의 설치 아이콘 및 설치 버튼을 탭하거나, 아래의 설치 버튼을 탭하세요. 시크릿 모드의 경우 설치 아이콘이 표시되지 않을 수 있습니다.',
  pwaOtherBrowser: '<a style="color:var(--black-1) !important;"><u>다른 브라우저를 사용하시나요? 더 많은 설치 가이드가 준비되어 있어요.</u></a>',
  pwaInstall: '설치',
  pwaLinkCopied: '링크가 복사되었습니다.',
  pwaLinkCopiedFaild: '링크 복사에 실패했습니다.',

  pwaMobileTitle: 'Mobile 설치 가이드',
  pwaSamsungAndroid: '· 삼성 인터넷 on Android',
  pwaSamsungAndroid1: '1. <span class="highlight">더보기</span> 아이콘을 탭하세요.',
  pwaSamsungAndroid2: '2. <span class="highlight">현재 페이지 추가</span>를 탭하세요.',
  pwaSamsungAndroid3: '3. <span class="highlight">홈 화면</span>를 탭하세요.',
  pwaSamsungAndroid4: '4. <span class="highlight">추가</span> 버튼을 탭하세요.',
  pwaFirefoxAndroid: '· Firefox on Android',
  pwaFirefoxAndroid1: '1. <span class="highlight">더보기</span> 아이콘을 탭하세요.',
  pwaFirefoxAndroid2: '2. <span class="highlight">홈 화면에 추가</span>를 탭하세요.',
  pwaFirefoxAndroid3: '3. <span class="highlight">추가</span> 버튼을 탭하세요.',
  pwaEdgeAndroid: '· Edge on Android',
  pwaEdgeAndroid1: '1. <span class="highlight">더보기</span> 아이콘을 탭하세요.',
  pwaEdgeAndroid2: '2. <span class="highlight">휴대폰에 추가</span>를 탭하세요.',
  pwaEdgeAndroid3: '3. <span class="highlight">설치</span> 버튼을 탭하세요.',
  pwaFirefoxIOS: '· Firefox on iOS',
  pwaFirefoxIOS1: '1. <span class="highlight">공유</span> 아이콘을 탭하세요.',
  pwaFirefoxIOS2: '2. <span class="highlight">홈 화면에 추가</span>를 탭하세요.',
  pwaFirefoxIOS3: '3. <span class="highlight">추가</span> 버튼을 탭하세요.',
  pwaEdgeIOS: '· Edge on iOS',
  pwaEdgeIOS1: '1. <span class="highlight">공유</span> 아이콘을 탭하세요.',
  pwaEdgeIOS2: '2. <span class="highlight">홈 화면에 추가</span>를 탭하세요.',
  pwaEdgeIOS3: '3. <span class="highlight">추가</span> 버튼을 탭하세요.',
  pwaDesktopTitle: 'Desktop 설치 가이드',
  pwaEdgeDesktop: '· Edge on Windows and Mac',
  pwaEdgeDesktop1: '1. <span class="highlight">앱</span> - <span class="highlight">이 사이트를 앱으로 설치</span> 를 탭하세요.',
  pwaEdgeDesktop2: '2. <span class="highlight">설치</span> 버튼을 탭하세요.',
  pwaSafariMacOS: '· Safari on Mac',
  pwaSafariMacOS1: '1. 오른쪽 상단 <span class="highlight">공유</span> 아이콘을 탭하세요.',
  pwaSafariMacOS2: '2. <span class="highlight">Dock에 추가</span> 를 탭하세요.',
  pwaSafariMacOS3: '3. <span class="highlight">추가</span> 버튼을 탭하세요.',

  // dialog
  confirmLogout: '정말 로그아웃할까요?',
  confirmAccountDelete: '정말 Deeep에서 떠나시겠어요?',
  requestNotification: '채팅 알림을 위해 권한을 허용해주세요.',

  // widgets
  appSubTitle: 'BDSM 데이팅 & 매칭',

  loginTitle: '로그인',
  loginEmail: '이메일',
  loginPassword: '비밀번호',
  loginRegister: '새로운 계정으로 회원가입',

  registerTitle: '회원가입',
  registerNewEmail: '새로운 이메일',
  registerNewPassword: '새로운 비밀번호',
  registerRePassword: '비밀번호 확인',
  registerBack: '로그인으로 돌아기기',
  registerTerms: '서비스 이용약관 동의',
  registerPrivacy: '개인정보처리방침 및 이용약관 동의',

  profileTitle: '프로필 더보기',

  profileInitTitle: '프로필 설정',
  profileInitComplete: '프로필 설정 완료',
  profileInitNickname: '닉네임',
  profileInitOrientation: '성향',
  profileInitGender: '성별',
  profileInitGenderM: '남성',
  profileInitGenderF: '여성',
  profileInitBio: '회원님에 대해 알려주세요',
  profileInitBioPlaceholder: '소셜미디어 계정 또는 메신저 아이디 공유시 계정이 차단될 수 있습니다.',
  profileInitBirthYear: '생년',
  profileInitBirthMonth: '월',
  profileInitBirthDay: '일',
  profileInitLocation: '지역',
  profileInitExperience: '경험한 플레이',
  profileInitPreferences: '선호하는 플레이',
  profileInitRelationType: '선호하는 관계',
  profileInitBDSMTest: '🌈 BDSM 성향 테스트 →',

  profileEditTitle: '프로필 수정',
  profileEditComplete: '프로필 수정 완료',
  profileEditNickname: '닉네임',
  profileEditOrientation: '성향',
  profileEditGender: '성별',
  profileEditGenderM: '남성',
  profileEditGenderF: '여성',
  profileEditBio: '회원님에 대해 알려주세요',
  profileEditBioPlaceholder: '소셜미디어 계정 또는 메신저 아이디 공유시 계정이 차단될 수 있습니다.',
  profileEditBirthYear: '생년',
  profileEditBirthMonth: '월',
  profileEditBirthDay: '일',
  profileEditLocation: '지역',
  profileEditExperience: '경험한 플레이',
  profileEditPreferences: '선호하는 플레이',
  profileEditRelationType: '선호하는 관계',

  userReportTitle: '회원 신고',
  userReportContents: '신고 내용',
  userReportSubmit: '제출',
  userReportContentsEmpty: '신고 내용을 입력해주세요.',
  userReportContentsTooLong: '신고 내용을 5000자 이하로 입력해주세요.',

  profileProfile: '프로필',
  profileReportUser: '회원 신고',
  profileBio: '자기소개',
  profileLocation: '지역',
  profilePlayPreferences: '선호하는 플레이',
  profilePlayExperiences: '경험한 플레이',
  profileRelationType: '선호관계',

  profileViewChat: '채팅 (-30*)',

  playAbuse: '욕설',
  playMassage: '마사지',
  playWorship: '워십',
  playBiting: '바이팅',
  playGrooming: '그루밍',
  playOnline: '온라인',
  playCosplay: '코스프레',
  playRoleplay: '롤플레이',
  playClown: '광대',
  playMannequin: '마네킹',
  playAnimal: '애니멀',
  playFurniture: '가구',
  playFood: '음식',
  playMission: '미션',
  playShame: '수치',
  playNeglect: '방치',
  playResist: '반항',
  playBreathControl: '호흡 컨트롤',
  playOrgasmControl: '절정 컨트롤',
  playMindControl: '정신지배',
  playBlind: '블라인드',
  playBondage: '본디지',
  playHanging: '행잉',
  playSpank: '스팽',
  playFingering: '핑거링',
  playAnal: '애널',
  playEnema: '관장',
  playSpit: '스핏',
  playGoldenShower: '골든샤워',
  playScat: '스캇',

  abuse: '욕설',
  massage: '마사지',
  worship: '워십',
  biting: '바이팅',
  grooming: '그루밍',
  online: '온라인',
  cosplay: '코스프레',
  roleplay: '롤플레이',
  clown: '광대',
  mannequin: '마네킹',
  animal: '애니멀',
  furniture: '가구',
  food: '음식',
  mission: '미션',
  shame: '수치',
  neglect: '방치',
  resist: '반항',
  breathControl: '호흡 컨트롤',
  orgasmControl: '절정 컨트롤',
  mindControl: '정신지배',
  blind: '블라인드',
  bondage: '본디지',
  hanging: '행잉',
  spank: '스팽',
  fingering: '핑거링',
  anal: '애널',
  enema: '관장',
  spit: '스핏',
  goldenShower: '골든샤워',
  scat: '스캇',

  relationTypeFriend: '친구',
  relationTypePartner: '파트너',
  relationTypeCouple: '연인',
  friend: '친구',
  partner: '파트너',
  couple: '연인',

  termsTitle: '약관',
  termsServiceTitle: '서비스 이용약관',
  termsPrivacyTitle: '개인정보처리방침',

  mainChat: '채팅',

  chatTitle: '채팅',

  threadTitle: 'Deeep* 스레드',
  threadReport: '스레드 신고',
  threadDelete: '스레드 삭제',
  threadReportSuccess: '스레드가 신고되었습니다',
  threadTimer: '스레드 초기화까지',
  threadPromotionBanner: 'LIKE 받을 때마다 코인 1개 지급!',

  settingsTitle: '설정',
  settingsProfileEdit: '프로필 수정',
  settingsAccount: '계정',
  settingBillingsList: '구매내역',
  settingsInquiry: 'Deeep* 문의',
  
  billingsListTitle: '구매내역',
  billingsRefund: '환불',
  billingsCancelRequestSuccess: '환불이 요청되었습니다.',
  billingsCancelRequestAlready: '이미 환불이 요청되었습니다.',
  billingsInfoSave: '결제 정보 저장하기',
  
  accountTitle: '계정',
  accountLogout: '로그아웃',
  accountDelete: '회원탈퇴',

  storeTitle: '코인 스토어',
  storeSubtitle: '보유한 코인:',
  storeChat: '채팅',

  payTitle: '코인 구매하기',
  payInformationTitle: '구매자 정보 입력',
  payPlaceholder1: '이름',
  payPlaceholder2: '연락처 (- 제외)',
  payPlaceholder3: '생년월일 (YYMMDD)',
  payBillingTitle: '결제 정보 입력',
  payBillingPlaceholder1: '카드번호 (- 제외)',
  payBillingPlaceholder2: '유효기간 (MMYY)',
  payBillingPlaceholder3: '비밀번호 앞 두자리 (**)',
  payRegisterTermsRequired: '이용약관에 동의해주세요.',
  payNameInvalid: '올바른 이름을 입력해주세요.',
  payContactInvalid: '올바른 형식의 연락처를 입력해주세요.',
  payBirthInvalid: '올바른 생년월일을 입력해주세요.',
  payCardInvalid: '올바른 카드 번호를 입력해주세요.',
  payExpiryDateInvalid: '올바른 유효기간을 입력해주세요.',
  payPWInvalid: '올바른 비밀번호를 입력해주세요.',
  payComplete: '결제하기',
  payPromotionTxt: '최대 할인 적용됨 ✅',

  payResponse1146: '계좌 잔액이 부족합니다.',
  payResponse3002: '카드 결제에 실패했습니다.',
  payResponse3011: '카드 번호가 올바르지 않습니다.',
  payResponse3021: '유효기간이 올바르지 않습니다.',
  payResponse3054: '지원하지 않는 카드입니다. (국외카드)',
  payResponse3098: '지원하지 않는 카드입니다. (국외카드)',
  payResponse9998: '카드 잔액이 부족합니다.',
  
  notificationOn: '알림 ON <span class="ml-1" style="font-style:bold;color:var(--green);">●</span>',
  notificationOff: '알림 OFF <span class="ml-1" style="font-style:bold;color:var(--red);">●</span>',

  alertOK: '확인',
  alertRefuse: '거부',
  alertBack: '뒤로가기',

  gender: {
    male: "남성",
    female: "여성",
  },
  
  orientation: {
    owner: "오너",
    daddy: "대디",
    mommy: "마미",
    dominant: "도미넌트",
    master: "마스터",
    hunter: "헌터",
    brattamer: "브랫테이머",
    degrader: "디그레이더",
    rigger: "리거",
    boss: "보스",
    sadist: "사디스트",
    spanker: "스팽커",
    pet: "펫",
    little: "리틀",
    submissive: "서브미시브",
    slave: "슬레이브",
    prey: "프레이",
    brat: "브랫",
    degradee: "디그레이디",
    ropebunny: "로프버니",
    servant: "서번트",
    masochist: "마조히스트",
    spankee: "스팽키",
    switch: "스위치",
  },

  // meta
  meta: {
    "title": "Deeep: BDSM 데이팅 & 매칭",
    "description": "Deeep* 데이팅 앱은 다양한 BDSM 성향자들의 빠른 만남을 위한 공간입니다. 지금 바로 다른 성향자들과 대화하고, 만나고, 사랑하세요.",
    "keywords": "BDSM, BDSM 플레이, BDSM 데이팅, BDSM 커뮤니티, SM, SM 플레이, SM 커뮤니티, SM 데이팅, 가학, 수치, 성향, 멜돔, 멜섭, 펨돔, 펨섭, 스위치, 아라곤, SM톡, 딥, Deeep, Deeep*",
  },

  // else
  default: '',
  
};
import api from '../../rest/api';

export default {
  actions: {
    async inquiryWrite( _, { accessToken, uid, email, contents }) {
      try {
        const response = await api.inquiryWrite(accessToken, uid, email, contents);
        const code = response.data.code;
        if(code == "200") {
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed', error);
        return { success: false, code: "500"};
      }
    },
  },
};
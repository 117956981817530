<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container class="pa-0" style="max-width: 1024px;">

        <v-app-bar 
          class="app-bar pa-0" 
          style="background-color: var(--black-1);" 
          elevation="0" 
          app
          height="64"
        >
          <v-container class="pa-0 d-flex align-center" fluid>
            <v-toolbar-title class="d-flex align-center pa-0">
              <v-img 
                :src="require('@/assets/ic_logo.png')" 
                alt="Logo" 
                width="45" 
                height="45" 
                contain
              ></v-img>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn 
              to="/filter" 
              icon
              class="d-flex align-center justify-center pa-0 mr-5" 
              min-height="20" 
              min-width="20"
              width="20"
            >
              <v-img 
                :src="require('@/assets/ic_filter.svg')" 
                width="20" 
                height="20" 
                cover
              ></v-img>
            </v-btn>
            <v-btn 
              to="/settings" 
              icon 
              class="d-flex align-center justify-center pa-0" 
              min-height="25" 
              min-width="25"
              width="25"
            >
              <v-img 
                :src="profileImage" 
                class="rounded-circle" 
                width="25"
                height="25" 
                cover
              ></v-img>
            </v-btn>
            <v-btn 
              @click="notificationBtnCkd()"
              class="text-body-2 white--text d-flex align-center justify-center ml-5 px-2"
              style="font-size: 12px !important;"
              color="var(--black-3)"
              v-html="notificationText"
            >
            </v-btn>
          </v-container>
        </v-app-bar>

        <v-app-bar class="d-flex align-center justify-center app-tool-bar" app>
          <v-btn v-for="(order, index) in feed.orders" 
            :key="'order-' + index" 
            :class="{ 'selected-btn': feed.selectedOrder === order }" 
            @click="reload(order)"
            class="main-order-btn mx-2" text
          > <b>{{ order }}</b> </v-btn>
        </v-app-bar>

        <v-container v-if="!isPWA" fluid>
          <router-link to="/pwa" class="text-decoration-none">
            <v-row align="center" justify="center" no-gutters class="py-3 ma-0" style="color:white;background-color: var(--black-2);">
              <v-col cols="auto" class="mr-3 d-flex align-center">
                <v-img class="app-icon app-icon-elevation" :src="require('@/assets/ic_app_icon.png')" alt="Logo" width="35" height="35" contain></v-img>
              </v-col>
              <v-col cols="auto" class="d-flex align-center">
                <p class="caption ma-0 font-weight-bold">{{ $t('pwaBannerTitle') }}</p>
              </v-col>
            </v-row>
          </router-link>
        </v-container>

        <v-container class="mt-1 pt-0" fluid>
          <v-row 
            align="center" 
            justify="center" 
            no-gutters 
            class="py-2 ma-0" 
            style="color:white;background-color: var(--red-dark); cursor: pointer;"
            @click="openSurvey"
          >
            <v-col cols="auto" class="d-flex align-center">
              <p class="caption ma-0 font-weight-bold">📄 Deeep* 개선 설문조사 (코인 지급)</p>
            </v-col>
          </v-row>
        </v-container>

        <v-container class="mt-1 pt-0" fluid>
          <v-row 
            align="center" 
            justify="center" 
            no-gutters 
            class="py-2 ma-0" 
            style="color:white;background-color: var(--red-dark); cursor: pointer;"
            @click="openURLNotify"
          >
            <v-col cols="auto" class="d-flex align-center">
              <p class="caption ma-0 font-weight-bold">📄 [중요] 주소 변경 안내</p>
            </v-col>
          </v-row>
        </v-container>

        <v-container class="mt-1 pt-0 px-5 mb-3">
          <v-row justify-space-between>
            <v-col v-for="(user, index) in feed.users" :key="`user-${user.id}-${index}`" cols="6" sm="6" md="4" class="px-1 mb-0 pb-0">
              <v-card 
                class="mx-auto clickable-card" 
                dark 
                color="var(--black-2)"
                @click="profileBtnCkd(user.id)">
                
                <v-img
                  height="220"
                  class="main-cell-profile-image-view"
                  :src="getComputedProfileImage(user.profile_image)"
                  alt="profile image"
                  :aspect-ratio="1"
                  cover
                  fill-height
                >
                </v-img>

                <v-card-text class="text-center" style="margin-top: -6px;">
                  <div class="white--text text-body-2 font-weight-bold main-cell-nickname-view font-weight-bold d-inline-flex align-center justify-center">
                    <!-- <span
                      v-if="isWithinGreenHours(user.updated_at)"
                      class="green-dot mr-2"
                      style="
                        width: 8px;
                        height: 8px;
                        background-color: var(--green);
                        border-radius: 50%;
                        display: inline-block;
                      "
                    ></span> -->
                    {{ user.nickname }}
                  </div>
                  <div class="grey--text caption main-cell-profile-extra-view mt-1 mb-1">
                    {{ getComputedOrientation(user.orientation) + ' · ' + getComputedGender(user.gender) }}
                  </div>
                  <!-- <div class="grey--text text-truncate main-cell-bio-view">{{ user.bio }}</div> -->
                </v-card-text>

                <v-card-actions style="margin-top:-16px">
                  <v-btn 
                    block 
                    class="white--text font-weight-bold"
                    style="background-color: var(--red);"
                    @click.stop="chatBtnCkd(user.id)">
                    <b>{{$t('mainChat')}}</b>
                  </v-btn>
                </v-card-actions>

              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <v-container class="pa-0 ma-0" style="position: fixed; bottom: 32px; right: 20px; width: auto; z-index: 1000;" fluid>
          <v-col class="pa-0">
            <v-row dense justify="center" align="center" class="flex-column">
              <v-col cols="auto" class="pa-0 mb-1">
                <v-btn fab x-small class="floating-btn rounded-circle floating-btn-size" to="/thread">
                  <v-img class=" floating-btn-size" :src="require('@/assets/ic_thread.png')" alt="Chat Icon"></v-img>
                </v-btn>
              </v-col>
              <v-col cols="auto" class="pa-0 mb-1">
                <v-btn fab x-small class="floating-btn rounded-circle floating-btn-size" to="/chat-list">
                  <v-img class=" floating-btn-size" :src="chatImage" alt="Chat Icon"></v-img>
                </v-btn>
              </v-col>
              <v-col cols="auto" class="pa-0 mb-1">
                <v-btn fab x-small class="floating-btn rounded-circle floating-btn-size" to="/store">
                  <v-img class=" floating-btn-size" :src="require('@/assets/ic_store.png')" alt="Store Icon"></v-img>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-container>

        <img
          v-if="feed.isLoading"
          :src="require('@/assets/ic_spinner.png')"
          width="20"
          height="20"
          alt="Loading..."
          class="mt-5 mb-5"/>

        <v-snackbar 
          :min-width="0" 
          v-model="snackbar.visibility"
        >
          <div class="text-center">
            <span style="white-space: normal; word-break: break-word;">
              {{ snackbar.text }}
            </span>
          </div>
        </v-snackbar>

        <v-dialog v-model="dialog.dialogVisibility" max-width="400px">
          <v-card style="background-color: var(--black-2)">
            <v-card-title style="color: white; font-size: 16px;">
              {{ dialog.dialogTitle }}
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="var(--red)" style="color: white;" @click="confrimDialog">
                {{ $t('alertOK') }}
              </v-btn>
              <v-btn color="var(--black-3)" dark @click="refuseDialog">
                {{ $t('alertRefuse') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import { AnalyticsFunctions } from '../rest/analytics/functions.js';
import { AnalyticsParams } from '../rest/analytics/params.js';
import { getToken } from "firebase/messaging";
import {CHAT_SERVER} from '../rest/api'
import io from 'socket.io-client';
import functions from "@/rest/functions";
import shared from '@/rest/shared';

const loadCount = 30;

export default {
  name: 'MainView',
  data() {
    return {
      isPWA: false,
      socket: null,
      profileImage: require('@/assets/ic_profile_placeholder.png'),
      chatImage: require('@/assets/ic_chat.png'),
      feed: {
        isLoading: false,
        isDataEnd: false,
        users: [],
        selectedOrder: this.$t('orderLatest'),
        orders: [this.$t('orderRecent'), this.$t('orderLatest')],
        offset: 0,
        scrollPosition: 0,
      },
      dialog: {
        dialogVisibility: false,
        dialogTitle: '',
        dialogAction: '',
      },
      snackbar: {
        text: "",
        visibility: false,
      }
    };
  },
  computed: {
    getComputedOrder() {
      switch (this.feed.selectedOrder) {
        case this.$t('orderRecent'):
          return `ORDER BY u.updated_at DESC LIMIT ${loadCount} OFFSET`;
        case this.$t('orderLatest'):
          return `ORDER BY u.created_at DESC LIMIT ${loadCount} OFFSET`;
        default:
          return '';
      }
    },
    getComputedProfileImage() {
      return (imageUrl) => {
        if (imageUrl && imageUrl !== "null" && imageUrl !== "") {
          return imageUrl;
        }
        return require('@/assets/ic_profile_placeholder.png');
      }
    },
    getComputedOrientation() {
      return (orientation) => {
        const translated = this.$t(`orientation.${orientation}`);
        return typeof translated === 'string' ? translated : orientation;
      }
    },
    getComputedGender() {
      return (gender) => {
        const translated = this.$t(`gender.${gender}`);
        return typeof translated === 'string' ? translated : gender;
      }
    },
    notificationText() {
      return this.$store.getters.getFCMToken === 'error' || this.$store.getters.getFCMToken === 'refused'
        ? this.$t('notificationOff')
        : this.$t('notificationOn');
    }
  },
  methods: {
    tokenRefresh() {
      this.$store.dispatch('tokenRefresh', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID
      })
      .then((result) => {
        if (!result.success) {
          this.snackbar.text = this.$t('reLoginPlease');
          this.snackbar.visibility = true;
          setTimeout(() => {
            this.$store.dispatch('resetState');
            this.$router.push('/');
          }, 1500);
        }
      });
    },
    feedGet() {
      this.feed.isLoading = true;
      this.$store.dispatch('feedGet', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
        filter: this.$store.getters.getFilter,
        order: this.getComputedOrder,
        offset: this.feed.offset
      })
      .then((result) => {
        if (result.success) {
          const newUsers = this.$store.getters.getUsers;
          if(newUsers.length == 0) {
            this.feed.isDataEnd = true;
          }
          else {
            this.feed.users = [...this.feed.users, ...newUsers];
            this.feed.offset += loadCount;
          }
          setTimeout(() => {
            this.feed.isLoading = false;
          }, 1000);
        }
        else {
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
        }
      });
    },
    getMe() {
      this.$store.dispatch('userMe', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID
      })
      .then((result) => {
        if(result.success) {

          this.profileImage = (
            this.$store.getters.getProfileImageThumbnail &&
            this.$store.getters.getProfileImageThumbnail !== "null" &&
            this.$store.getters.getProfileImageThumbnail.trim() !== ""
          ) ? this.$store.getters.getProfileImageThumbnail : require('@/assets/ic_profile_placeholder.png');

          if(this.$store.getters.getNickname == "deep") {
            shared.IS_PROFILE_UNSET = true;
            this.$router.push('/profile-init');
          }
          else if(this.$store.getters.getProfileStatus == "denied") {
            this.$router.push('/profile-edit?q=denied');
          }
          else if(this.$store.getters.getBlocked == "true") {
            this.snackbar.text = this.$t('userBlocked');
            this.snackbar.visibility = true;
            setTimeout(() => {
              this.$store.dispatch('resetState');
              this.$router.push('/');
            }, 1500);
          }
          else if(this.$store.getters.getDeleted == "true") {
            this.snackbar.text = this.$t('userDeleted');
            this.snackbar.visibility = true;
            setTimeout(() => {
              this.$store.dispatch('resetState');
              this.$router.push('/');
            }, 1500);
          }

          if(this.$store.getters.getFCMToken == "null") {
            this.openDialog('request_notification');
          }

          this.update();
          
          this.chatImage = require(`@/assets/ic_chat${this.$store.getters.getNewChat === "true" ? '_unread.png' : '.png'}`);
        }
        else {
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
        }
      })
    },
    async updateFCMToken(type) {
      this.$store.dispatch('setLoading', true);
      var currentToken = "";
      if(type == "refused" || type == "error") {
        currentToken = type;
      }
      else {
        const existingRegistration = await navigator.serviceWorker.getRegistration("firebase-messaging-sw.js");
        const registration = existingRegistration || await navigator.serviceWorker.register("firebase-messaging-sw.js");
        await navigator.serviceWorker.ready;
        currentToken = await getToken(this.$messaging, {
          serviceWorkerRegistration: registration,
          vapidKey: 'BAgbXzv6XuSGrcBXEr93BzMufGWGF8lxysC-s83orMvUZ8T6B9_5uWGeKydabK3BfG4dI5IbiErfeEbhIqggkgA'
        });
      }
      this.$store.commit('setFCMToken', currentToken);
      await this.$store.dispatch('userUpdateFCMToken', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
        fcmToken: currentToken
      })
      .then((result) => {
        if (result.success) {
          console.log("user updated");
        } else {
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
        }
      })
      .finally(() => {
        this.$store.dispatch('setLoading', false);
      });
    },
    update() {
      this.$store.dispatch('userUpdate', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
      })
      .then((result) => {
        if (result.success) {
          console.log("user updated");
        } else {
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
        }
      })
      .finally(() => {
        this.$store.dispatch('setLoading', false);
      });
    },
    setSocket() {
      this.socket = io(CHAT_SERVER, { forceNew: true });
      this.socket.on('connect', () => {
        console.log('Connected to server');
        this.socket.emit('join', `uid${this.$store.getters.getUID}`);
      });
      this.socket.on('chat', () => {
        this.getMe();
      });
    },
    reload(order) {
      this.feed.isDataEnd = false;
      this.feed.selectedOrder = order;
      this.feed.offset = 0;
      this.feed.users = [];
      this.feedGet();
    },
    profileBtnCkd(ouid) {
      this.$store.commit('setOUID', ouid);
      this.$router.push('/profile');
    },
    chatBtnCkd(ouid) {
      this.$store.dispatch('chatNew', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
        ouid: ouid
      })
      .then((result) => {
        if (result.success) {
          AnalyticsFunctions.logging(AnalyticsParams.CHAT_NEW);
          this.$store.commit('setRoomId', result.data);
          this.$store.commit('setOUID', ouid);
          this.$router.push('/chat');
        }
        else {
          switch (result.code) {
            case '5002':
              this.snackbar.text = this.$t('chatIntervalTooShort');
              break;
            case '5003':
              this.$router.push('/store')
              break;
            default:
              this.snackbar.text = this.$t('serverError');
              break;
          }
          this.snackbar.visibility = true;
        }
      });
    },
    // notification
    notificationBtnCkd() {
      if(this.$store.getters.getFCMToken === 'error' || this.$store.getters.getFCMToken === 'refused') {
        this.requestNotificationPermission();
      }
      else {
        this.updateFCMToken("refused")
      }
    },
    openDialog(action) {
      this.dialog.dialogAction = action;
      this.dialog.dialogTitle = action === 'request_notification' 
        ? this.$t('requestNotification')
        : this.$t('default');
      this.dialog.dialogVisibility = true;
    },
    confrimDialog() {
      if (this.dialog.dialogAction === 'request_notification') {
        this.requestNotificationPermission()
      }
      this.dialog.dialogVisibility = false;
    },
    refuseDialog() {
      this.updateFCMToken("refused");
      this.dialog.dialogVisibility = false;
    },
    async requestNotificationPermission() {
      try {
        if (Notification.permission === 'denied') {
          this.snackbar.text = functions.isPWA()
            ? this.$t('notificationAppAllow')
            : this.$t('notificationBrowserAllow');
          this.snackbar.visibility = true;
          return;
        }
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          this.updateFCMToken("granted");
        }
        else {
          this.updateFCMToken("refused");
        }
      } catch (error) {
        this.updateFCMToken("error");
      }
    },
    // else   
    openSurvey() {
      window.open('https://forms.gle/RB51iBPyM8Rbwbzn8', '_blank');
    },
    openURLNotify() {
      window.open('https://oct4ve.notion.site/Deeep-043360c32d1645fdb2fbd3910a141eaf?pvs=4', '_blank');
    },
    // isWithinGreenHours(dateString) {
    //   const now = new Date();
    //   const updated = new Date(dateString);
    //   const diffInHours = (now - updated) / (1000 * 60 * 60);
    //   return diffInHours <= 2;
    // },
    scrolled() {
      this.feed.scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      if (documentHeight - (this.feed.scrollPosition + windowHeight) < 100 && !this.feed.isLoading && !this.feed.isDataEnd) {
        this.feedGet();
      }
    },
  },
  created() {
    window.scrollTo(0, 0);
    this.isPWA = functions.isPWA();
    window.addEventListener('scroll', this.scrolled);
  },
  mounted() {
    this.tokenRefresh();
    this.feedGet();
    this.getMe();
    this.setSocket();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrolled);
    if (this.socket) {
      this.socket.off('chat');
      this.socket.disconnect();
    }
  },
};
</script>

<style scoped>

.main-order-btn {
  color: white;
  background-color: transparent;
  font-weight: normal;
}

.main-order-btn.selected-btn {
  color: var(--black-1);
  background-color: white;
  font-weight: bold;
}

.floating-btn {
  background-color: rgba(0, 0, 0, 0.7) !important;
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.3);
}
.floating-btn-size {
  width: 48px;
  height: 48px;
}

.app-tool-bar {
  top:60px;
  background-color: var(--black-1) !important;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2) !important;
}

@media (max-width: 600px) {
  .main-cell-profile-image-view {
    height: 140px !important;
  }
  .floating-btn-size {
    width: 42px;
    height: 42px;
  }
}

</style>
<template>
  <v-container fluid>

	<article id="d098548f-cd0e-4a92-8c1a-89c077686529" class="page sans">
    <div class="text-left page-body">
        <p id="fffc0936-3add-8167-b108-d6785da7853b" class="">
        </p>
        <h3 id="fffc0936-3add-812a-b9f8-d316f749d041" class=""><strong>1. 개요</strong></h3>
        <hr id="fffc0936-3add-8114-b81c-d800b674fcc5" />
        <ul id="fffc0936-3add-81fd-8e56-fca9c146485e" class="bulleted-list">
            <li style="list-style-type:disc">본 이용약관(이하 &#x27;약관&#x27;)은 Deeep 서비스(이하 &#x27;서비스&#x27;)의 이용에 대한 조건을 정한 문서입니다. Deeep는 Deeep.aeida.io<mark class="highlight-default"> </mark>웹사이트, Deeep 모바일 앱, 또는 기타 플랫폼이나 서비스를 통해 다양한 서비스를 제공합니다.</li>
        </ul>
        <ul id="fffc0936-3add-81aa-9031-f5dc8935ab57" class="bulleted-list">
            <li style="list-style-type:disc">Deeep 서비스를 이용하기 전에 본 이용약관을 주의 깊게 읽어보시기 바랍니다. 본 계약은 Deeep 서비스에 접속하거나 이를 이용하는 모든 사용자에게 당사의 개인정보 처리방침, 가이드라인을 포함하여 본 이용약관을 준수할 것에 동의하는 것으로 간주합니다. </li>
        </ul>
        <ul id="fffc0936-3add-816b-aa7d-f055a5ea6055" class="bulleted-list">
            <li style="list-style-type:disc">본 약관에 동의하지 않는 경우 Deeep 서비스를 이용할 수 없습니다. 본 계약에 구속되기를 원하지 않는 경우 당사 서비스에 접속하거나 사용하지 마십시오.</li>
        </ul>
        <ul id="fffc0936-3add-8103-ab53-f666b05c39cb" class="bulleted-list">
            <li style="list-style-type:disc">Deeep는 서비스 개선을 위해 당사는 약관을 수시로 변경할 수 있습니다. 변경 내용은 Deeep 웹사이트 또는 앱에서 확인할 수 있으며, 특정 상황에서 당사는 귀하에게 앱 푸시 알림 또는 기타 수단을 통해 통지할 수 있습니다. 그러나 정기적으로 이 페이지를 방문하여 약관 변경 사항을 확인해야 것이 귀하의 책임입니다. </li>
        </ul>
        <ul id="fffc0936-3add-8187-9a22-c9a4ba95f41a" class="bulleted-list">
            <li style="list-style-type:disc">귀하가 당사 서비스를 계속 이용하는 것은 변경된 약관에 대한 귀하의 동의를 의미합니다. 약관 변경에 동의하지 않는 경우 서비스 이용을 중단해야 합니다.</li>
        </ul>
        <ul id="fffc0936-3add-81fe-903f-dcfae3744f1f" class="bulleted-list">
            <li style="list-style-type:disc">귀하는 회원 가입 시 기재한 개인정보의 내용에 변경이 발생한 경우, 즉시 변경사항을 정정하여 기재하여야 합니다. 변경의 지체로 인하여 발생한 회원의 손해에 대해 회사는 책임을 지지 않습니다. </li>
        </ul>
        <ul id="fffc0936-3add-810a-a3f5-f2a1b0b0ae9f" class="bulleted-list">
            <li style="list-style-type:disc">회사는 관련 법률 및 회사의 개인정보처리방침에서 정한 바에 따라 귀하에게 요청하는 회원정보 및 기타정보 항목을 추가, 삭제 등 변경하여 수집 및 이용할 수 있습니다.</li>
        </ul>
        <p id="fffc0936-3add-8145-86d9-eaaca77c1883" class="">
        </p>
        <h3 id="fffc0936-3add-817f-8c54-e33cf270ccc6" class="">2. 회원의 자격 및 의무</h3>
        <hr id="fffc0936-3add-8170-b1b8-dfeeffa9df4a" />
        <ul id="fffc0936-3add-8174-9f40-ceec991f6fe5" class="bulleted-list">
            <li style="list-style-type:disc">귀하는 다음과 같은 행위를 해서는 안됩니다.<ul id="fffc0936-3add-8189-8938-f6ab8b9ee482" class="bulleted-list">
                    <li style="list-style-type:circle">회원 가입 또는 회원정보 변경 시 허위내용을 등록</li>
                </ul>
                <ul id="fffc0936-3add-816e-b8e4-dfb979aa4559" class="bulleted-list">
                    <li style="list-style-type:circle">타인의 정보를 도용</li>
                </ul>
                <ul id="fffc0936-3add-81c1-99fc-f58eadaa7f9f" class="bulleted-list">
                    <li style="list-style-type:circle">다른 회원의 계정을 사용</li>
                </ul>
                <ul id="fffc0936-3add-8155-a198-d6de2f0233d4" class="bulleted-list">
                    <li style="list-style-type:circle">다른 회원 및 기타 제3자에게 금전 거래를 요구하거나 개인정보를 요구</li>
                </ul>
                <ul id="fffc0936-3add-81b8-aaa2-d8b0fa1bc12b" class="bulleted-list">
                    <li style="list-style-type:circle">다른 회원의 비밀번호, 개인정보를 취득 혹은 공개</li>
                </ul>
                <ul id="fffc0936-3add-81e4-a39d-f3de01e31482" class="bulleted-list">
                    <li style="list-style-type:circle">허위사실 유포 및 사기</li>
                </ul>
                <ul id="fffc0936-3add-81e2-bfda-cb6cb85a8820" class="bulleted-list">
                    <li style="list-style-type:circle">라이선스 약관을 위반</li>
                </ul>
                <ul id="fffc0936-3add-81e4-af0b-d51fd1da241e" class="bulleted-list">
                    <li style="list-style-type:circle">금지된 콘텐츠 약관을 위반</li>
                </ul>
                <ul id="fffc0936-3add-8173-a859-e2237092d877" class="bulleted-list">
                    <li style="list-style-type:circle">자신의 종교적 신념을 표현하거나 타인의 종교를 비하</li>
                </ul>
                <ul id="fffc0936-3add-812f-84fe-c2c0d9c38fa6" class="bulleted-list">
                    <li style="list-style-type:circle">허위로 회원의 행동이나 콘텐츠에 대한 신고를 제출하거나, 신고 및 문의 기능을 남용</li>
                </ul>
                <ul id="fffc0936-3add-8122-b9b5-e73914b46432" class="bulleted-list">
                    <li style="list-style-type:circle">회사와 다른 회원 및 기타 제3자를 희롱하거나, 욕설·비속어 등을 사용하거나, 위협하거나 명예를 손상</li>
                </ul>
                <ul id="fffc0936-3add-8199-b604-ce2faeb33bf4" class="bulleted-list">
                    <li style="list-style-type:circle">회사의 운영자, 임직원, 회사를 사칭 </li>
                </ul>
                <ul id="fffc0936-3add-8181-a564-c5d600f05c32" class="bulleted-list">
                    <li style="list-style-type:circle">회원의 진술 및 정보를 귀사가 보증하였다고 표현하거나 암시</li>
                </ul>
                <ul id="fffc0936-3add-81fc-98ad-de123ca1740d" class="bulleted-list">
                    <li style="list-style-type:circle">서비스를 훼손하거나 다른 사용자의 서비스 이용을 방해</li>
                </ul>
                <ul id="fffc0936-3add-81a6-bfb2-cc7332376173" class="bulleted-list">
                    <li style="list-style-type:circle">회사의 서비스 어느 부분을 수정, 개조, 변경, 재라이선스, 판매</li>
                </ul>
                <ul id="fffc0936-3add-81ce-a8d7-cfe39fc66619" class="bulleted-list">
                    <li style="list-style-type:circle">당사 서비스를 유해하거나 불법적이거나 악의적인 목적으로 사용</li>
                </ul>
                <ul id="fffc0936-3add-81fc-be89-fe36cc99c03b" class="bulleted-list">
                    <li style="list-style-type:circle">회원 콘텐츠 또는 당사 콘텐츠 또는 저작권이 있는 자료, 이미지, 상표, 상호 또는 당사 서비스를 통해 이용 가능한 기타 지적재산, 콘텐츠 또는 독점 정보를 복사, 수정, 전송, 배포하거나 파생 저작물을 생성</li>
                </ul>
                <ul id="fffc0936-3add-81a1-b4d1-d93e602ddff5" class="bulleted-list">
                    <li style="list-style-type:circle">당사 서비스의 보안을 손상시키는 악성코드나 데이터 등을 유포</li>
                </ul>
                <ul id="fffc0936-3add-81c8-8737-ec0de12d9a3a" class="bulleted-list">
                    <li style="list-style-type:circle">당사 서비스의 어떤 부분을 도용 혹은 반영</li>
                </ul>
                <ul id="fffc0936-3add-8130-8fad-db2179ddce3b" class="bulleted-list">
                    <li style="list-style-type:circle">당사 서비스로 사칭하여 누군가를 다른 플랫폼으로 안내</li>
                </ul>
                <ul id="fffc0936-3add-8190-89d4-f1491404c420" class="bulleted-list">
                    <li style="list-style-type:circle">본 약관을 위반하는 활동을 장려, 조장 또는 참여에 동의</li>
                </ul>
            </li>
        </ul>
        <ul id="fffc0936-3add-81c4-af1c-e6083804910e" class="bulleted-list">
            <li style="list-style-type:disc">귀하는 귀하의 콘텐츠에 대한 책임이 있습니다. 다른 사람에게 보여주고 싶지 않거나, 본 계약을 위반하거나, 귀하 또는 당사가 법적 책임을 지게 할 수 있는 내용을 공유하지 마십시오.</li>
        </ul>
        <ul id="fffc0936-3add-815a-93bd-fa199b453ee5" class="bulleted-list">
            <li style="list-style-type:disc">귀하는 다음과 같은 금지된 콘텐츠 약관을 위반해서는 안됩니다. (콘텐츠는 당사 서비스에서 제공하는 콘텐츠 및 귀하가 제공하거나 게시하는 모든 콘텐츠를 포함)<ul id="fffc0936-3add-81f4-913d-fed3865c1024" class="bulleted-list">
                    <li style="list-style-type:circle">타인에게 불쾌감을 주거나 괴롭히거나 학대하거나 정신적 고통을 야기하는 콘텐츠</li>
                </ul>
                <ul id="fffc0936-3add-8118-aebe-e2face99250b" class="bulleted-list">
                    <li style="list-style-type:circle">외설적이거나, 음란하거나, 폭력적이거나, 과도한 노출이 포함된 콘텐츠</li>
                </ul>
                <ul id="fffc0936-3add-81ba-be85-fc0f5196411a" class="bulleted-list">
                    <li style="list-style-type:circle">모욕적이거나, 위협적이거나, 차별적이거나, 인종차별, 성차별, 증오 또는 편견을 조장하거나 장려하는 콘텐츠</li>
                </ul>
                <ul id="fffc0936-3add-81fd-8471-ffd35948094f" class="bulleted-list">
                    <li style="list-style-type:circle">테러리즘, 인종적 증오 선동 또는 그 자체로 형사 범죄를 저지르는 것으로 간주되는 것을 포함하되 이에 국한되지 않는 모든 불법 행위를 장려하거나 촉진하는 콘텐츠</li>
                </ul>
                <ul id="fffc0936-3add-8178-b25b-e1389b57ddf1" class="bulleted-list">
                    <li style="list-style-type:circle">명예를 훼손하거나, 비방하거나, 사실이 아닌 콘텐츠</li>
                </ul>
                <ul id="fffc0936-3add-819e-90d5-d967f1bfb9b8" class="bulleted-list">
                    <li style="list-style-type:circle">당사 서비스 의도와 무관한 활동(영업, 판촉 및 광고, 서비스 권유, 성노동, 타 플랫폼 계정/오픈채팅 링크 등)과 관련된 콘텐츠</li>
                </ul>
                <ul id="fffc0936-3add-813a-b5bf-cdc7efac3788" class="bulleted-list">
                    <li style="list-style-type:circle">귀하 또는 다른 사람과 관련된 개인 연락처, 은행 정보 또는 결제 정보가 공개된 콘텐츠</li>
                </ul>
                <ul id="fffc0936-3add-81ca-b48e-f33651aef589" class="bulleted-list">
                    <li style="list-style-type:circle">스팸 전송에 관여하거나 이를 촉진하는 콘텐츠</li>
                </ul>
                <ul id="fffc0936-3add-816b-b861-ec377e53090e" class="bulleted-list">
                    <li style="list-style-type:circle">소프트웨어, 하드웨어, 통신, 네트워크, 서버 또는 기타 장비의 기능을 중단, 손상 또는 제한하거나 방해하도록 설계된 스파이웨어, 애드웨어, 바이러스, 손상된 파일 또는 기타 악성 코드를 포함하거나, 당사의 데이터 또는 회원의 개인정보를 손상, 방해, 부당하게 가로채거나 탈취하도록 설계된 트로이 목마 또는 기타 위해물을 포함한 콘텐츠</li>
                </ul>
                <ul id="fffc0936-3add-81ad-8571-dba1f461b508" class="bulleted-list">
                    <li style="list-style-type:circle">제3자의 권리(지적 재산권 및 개인정보 보호 권리를 포함하되 이에 국한되지 않음)를 침해하는 콘텐츠</li>
                </ul>
                <ul id="fffc0936-3add-81fe-9759-f8907728ccf2" class="bulleted-list">
                    <li style="list-style-type:circle">타인의 이미지 또는 초상을 포함한 콘텐츠</li>
                </ul>
                <ul id="fffc0936-3add-817e-9c94-f566280c86e2" class="bulleted-list">
                    <li style="list-style-type:circle">미성년자의 이미지 또는 초상을 포함하는 콘텐츠 또는 성행위를 하는 미성년자를 묘사 또는 암시하는 콘텐츠</li>
                </ul>
                <ul id="fffc0936-3add-8154-b882-fb5d92d4cac6" class="bulleted-list">
                    <li style="list-style-type:circle">당사 서비스의 의도된 사용과 일치하지 않는 콘텐츠</li>
                </ul>
                <ul id="fffc0936-3add-81a1-bf4b-cb2c565932bc" class="bulleted-list">
                    <li style="list-style-type:circle">당사 서비스의 평판을 훼손할 수 있는 콘텐츠</li>
                </ul>
            </li>
        </ul>
        <ul id="fffc0936-3add-81ff-8c91-ef49b04c40cc" class="bulleted-list">
            <li style="list-style-type:disc">본 서비스 이용 시 개인정보 공개는 필수적이지만, 자신에 대한 개인 정보를 다른 사용자에게 공개하기로 선택한 경우 그에 따른 위험은 귀하가 감수해야 합니다. 온라인에 개인 정보를 공개할 때는 귀하가 위험성을 충분히 인지하고, 안전을 위한 노력을 기울여야 합니다.</li>
        </ul>
        <ul id="fffc0936-3add-81a1-ba5d-caff9531279b" class="bulleted-list">
            <li style="list-style-type:disc">서비스 안팎에서 부적절한 콘텐츠나 위법 행위를 용납하지 않습니다. 부적절한 회원 콘텐츠 또는 다른 사용자의 위법 행위를 신고할 것을 권장합니다. 사용자 프로필의 신고기능을 통해 또는 채팅 환경에서 사용자를 신고할 수 있습니다. 고객지원에 문의할 수도 있습니다. </li>
        </ul>
        <ul id="fffc0936-3add-81b6-954c-cc653b5dfc01" class="bulleted-list">
            <li style="list-style-type:disc">귀하가 본 약관 및 관계 법령을 위반할 경우, 다른 회원을 보호하고, 법원, 수사기관 또는 관련 기관의 요청에 따른 증거자료로 활용하기 위해 계정 차단 혹은 탈퇴 후에도 귀하의 아이디 및 회원정보를 보관할 수 있습니다. 귀하의 콘텐츠 또는 저작물이 회사 또는 제3자의 저작권 등 지적재산권을 침해함으로써 발생하는 민•형사상의 책임은 전적으로 귀하가 부담하여야 합니다.</li>
        </ul>
        <ul id="fffc0936-3add-81b9-80b7-c8c0aa445313" class="bulleted-list">
            <li style="list-style-type:disc">귀하는 다음과 같은 라이선스 약관을 위반해서는 안됩니다. <ul id="fffc0936-3add-8104-b9b3-e729b7f2354f" class="bulleted-list">
                    <li style="list-style-type:circle">Deeep는 귀하가 당사 서비스에 업로드하는 모든 콘텐츠에 대한 호스팅, 저장, 사용, 복사, 전시, 복제, 각색, 편집, 출판, 번역, 수정, 재포맷, 다른 저작물에 통합, 광고, 배포 및 기타 일반 대중이 이용할 수 있도록 하는 권리 및 라이선스를 부여받습니다. 해당 권리 및 라이선스는 전 세계 어디서나 유효하고, 영구적, 양도 가능, 재라이선스 가능, 무료 라이선스를 부여받습니다.</li>
                </ul>
                <ul id="fffc0936-3add-816f-90e9-fc61105ac085" class="bulleted-list">
                    <li style="list-style-type:circle">Deeep는 또한 귀하의 콘텐츠를 포함하는 서비스의 스크린샷에 대한 독점적 라이선스를 보유합니다. 즉, Deeep는 귀하의 콘텐츠를 다양한 방식으로 사용할 수 있는 권한을 가지며, 이 권한은 제한 없이 전 세계 어디서나 영구적으로 유효합니다. 또한 Deeep는 귀하의 동의 없이 이 권한을 다른 회사에 양도하거나 재라이선스할 수 있습니다.</li>
                </ul>
                <ul id="fffc0936-3add-81d6-b112-caf47243b5b6" class="bulleted-list">
                    <li style="list-style-type:circle">Deeep는 다른 사용자 또는 제3자가 서비스에서 획득한 귀하의 콘텐츠를 권리를 침해하는 방식으로 사용하는 것을 방지할 수 있습니다. 이는 Deeep가 귀하를 대신하여 법적 조치를 취하거나 다른 적절한 조치를 취할 수 있음을 의미합니다. 즉, Deeep는 귀하의 콘텐츠를 보호하기 위해 노력하지만, 다른 사용자가 귀하의 콘텐츠를 무단으로 사용하는 것을 완전히 방지할 수는 없다는 점을 유념해야 합니다.</li>
                </ul>
                <ul id="fffc0936-3add-812e-b211-e61aae93f5d9" class="bulleted-list">
                    <li style="list-style-type:circle">Deeep는 당사, 계열사 및 제3자 파트너가 당사 서비스에 광고를 게재할 수 있으며, 이 광고는 귀하의 콘텐츠와 관련될 수 있습니다. Deeep는 광고 수익을 얻을 수 있으며, 이 수익은 서비스 운영 및 유지 관리에 사용됩니다. 즉, Deeep 서비스를 사용하면 광고를 보게 되며, 이 광고는 귀하의 관심사와 관련될 가능성이 높다는 점을 유념해야 합니다.</li>
                </ul>
                <ul id="fffc0936-3add-811a-be7d-d527d5364ab4" class="bulleted-list">
                    <li style="list-style-type:circle">Deeep 서비스와 관련하여 제출하는 의견 및 제안은 Deeep가 보상 없이 어떠한 목적으로도 사용하고 공유할 수 있습니다. 즉, Deeep는 귀하의 의견을 사용하여 서비스를 개선하거나 다른 목적으로 사용할 수 있으며, 이에 대한 보상을 받지 못할 수 있다는 점을 유념해야 합니다.</li>
                </ul>
            </li>
        </ul>
        <ul id="fffc0936-3add-81e7-9e19-e0b383a86d17" class="bulleted-list">
            <li style="list-style-type:disc">당사 서비스에 접근할 수 있는 모든 권한은 귀하가 본 약관을 준수하지 않을 경우 자동으로 취소됩니다.</li>
        </ul>
        <p id="fffc0936-3add-81bb-bab4-c28b3dee0b48" class="">
        </p>
        <h3 id="fffc0936-3add-816d-8f3b-ffba9bd24eca" class=""><strong>3. 결제, 환불 및 결제 취소</strong></h3>
        <hr id="fffc0936-3add-817c-9bb4-d0c202cb8541" />
        <ul id="fffc0936-3add-81d7-a907-c82a26c36467" class="bulleted-list">
            <li style="list-style-type:disc">환불은 결제일로부터 3일 이내에 요청한 경우에만 가능합니다. 결제일로부터 3일이 경과한 후에는 어떠한 경우에도 환불이 불가능합니다.</li>
        </ul>
        <ul id="66b4fb5b-4453-4523-8516-b34118c933d1" class="bulleted-list">
            <li style="list-style-type:disc">다만, 유료 아이템을 사용한 이력이 있는 경우 어떠한 경우에도 환불이 불가능합니다.</li>
        </ul>
        <ul id="723ae3c8-ddc4-4c57-b103-f8c7dc35e03d" class="bulleted-list">
            <li style="list-style-type:disc">환불 처리는 원칙적으로 결제 수단의 취소를 통해 진행됩니다.</li>
        </ul>
        <ul id="9ad00b3a-7639-4547-ae19-bbf4a4043498" class="bulleted-list">
            <li style="list-style-type:disc">환불 요청 접수 후 영업일 기준 14일 이내에 처리됩니다. 단, 결제 수단에 따라 3~7일이 추가로 소요될 수 있습니다.</li>
        </ul>
        <ul id="878c9a9b-7a98-49a6-a50f-0a0d91bad7a1" class="bulleted-list">
            <li style="list-style-type:disc">고객센터를 통해 환불을 요청할 수 있습니다. 환불 요청 시 구매 내역, 환불 사유 등을 명확히 기재해야 합니다.</li>
        </ul>
        <p id="fffc0936-3add-8132-8027-dcb54c7f325e" class="">
        </p>
        <h3 id="fffc0936-3add-81d6-80af-e75ea8b39cba" class="">4. 콘텐츠 권리 및 책임</h3>
        <hr id="fffc0936-3add-81c1-b664-dc8db1611a1f" />
        <ul id="fffc0936-3add-8172-b2d6-c8e85ef36b42" class="bulleted-list">
            <li style="list-style-type:disc">당사 서비스에 업로드하는 모든 콘텐츠에 대한 책임과 권리는 귀하에게 있습니다.</li>
        </ul>
        <ul id="fffc0936-3add-81a2-9b2d-e30ec80682ce" class="bulleted-list">
            <li style="list-style-type:disc">귀하는 귀하의 콘텐츠에 대해 전적으로 책임을 지며, 따라서 귀하는 귀하의 콘텐츠와 관련하여 제기된 모든 청구로부터 당사를 면책, 방어, 면제하고 해를 입히지 않을 것에 동의합니다. </li>
        </ul>
        <ul id="fffc0936-3add-810b-af72-d474027d4b25" class="bulleted-list">
            <li style="list-style-type:disc">귀하의 개인 프로필에 포함된 콘텐츠는 당사 서비스의 의도된 사용과 관련이 있어야 합니다. 귀하는 금지된 콘텐츠, 가이드라인을 위반하는 콘텐츠는 업로드할 수 없습니다.</li>
        </ul>
        <ul id="fffc0936-3add-81aa-b823-d0f1b893b308" class="bulleted-list">
            <li style="list-style-type:disc">당사는 다른 회원이 작성한 콘텐츠에 대해서 감시 및 관리할 수 있지만, 이에 대해서 책임지지 않습니다. 당사는 회원이 등록하는 게시물의 신뢰성, 진실성, 정확성 등에 대해서 책임지지 않으며 보증하지 않습니다. </li>
        </ul>
        <ul id="fffc0936-3add-814a-aac3-e962b44d44fc" class="bulleted-list">
            <li style="list-style-type:disc">당사는 귀하의 콘텐츠를 모니터링하거나 검토할 수 있으며, 단독 재량에 따라 언제든지 귀하의 콘텐츠를 제거, 삭제, 편집, 제한, 차단하거나 접속을 방지할 수 있습니다.</li>
        </ul>
        <p id="fffc0936-3add-818b-aa6e-c09c3b30ee87" class="">
        </p>
        <h3 id="fffc0936-3add-816c-a4d1-dca3b2f7a149" class="">5. 책임 제한</h3>
        <hr id="fffc0936-3add-8168-91ba-d0952f57601f" />
        <ul id="fffc0936-3add-81e5-b230-fb6d30699bf6" class="bulleted-list">
            <li style="list-style-type:disc">당사는 Deeep 서비스의 완벽성이나 안정성을 보장하지 않으며, 서비스 이용 과정에서 발생하는 문제에 대한 책임을 지지 않습니다.</li>
        </ul>
        <ul id="fffc0936-3add-8187-ac35-eea67d253a8b" class="bulleted-list">
            <li style="list-style-type:disc">당사는 다음과 같은 보증을 제공하지 않습니다.<ul id="fffc0936-3add-8180-a7a4-cc7221429d02" class="bulleted-list">
                    <li style="list-style-type:circle">서비스의 중단 없음, 안전성, 오류 없음</li>
                </ul>
                <ul id="fffc0936-3add-8159-883f-cdf4c560e67d" class="bulleted-list">
                    <li style="list-style-type:circle">서비스의 결함이나 오류의 발견 및 수정</li>
                </ul>
                <ul id="fffc0936-3add-81e9-856a-e439b92463ba" class="bulleted-list">
                    <li style="list-style-type:circle">서비스에서 제공되는 콘텐츠 또는 정보의 정확성, 완전성, 최신성, 적합성</li>
                </ul>
                <ul id="fffc0936-3add-8137-9089-fe43c9d54c25" class="bulleted-list">
                    <li style="list-style-type:circle">특정 시점의 활성 사용자 수</li>
                </ul>
                <ul id="fffc0936-3add-8169-bb1c-cb98f9a01b6a" class="bulleted-list">
                    <li style="list-style-type:circle">사용자와의 의사소통 또는 만남 가능성</li>
                </ul>
                <ul id="fffc0936-3add-81f7-a73d-e9161cbd0023" class="bulleted-list">
                    <li style="list-style-type:circle">서비스를 통해 만나는 사용자와의 궁극적인 호감도 또는 행위</li>
                </ul>
            </li>
        </ul>
        <ul id="fffc0936-3add-81b7-aff6-d30d893fd498" class="bulleted-list">
            <li style="list-style-type:disc">당사 서비스를 통해 다운로드하거나 획득한 모든 자료는 귀하의 재량 및 위험 부담으로 접속됩니다. Deeep는 다운로드 과정에서 발생하는 손상에 대한 책임을 지지 않습니다.</li>
        </ul>
        <ul id="fffc0936-3add-816f-adae-c1063af32172" class="bulleted-list">
            <li style="list-style-type:disc">당사는 다음과 같은 손상에 대한 책임을 지지 않습니다.<ul id="fffc0936-3add-811c-8174-e117ed414d51" class="bulleted-list">
                    <li style="list-style-type:circle">보안 위반 또는 바이러스, 버그, 변조, 해킹, 사기, 오류, 누락, 중단, 결함, 작동 또는 전송 지연</li>
                </ul>
                <ul id="fffc0936-3add-81d1-ae94-deb28e462796" class="bulleted-list">
                    <li style="list-style-type:circle">컴퓨터 회선 또는 네트워크 장애</li>
                </ul>
                <ul id="fffc0936-3add-8111-88b6-e3122930c038" class="bulleted-list">
                    <li style="list-style-type:circle">기타 기술적 또는 기타 방해 또는 오작동</li>
                </ul>
            </li>
        </ul>
        <ul id="fffc0936-3add-817e-813c-ddcee39271e5" class="bulleted-list">
            <li style="list-style-type:disc">당사는 관련 법률이 허용하는 최대한의 범위 내에서 다음과 같은 책임을 지지 않습니다.<ul id="fffc0936-3add-8120-b5c1-c7ae5f345f1e" class="bulleted-list">
                    <li style="list-style-type:circle">간접적, 결과적, 예시적, 우발적, 특수적, 징벌적, 고정적 또는 가중적 손해</li>
                </ul>
                <ul id="fffc0936-3add-81b6-baf9-d39194f2ded3" class="bulleted-list">
                    <li style="list-style-type:circle">서비스 접속 및 사용 또는 접속 및 사용 불능으로 인한 손해</li>
                </ul>
                <ul id="fffc0936-3add-8129-955b-f9ec5a408b6a" class="bulleted-list">
                    <li style="list-style-type:circle">서비스에서 또는 서비스를 통해 또는 서비스와 관련된 사용자 또는 제3자의 행위 또는 콘텐츠로 인한 손해</li>
                </ul>
                <ul id="fffc0936-3add-8156-a49c-df1ea93bb357" class="bulleted-list">
                    <li style="list-style-type:circle">사용자의 콘텐츠에 대한 무단 접속, 사용 또는 변경으로 인한 손해</li>
                </ul>
            </li>
        </ul>
        <ul id="fffc0936-3add-814c-84aa-f24e276afa7c" class="bulleted-list">
            <li style="list-style-type:disc">서비스 이용과 관련된 모든 청구에 대한 Deeep의 최대 책임 한도는 한화 100,000원입니다. 만약 사용자가 지난 24개월 동안 Deeep에 지불한 금액이 한화 100,000원보다 크다면, 최대 책임 한도는 해당 지불 금액으로 설정됩니다. 전술한 손해 배상 한도는 계약 위반, 불법 행위, 법령 위반, 의무 위반 등 책임의 근거에 관계없이 당사 서비스 이용과 관련된 모든 사건, 서비스 및 본 계약에 적용됩니다.</li>
        </ul>
        <p id="fffc0936-3add-81c6-9827-f1c74b7be6a1" class="">
        </p>
        <h3 id="fffc0936-3add-813c-95ae-eb81e80879f0" class="">6. 광고의 게제</h3>
        <hr id="fffc0936-3add-81e0-80b6-f3103305cf4a" />
        <ul id="fffc0936-3add-8178-8a52-d3aec41b6a11" class="bulleted-list">
            <li style="list-style-type:disc">당사 서비스는 다양한 제3자가 제공하는 광고, 프로모션, 웹사이트 링크, 가상 아이템 등을 포함합니다. 이러한 제3자와의 상호 작용 시 다음 사항에 유의하시기 바랍니다.</li>
        </ul>
        <ul id="fffc0936-3add-81e4-8513-c8a75ade2e3b" class="bulleted-list">
            <li style="list-style-type:disc">Deeep는 다른 웹사이트 또는 정보로의 링크가 포함될 수 있습니다. 당사는 외부 웹사이트 또는 정보의 가용성, 콘텐츠, 안전성에 대해 책임을 지지 않습니다. 외부 웹사이트 또는 정보에서 제공되는 상품이나 서비스로 인해 발생하는 손실과 손해에 대해 Deeep는 책임을 지지 않으며, 그 안전성이나 신뢰성을 보증하지 않습니다.</li>
        </ul>
        <ul id="fffc0936-3add-8160-bf57-ee6d682df1f0" class="bulleted-list">
            <li style="list-style-type:disc">당사 서비스에서 제공된 제3자와 상호 작용하기로 선택한 경우, 해당 제3자의 약관이 적용됩니다. Deeep는 제3자와의 상호 작용으로 인해 발생하는 문제에 대해 책임을 지지 않습니다. 제3자의 조건 또는 행동에 대한 책임은 사용자에게 있으며, Deeep는 어떠한 법적 책임도 지지 않습니다.</li>
        </ul>
        <p id="fffc0936-3add-81dc-9b70-d96aa4fb75c1" class="">
        </p>
        <h3 id="fffc0936-3add-814b-943c-d35427a25c8f" class="">7. 약관 동의 및 합의</h3>
        <hr id="fffc0936-3add-813a-87a8-e543230e82b2" />
        <ul id="fffc0936-3add-81ba-b8e5-cfd6f13b3d73" class="bulleted-list">
            <li style="list-style-type:disc">당사 서비스를 이용함으로써 사용자는 다음 내용에 동의하게 됩니다.<ul id="fffc0936-3add-814c-8495-fe29ce394a37" class="bulleted-list">
                    <li style="list-style-type:circle">이용약관: Deeep는 서비스 약관을 수시로 수정할 수 있으며, 사용자는 최신 약관에 구속됩니다.</li>
                </ul>
                <ul id="fffc0936-3add-8113-8f00-fad0b63d1096" class="bulleted-list">
                    <li style="list-style-type:circle">개인정보 처리방침: Deeep는 사용자의 개인정보를 수집, 이용, 처리합니다. 사용자는 개인정보 처리방침을 확인하고 동의해야 합니다.</li>
                </ul>
                <ul id="fffc0936-3add-8189-869b-cbbb64a25638" class="bulleted-list">
                    <li style="list-style-type:circle">쿠키 정책: Deeep는 쿠키를 사용하여 사용자 경험을 개선합니다. 사용자는 쿠키 정책을 확인하고 동의해야 합니다.</li>
                </ul>
            </li>
        </ul>
        <ul id="fffc0936-3add-8126-be10-f7b10fb43d85" class="bulleted-list">
            <li style="list-style-type:disc">본 계약은 개인정보 처리방침과 함께 Deeep 서비스 이용과 관련하여 사용자와 Deeep 간의 모든 합의를 담고 있습니다.</li>
        </ul>
        <ul id="fffc0936-3add-8114-867e-dd5c9d8afcce" class="bulleted-list">
            <li style="list-style-type:disc">본 계약은 서면 또는 구두로 이루어진 당사자 간의 이전의 모든 계약, 진술 및 약정을 대체합니다. Deeep가 본 계약의 권리 또는 조항을 행사하거나 집행하지 않는다고 해서 해당 권리 또는 조항을 포기하는 것으로 간주되지 않습니다.</li>
        </ul>
        <ul id="fffc0936-3add-81f1-9976-edda0bc5c2de" class="bulleted-list">
            <li style="list-style-type:disc">사용자는 자신의 Deeep 계정을 양도할 수 없습니다. 사용자가 사망하면 해당 사용자의 Deeep 계정 및 그 콘텐츠의 권리는 해지됩니다. 본 계약에 따라 부여된 모든 권리 및 라이선스는 사용자가 양도하거나 양도할 수 없으나 Deeep는 제한 없이 양도할 수 있습니다. </li>
        </ul>
        <ul id="fffc0936-3add-81fb-a271-c74bdcdb53f4" class="bulleted-list">
            <li style="list-style-type:disc">본 계약은 어떤 대리인, 파트너십, 합작투자, 신탁 또는 기타 특별한 관계나 고용도 생성하지 않습니다. 사용자는 어떤 방식으로든 Deeep를 대표하거나 구속할 수 없습니다.</li>
        </ul>
        <p id="fffc0936-3add-811e-a898-f054fe4bc349" class="">
        </p>
        <p id="fffc0936-3add-8187-9b98-e03065abb3a6" class="">
        </p>
        <p id="fffc0936-3add-817b-bad0-eae0442b41cb" class="">
        </p>
        <p id="fffc0936-3add-8162-9c19-c16a9a33045e" class="">[ 발효일 : 2024년 10월 25일 ]</p>
        <p id="fffc0936-3add-81e1-9ef7-c484cb991750" class="">
        </p>
		</div>
	</article><span class="sans" style="font-size:14px;padding-top:2em"></span>


  </v-container>
</template>
    
<script>
export default {
  name: 'SerivceTermsView',
}
</script>

<style scoped>

html {
	-webkit-print-color-adjust: exact;
}
* {
	box-sizing: border-box;
	-webkit-print-color-adjust: exact;
}

html,
body {
	margin: 0;
	padding: 0;
}
@media only screen {
	body {
		margin: 2em auto;
		max-width: 900px;
		color: rgb(55, 53, 47);
	}
}

body {
	line-height: 1.5;
	white-space: pre-wrap;
}

a,
a.visited {
	color: inherit;
	text-decoration: underline;
}

.pdf-relative-link-path {
	font-size: 80%;
	color: #444;
}

h1,
h2,
h3 {
	letter-spacing: -0.01em;
	line-height: 1.2;
	font-weight: 600;
	margin-bottom: 0;
}

.page-title {
	font-size: 2.5rem;
	font-weight: 700;
	margin-top: 0;
	margin-bottom: 0.75em;
}

h1 {
	font-size: 1.875rem;
	margin-top: 1.875rem;
}

h2 {
	font-size: 1.5rem;
	margin-top: 1.5rem;
}

h3 {
	font-size: 1.25rem;
	margin-top: 1.25rem;
}

.source {
	border: 1px solid #ddd;
	border-radius: 3px;
	padding: 1.5em;
	word-break: break-all;
}

.callout {
	border-radius: 3px;
	padding: 1rem;
}

figure {
	margin: 1.25em 0;
	page-break-inside: avoid;
}

figcaption {
	opacity: 0.5;
	font-size: 85%;
	margin-top: 0.5em;
}

mark {
	background-color: transparent;
}

.indented {
	padding-left: 1.5em;
}

hr {
	margin-top: 12px;
	background: transparent;
	display: block;
	width: 100%;
	height: 1px;
	visibility: visible;
	border: none;
	border-bottom: 1px solid rgba(55, 53, 47, 0.09);
}

img {
	max-width: 100%;
}

@media only print {
	img {
		max-height: 100vh;
		object-fit: contain;
	}
}

@page {
	margin: 1in;
}

.collection-content {
	font-size: 0.875rem;
}

.column-list {
	display: flex;
	justify-content: space-between;
}

.column {
	padding: 0 1em;
}

.column:first-child {
	padding-left: 0;
}

.column:last-child {
	padding-right: 0;
}

.table_of_contents-item {
	display: block;
	font-size: 0.875rem;
	line-height: 1.3;
	padding: 0.125rem;
}

.table_of_contents-indent-1 {
	margin-left: 1.5rem;
}

.table_of_contents-indent-2 {
	margin-left: 3rem;
}

.table_of_contents-indent-3 {
	margin-left: 4.5rem;
}

.table_of_contents-link {
	text-decoration: none;
	opacity: 0.7;
	border-bottom: 1px solid rgba(55, 53, 47, 0.18);
}

table,
th,
td {
	border: 1px solid rgba(55, 53, 47, 0.09);
	border-collapse: collapse;
}

table {
	border-left: none;
	border-right: none;
}

th,
td {
	font-weight: normal;
	padding: 0.25em 0.5em;
	line-height: 1.5;
	min-height: 1.5em;
	text-align: left;
}

th {
	color: rgba(55, 53, 47, 0.6);
}

ol,
ul {
	margin: 0;
	margin-block-start: 0.6em;
	margin-block-end: 0.6em;
}

li > ol:first-child,
li > ul:first-child {
	margin-block-start: 0.6em;
}

ul > li {
	list-style: disc;
}

ul.to-do-list {
	padding-inline-start: 0;
}

ul.to-do-list > li {
	list-style: none;
}

.to-do-children-checked {
	text-decoration: line-through;
	opacity: 0.375;
}

ul.toggle > li {
	list-style: none;
}

ul {
	padding-inline-start: 1.7em;
}

ul > li {
	padding-left: 0.1em;
}

ol {
	padding-inline-start: 1.6em;
}

ol > li {
	padding-left: 0.2em;
}

.mono ol {
	padding-inline-start: 2em;
}

.mono ol > li {
	text-indent: -0.4em;
}

.toggle {
	padding-inline-start: 0em;
	list-style-type: none;
}

/* Indent toggle children */
.toggle > li > details {
	padding-left: 1.7em;
}

.toggle > li > details > summary {
	margin-left: -1.1em;
}

.selected-value {
	display: inline-block;
	padding: 0 0.5em;
	background: rgba(206, 205, 202, 0.5);
	border-radius: 3px;
	margin-right: 0.5em;
	margin-top: 0.3em;
	margin-bottom: 0.3em;
	white-space: nowrap;
}

.collection-title {
	display: inline-block;
	margin-right: 1em;
}

.page-description {
    margin-bottom: 2em;
}

.simple-table {
	margin-top: 1em;
	font-size: 0.875rem;
	empty-cells: show;
}
.simple-table td {
	height: 29px;
	min-width: 120px;
}

.simple-table th {
	height: 29px;
	min-width: 120px;
}

.simple-table-header-color {
	background: rgb(247, 246, 243);
	color: black;
}
.simple-table-header {
	font-weight: 500;
}

time {
	opacity: 0.5;
}

.icon {
	display: inline-block;
	max-width: 1.2em;
	max-height: 1.2em;
	text-decoration: none;
	vertical-align: text-bottom;
	margin-right: 0.5em;
}

img.icon {
	border-radius: 3px;
}

.user-icon {
	width: 1.5em;
	height: 1.5em;
	border-radius: 100%;
	margin-right: 0.5rem;
}

.user-icon-inner {
	font-size: 0.8em;
}

.text-icon {
	border: 1px solid #000;
	text-align: center;
}

.page-cover-image {
	display: block;
	object-fit: cover;
	width: 100%;
	max-height: 30vh;
}

.page-header-icon {
	font-size: 3rem;
	margin-bottom: 1rem;
}

.page-header-icon-with-cover {
	margin-top: -0.72em;
	margin-left: 0.07em;
}

.page-header-icon img {
	border-radius: 3px;
}

.link-to-page {
	margin: 1em 0;
	padding: 0;
	border: none;
	font-weight: 500;
}

p > .user {
	opacity: 0.5;
}

td > .user,
td > time {
	white-space: nowrap;
}

input[type="checkbox"] {
	transform: scale(1.5);
	margin-right: 0.6em;
	vertical-align: middle;
}

p {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.image {
	border: none;
	margin: 1.5em 0;
	padding: 0;
	border-radius: 0;
	text-align: center;
}

.code,
code {
	background: rgba(135, 131, 120, 0.15);
	border-radius: 3px;
	padding: 0.2em 0.4em;
	border-radius: 3px;
	font-size: 85%;
	tab-size: 2;
}

code {
	color: #eb5757;
}

.code {
	padding: 1.5em 1em;
}

.code-wrap {
	white-space: pre-wrap;
	word-break: break-all;
}

.code > code {
	background: none;
	padding: 0;
	font-size: 100%;
	color: inherit;
}

blockquote {
	font-size: 1.25em;
	margin: 1em 0;
	padding-left: 1em;
	border-left: 3px solid rgb(55, 53, 47);
}

.bookmark {
	text-decoration: none;
	max-height: 8em;
	padding: 0;
	display: flex;
	width: 100%;
	align-items: stretch;
}

.bookmark-title {
	font-size: 0.85em;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 1.75em;
	white-space: nowrap;
}

.bookmark-text {
	display: flex;
	flex-direction: column;
}

.bookmark-info {
	flex: 4 1 180px;
	padding: 12px 14px 14px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.bookmark-image {
	width: 33%;
	flex: 1 1 180px;
	display: block;
	position: relative;
	object-fit: cover;
	border-radius: 1px;
}

.bookmark-description {
	color: rgba(55, 53, 47, 0.6);
	font-size: 0.75em;
	overflow: hidden;
	max-height: 4.5em;
	word-break: break-word;
}

.bookmark-href {
	font-size: 0.75em;
	margin-top: 0.25em;
}

.sans { font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol"; }
.code { font-family: "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace; }
.serif { font-family: Lyon-Text, Georgia, ui-serif, serif; }
.mono { font-family: iawriter-mono, Nitti, Menlo, Courier, monospace; }
.pdf .sans { font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK JP'; }
.pdf:lang(zh-CN) .sans { font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK SC'; }
.pdf:lang(zh-TW) .sans { font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK TC'; }
.pdf:lang(ko-KR) .sans { font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK KR'; }
.pdf .code { font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK JP'; }
.pdf:lang(zh-CN) .code { font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK SC'; }
.pdf:lang(zh-TW) .code { font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK TC'; }
.pdf:lang(ko-KR) .code { font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK KR'; }
.pdf .serif { font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji', 'Noto Color Emoji', 'Noto Serif CJK JP'; }
.pdf:lang(zh-CN) .serif { font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji', 'Noto Color Emoji', 'Noto Serif CJK SC'; }
.pdf:lang(zh-TW) .serif { font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji', 'Noto Color Emoji', 'Noto Serif CJK TC'; }
.pdf:lang(ko-KR) .serif { font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji', 'Noto Color Emoji', 'Noto Serif CJK KR'; }
.pdf .mono { font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK JP'; }
.pdf:lang(zh-CN) .mono { font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK SC'; }
.pdf:lang(zh-TW) .mono { font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK TC'; }
.pdf:lang(ko-KR) .mono { font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK KR'; }
.highlight-default {
	color: rgba(55, 53, 47, 1);
}
.highlight-gray {
	color: rgba(120, 119, 116, 1);
	fill: rgba(120, 119, 116, 1);
}
.highlight-brown {
	color: rgba(159, 107, 83, 1);
	fill: rgba(159, 107, 83, 1);
}
.highlight-orange {
	color: rgba(217, 115, 13, 1);
	fill: rgba(217, 115, 13, 1);
}
.highlight-yellow {
	color: rgba(203, 145, 47, 1);
	fill: rgba(203, 145, 47, 1);
}
.highlight-teal {
	color: rgba(68, 131, 97, 1);
	fill: rgba(68, 131, 97, 1);
}
.highlight-blue {
	color: rgba(51, 126, 169, 1);
	fill: rgba(51, 126, 169, 1);
}
.highlight-purple {
	color: rgba(144, 101, 176, 1);
	fill: rgba(144, 101, 176, 1);
}
.highlight-pink {
	color: rgba(193, 76, 138, 1);
	fill: rgba(193, 76, 138, 1);
}
.highlight-red {
	color: rgba(212, 76, 71, 1);
	fill: rgba(212, 76, 71, 1);
}
.highlight-default_background {
	color: rgba(55, 53, 47, 1);
}
.highlight-gray_background {
	background: rgba(241, 241, 239, 1);
}
.highlight-brown_background {
	background: rgba(244, 238, 238, 1);
}
.highlight-orange_background {
	background: rgba(251, 236, 221, 1);
}
.highlight-yellow_background {
	background: rgba(251, 243, 219, 1);
}
.highlight-teal_background {
	background: rgba(237, 243, 236, 1);
}
.highlight-blue_background {
	background: rgba(231, 243, 248, 1);
}
.highlight-purple_background {
	background: rgba(244, 240, 247, 0.8);
}
.highlight-pink_background {
	background: rgba(249, 238, 243, 0.8);
}
.highlight-red_background {
	background: rgba(253, 235, 236, 1);
}
.block-color-default {
	color: inherit;
	fill: inherit;
}
.block-color-gray {
	color: rgba(120, 119, 116, 1);
	fill: rgba(120, 119, 116, 1);
}
.block-color-brown {
	color: rgba(159, 107, 83, 1);
	fill: rgba(159, 107, 83, 1);
}
.block-color-orange {
	color: rgba(217, 115, 13, 1);
	fill: rgba(217, 115, 13, 1);
}
.block-color-yellow {
	color: rgba(203, 145, 47, 1);
	fill: rgba(203, 145, 47, 1);
}
.block-color-teal {
	color: rgba(68, 131, 97, 1);
	fill: rgba(68, 131, 97, 1);
}
.block-color-blue {
	color: rgba(51, 126, 169, 1);
	fill: rgba(51, 126, 169, 1);
}
.block-color-purple {
	color: rgba(144, 101, 176, 1);
	fill: rgba(144, 101, 176, 1);
}
.block-color-pink {
	color: rgba(193, 76, 138, 1);
	fill: rgba(193, 76, 138, 1);
}
.block-color-red {
	color: rgba(212, 76, 71, 1);
	fill: rgba(212, 76, 71, 1);
}
.block-color-default_background {
	color: inherit;
	fill: inherit;
}
.block-color-gray_background {
	background: rgba(241, 241, 239, 1);
}
.block-color-brown_background {
	background: rgba(244, 238, 238, 1);
}
.block-color-orange_background {
	background: rgba(251, 236, 221, 1);
}
.block-color-yellow_background {
	background: rgba(251, 243, 219, 1);
}
.block-color-teal_background {
	background: rgba(237, 243, 236, 1);
}
.block-color-blue_background {
	background: rgba(231, 243, 248, 1);
}
.block-color-purple_background {
	background: rgba(244, 240, 247, 0.8);
}
.block-color-pink_background {
	background: rgba(249, 238, 243, 0.8);
}
.block-color-red_background {
	background: rgba(253, 235, 236, 1);
}
.select-value-color-uiBlue { background-color: rgba(35, 131, 226, .07); }
.select-value-color-pink { background-color: rgba(245, 224, 233, 1); }
.select-value-color-purple { background-color: rgba(232, 222, 238, 1); }
.select-value-color-green { background-color: rgba(219, 237, 219, 1); }
.select-value-color-gray { background-color: rgba(227, 226, 224, 1); }
.select-value-color-transparentGray { background-color: rgba(227, 226, 224, 0); }
.select-value-color-translucentGray { background-color: rgba(0, 0, 0, 0.06); }
.select-value-color-orange { background-color: rgba(250, 222, 201, 1); }
.select-value-color-brown { background-color: rgba(238, 224, 218, 1); }
.select-value-color-red { background-color: rgba(255, 226, 221, 1); }
.select-value-color-yellow { background-color: rgba(253, 236, 200, 1); }
.select-value-color-blue { background-color: rgba(211, 229, 239, 1); }
.select-value-color-pageGlass { background-color: undefined; }
.select-value-color-washGlass { background-color: undefined; }

.checkbox {
	display: inline-flex;
	vertical-align: text-bottom;
	width: 16;
	height: 16;
	background-size: 16px;
	margin-left: 2px;
	margin-right: 5px;
}

.checkbox-on {
	background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%2358A9D7%22%2F%3E%0A%3Cpath%20d%3D%22M6.71429%2012.2852L14%204.9995L12.7143%203.71436L6.71429%209.71378L3.28571%206.2831L2%207.57092L6.71429%2012.2852Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E");
}

.checkbox-off {
	background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20x%3D%220.75%22%20y%3D%220.75%22%20width%3D%2214.5%22%20height%3D%2214.5%22%20fill%3D%22white%22%20stroke%3D%22%2336352F%22%20stroke-width%3D%221.5%22%2F%3E%0A%3C%2Fsvg%3E");
}

</style>
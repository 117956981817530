<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container class="pa-0" style="max-width: 1024px;">

        <v-app-bar class="app-bar" color="var(--black-1)" :style="{ borderBottom: '1px solid var(--black-2)' }" dark flat app>
          <v-btn icon @click="backBtnCkd">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <p>{{ $t('storeTitle') }}</p>
        </v-app-bar>

        <v-container class="mt-2" fluid>
          <p style="color: white !important; text-align: left;">{{ $t('storeSubtitle') }} <b>{{ point }}</b></p>
        </v-container>

        <v-container class="pt-2" fluid>
          <v-card v-for="(sku, index) in SKUs" :key="index" class="container--black2 text-left pa-0" :class="{ 'mt-3': index > 0 }">
            <v-card-text class="d-flex align-center justify-space-between" style="color:white; height: 100%;">
              <div class="d-flex align-center">
                <v-img
                  :src="require('@/assets/ic_coin.png')"
                  max-height="10"
                  max-width="10"
                  contain
                  class="d-inline-flex align-center mr-1"
                ></v-img>
                <span class="text-h7"><b>{{ sku.name }}</b></span>
              </div>
              <div class="d-flex align-center">
                <span class="mr-3"></span>
                <p class="white--text mr-2 text-right caption">{{ Math.floor(sku.name / 30) }} {{ $t('storeChat') }}, -{{ (index+2)*10 }}%</p>
                <v-btn style="background-color: var(--red) !important;color: white;" @click="buyBtnCkd(sku)">
                  {{ formatNumber(sku.price) }} ₩
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-container>

        <v-snackbar 
          :min-width="0" 
          v-model="snackbar.visibility"
        >
          <div class="text-center">
            <span style="white-space: normal; word-break: break-word;">
              {{ snackbar.text }}
            </span>
          </div>
        </v-snackbar>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import functions from '../rest/functions';
// import { AnalyticsFunctions } from '../rest/analytics/functions.js';
// import { AnalyticsParams } from '../rest/analytics/params.js';

export default {
  name: 'StoreView',
  data() {
    return {
      point: 0,
      SKUs : [],
      snackbar: {
        text: "",
        visibility: false,
      }
    };
  },
  methods: {
    getMe() {
      this.$store.dispatch('userMe', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID
      })
      .then((result) => {
        if(result.success) {
          this.profileImage = (
            this.$store.getters.getProfileImageThumbnail &&
            this.$store.getters.getProfileImageThumbnail !== "null" &&
            this.$store.getters.getProfileImageThumbnail.trim() !== ""
          ) ? this.$store.getters.getProfileImageThumbnail : require('@/assets/ic_profile_placeholder.png');
          this.point = this.$store.getters.getPoint;
        }
        else {
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
        }
      })
    },
    getSKUs() {
      this.$store.dispatch('billingsGetSKUs')
      .then((result) => {
        if(result.success) {
          this.SKUs = this.$store.getters.getSKUs;
        }
        else {
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
        }
      })
    },
    buyBtnCkd(sku) {
      const encodedSku = encodeURIComponent(JSON.stringify(sku));
      this.$router.push({ path: '/pay', query: { sku: encodedSku } });
    },
    formatNumber(num) {
      return functions.formatNumber(num);
    },
    backBtnCkd() {
      this.$router.go(-1);
    }
  },
  created() {
    if(this.$store.getters.isLoggedIn == null) {
      this.$router.push('/');
      return;
    }
    this.getMe();
    this.getSKUs();
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>

</style>
<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="isKeepAliveEnabled"></router-view>
    </keep-alive>
    <router-view v-if="!isKeepAliveEnabled"></router-view>
    <LoadingView :is-loading="$store.getters.isLoading" />
  </div>
</template>

<script>
import LoadingView from './components/elements/LoadingView.vue'
import shared from './rest/shared'

export default {
  name: 'App',
  components: {
    LoadingView
  },
  computed: {
    isKeepAliveEnabled() {
      return this.$route.meta.keepAlive && !shared.IS_FILTER_APPLYED && !shared.IS_PROFILE_UNSET && !shared.IS_LOGGED_OUT;
    }
  },
  watch: {
    '$route'(to) {
      if(to.meta.keepAlive) {
        if(shared.IS_FILTER_APPLYED) {
          shared.IS_FILTER_APPLYED = false;
          this.$router.go();
        }
        else if(shared.IS_LOGGED_OUT) {
          shared.IS_LOGGED_OUT = false;
          this.$router.go();
        }
      }
    }
  },
  mounted() {
    window.addEventListener('online', this.checkConnection);
    window.addEventListener('offline', this.checkConnection);
  },
  beforeDestroy() {
    window.removeEventListener('online', this.checkConnection);
    window.removeEventListener('offline', this.checkConnection);
  },
  methods: {
    checkConnection() {
      if (navigator.onLine) {
        if (this.$route.path === '/no-internet') {
          this.$router.push('/');
        }
      } else {
        this.$router.push('/no-internet');
      }
    }
  }
}
</script>

<style>

:root {
  --black-1: #1C1D1D;
  --black-2: #323333;
  --black-3: #474747;
  --black-4: #515151;
  --red: #EC6B6B;
  --red-dark: #D34848;
  --green: #75C165;
}

html {
  background-color: var(--black-1);
}

#app {
  -webkit-overflow-scrolling: touch;
  background-color: var(--black-1);
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

p {
  margin: 0 !important;
}

.full-size {
  width: 100%;
  height: 100%;
}

.app-bar {
  max-width: 1024px !important;
  margin: 0 auto !important;
}

.app-icon-elevation {
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(255,255,255,0.1);
  display: inline-block;
  transition: box-shadow 0.3s ease;
}

.app-icon {
  border-radius: 8px;
}

.checkbox {
  margin: 0px !important;
}

.ipt {
  background-color: var(--black-2) !important;
}

.btn-red {
  background-color: var(--red) !important;
}

.v-snack__wrapper {
  width: calc(100% - 48px) !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 24px !important;
}

.highlight {
  background-color: var(--black-1);
  color: white;
  padding: 2px 4px;
  border-radius: 4px;
}

.container--black2 {
  background-color: var(--black-2) !important;
}

</style>

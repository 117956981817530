import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import user from './user';
import other from './other';
import feed from './feed';
import thread from './thread';
import chat from './chat';
import billings from './billings';
import inquiry from './inquiry';
import loading from './loading';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user: user,
    other: other,
    feed: feed,
    thread: thread,
    chat: chat,
    billings: billings,
    loading: loading,
    inquiry: inquiry,
  },
  plugins: [(new VuexPersist({
    storage: window.localStorage,
    reducer: (state) => ({ user: state.user, other: state.other, feed: state.feed, chat: state.chat, billings: state.billings })
  })).plugin]
});
import api from '../../rest/api';

const initialState = {
  refreshToken: null,
  accessToken: null,
  uid: null,
  gender: null,
  nickname: null,
  profileImage: null,
  profileImageThumbnail: null,
  profileStatus: null,
  orientation: null,
  bio: null,
  birth: null,
  location: null,
  playPreferences: null,
  playExperiences: null,
  relationshipType: null,
  point: null,
  fcmToken: null,
  blocked: null,
  deleted: null,
  newChat: null,
  connected: 0,
};

export default {
  state: { ...initialState },
  mutations: {
    resetState(state) {
      Object.assign(state, initialState);
    },
    setRefreshToken(state, value) {
      state.refreshToken = value;
    },
    setAccessToken(state, value) {
      state.accessToken = value;
    },
    setUID(state, value) {
      state.uid = value;
    },
    setGender(state, data) {
      state.gender = data;
    },
    setNickname(state, data) {
      state.nickname = data;
    },
    setProfileImage(state, data) {
      state.profileImage = data;
    },
    setProfileImageThumbnail(state, data) {
      state.profileImageThumbnail = data;
    },
    setProfileStatus(state, data) {
      state.profileStatus = data;
    },
    setOrientation(state, data) {
      state.orientation = data;
    },
    setBio(state, data) {
      state.bio = data;
    },
    setBirth(state, value) {
      state.birth = value;
    },
    setLocation(state, value) {
      state.location = value;
    },
    setPlayPreferences(state, value) {
      state.playPreferences = value;
    },
    setPlayExperiences(state, value) {
      state.playExperiences = value;
    },
    setRelationshipType(state, value) {
      state.relationshipType = value;
    },
    setPoint(state, data) {
      state.point = data;
    },
    setFCMToken(state, data) {
      state.fcmToken = data;
    },
    setBlocked(state, data) {
      state.blocked = data;
    },
    setDeleted(state, data) {
      state.deleted = data;
    },
    setNewChat(state, data) {
      state.newChat = data;
    },
    setConnected(state, data) {
      state.connected = data;
    },
  },
  getters: {
    getRefreshToken(state) {
      return state.refreshToken
    },
    getAccessToken(state) {
      return state.accessToken
    },
    getUID(state) {
      return state.uid
    },
    isLoggedIn(state) {
      return state.uid && state.accessToken && state.refreshToken;
    },
    getGender(state) {
      return state.gender;
    },
    getNickname(state) {
      return state.nickname;
    },
    getProfileImage(state) {
      return state.profileImage;
    },
    getProfileImageThumbnail(state) {
      return state.profileImageThumbnail;
    },
    getProfileStatus(state) {
      return state.profileStatus;
    },
    getOrientation(state) {
      return state.orientation;
    },
    getBio(state) {
      return state.bio;
    },
    getBirth(state) {
      return state.birth;
    },
    getLocation(state) {
      return state.location;
    },
    getPlayPreferences(state) {
      return state.playPreferences;
    },
    getPlayExperiences(state) {
      return state.playExperiences;
    },
    getRelationshipType(state) {
      return state.relationshipType;
    },
    getPoint(state) {
      return state.point;
    },
    getFCMToken(state) {
      return state.fcmToken;
    },
    getBlocked(state) {
      return state.blocked;
    },
    getDeleted(state) {
      return state.deleted;
    },
    getNewChat(state) {
      return state.newChat;
    },
    getConnected(state) {
      return state.connected;
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    async tokenRefresh({ commit }, { accessToken, uid }) {
      try {
        const response = await api.tokenRefresh(accessToken, uid);
        const code = response.data.code;
        if(code == "200") {
          const { refresh_token, access_token, uid } = response.data.data;
          commit('setRefreshToken', refresh_token);
          commit('setAccessToken', access_token);
          commit('setUID', uid);
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed to token refresh:', error);
        return { success: false, code: "500"};
      }
    },
    async userLogin({ commit }, { user_name, password }) {
      try {
        const response = await api.userLogin(user_name, password);
        const code = response.data.code;
        if(code == "200") {
          const { refresh_token, access_token, uid } = response.data.data;
          commit('setRefreshToken', refresh_token);
          commit('setAccessToken', access_token);
          commit('setUID', uid);
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed to user register:', error);
        return { success: false, code: "500"};
      }
    },
    async userRegister({ commit }, { os, user_name, password }) {
      try {
        const response = await api.userRegister(os, user_name, password);
        const code = response.data.code;
        if(code == "200") {
          const { refresh_token, access_token, uid } = response.data.data;
          commit('setRefreshToken', refresh_token);
          commit('setAccessToken', access_token);
          commit('setUID', uid);
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed:', error);
        return { success: false, code: "500"};
      }
    },
    async userInit( _, { accessToken, uid, gender, nickname, orientation_type, orientation, bio, birth, location, playPreferences, playExperiences, relationshipType, profileImage }) {
      try {
        const response = await api.userInit(accessToken, uid, gender, nickname, orientation_type, orientation, bio, birth, location, playPreferences, playExperiences, relationshipType, profileImage);
        const code = response.data.code;
        if(code == "200") {
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed:', error);
        return { success: false, code: "500"};
      }
    },
    async userEdit( _, { accessToken, uid, gender, nickname, orientation_type, orientation, bio, birth, location, playPreferences, playExperiences, relationshipType, profileImage }) {
      try {
        const response = await api.userEdit(accessToken, uid, gender, nickname, orientation_type, orientation, bio, birth, location, playPreferences, playExperiences, relationshipType, profileImage);
        const code = response.data.code;
        if(code == "200") {
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed:', error);
        return { success: false, code: "500"};
      }
    },
    async userMe({ commit }, { accessToken, uid }) {
      try {
        const response = await api.userMe(accessToken, uid);
        const code = response.data.code;
        if(code == "200") {
          const { gender, nickname, profile_image, profile_image_thumbnail, profile_status, orientation, bio, birth, location, play_preferences, play_experiences, relationship_type, point, fcm_token, blocked, deleted, new_chat } = response.data.data;
          commit('setGender', gender);
          commit('setNickname', nickname);
          commit('setProfileImage', profile_image);
          commit('setProfileImageThumbnail', profile_image_thumbnail);
          commit('setProfileStatus', profile_status);
          commit('setOrientation', orientation);
          commit('setBio', bio);
          commit('setBirth', birth);
          commit('setLocation', location);
          commit('setPlayPreferences', play_preferences);
          commit('setPlayExperiences', play_experiences);
          commit('setRelationshipType', relationship_type);
          commit('setPoint', point);
          commit('setFCMToken', fcm_token);
          commit('setBlocked', blocked);
          commit('setDeleted', deleted);
          commit('setNewChat', new_chat);
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed:', error);
        return { success: false, code: "500"};
      }
    },
    async userUpdateFCMToken(_, { accessToken, uid, fcmToken }) {
      try {
        const response = await api.userUpdateFCMToken(accessToken, uid, fcmToken);
        const code = response.data.code;
        if(code == "200") {
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed:', error);
        return { success: false, code: "500"};
      }
    },
    async userUpdate(_, { accessToken, uid }) {
      try {
        const response = await api.userUpdate(accessToken, uid);
        const code = response.data.code;
        if(code == "200") {
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed:', error);
        return { success: false, code: "500"};
      }
    },
    async userReport(_, { accessToken, uid, ouid, contents }) {
      try {
        const response = await api.userReport(accessToken, uid, ouid, contents);
        const code = response.data.code;
        if(code == "200") {
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed:', error);
        return { success: false, code: "500"};
      }
    },
    async userDelete(_, { accessToken, uid }) {
      try {
        const response = await api.userDelete(accessToken, uid);
        const code = response.data.code;
        if(code == "200") {
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed:', error);
        return { success: false, code: "500"};
      }
    },
    async userConnected({commit}) {
      try {
        const response = await api.userConnected();
        const code = response.data.code;
        if(code == "200") {
          commit('setConnected', response.data.data)
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed:', error);
        return { success: false, code: "500"};
      }
    },
  },
};
<template>
  <v-container fluid>

    <article id="9b8259df-90c1-4d9c-987e-5beaea083aa9" class="page sans">
    <div class="text-left page-body">
        <p id="3ca714b6-f237-4b81-8b98-a1f0291a07f9" class="">
        </p>
        <h3 id="07306913-3d27-460e-b6d2-8c1e4e191261" class=""><strong>1. 개요</strong></h3>
        <hr id="01f1c31d-a965-4c7d-b1e5-a0da5e8e5501" />
        <ul id="032e3d31-d44d-45b0-8c8c-548f4d82040a" class="bulleted-list">
            <li style="list-style-type:disc">Deeep는 사용자 개인정보 보호를 최우선 가치로 여기며, 이를 바탕으로 서비스 및 제품 설계, 구축, 운영합니다. 사용자들이 안심하고 의미 있는 인연을 만들 수 있도록 노력하며, 제공된 개인정보는 철저한 보안 시스템을 통해 관리됩니다.</li>
        </ul>
        <ul id="21180cc0-a2ba-4633-9c11-95ceee1bc266" class="bulleted-list">
            <li style="list-style-type:disc">Deeep는 서비스 제공에 필요한 최소한의 개인정보만 수집하고, 과도한 정보 수집을 방지하는 등 사용자 개인정보 보호를 위한 다각적인 노력을 기울입니다.</li>
        </ul>
        <ul id="072a5c78-b43e-4ec2-8878-a47a82f8558e" class="bulleted-list">
            <li style="list-style-type:disc">본 개인정보 처리방침은 Deeep 웹사이트, 앱, 이벤트 및 기타 서비스에서 사용자 정보가 어떻게 수집, 사용, 공유, 보호되는지 명확하게 설명합니다.</li>
        </ul>
        <ul id="cf9bff91-5f9c-424f-8220-320cd94d200a" class="bulleted-list">
            <li style="list-style-type:disc">Deeep는 개인정보 처리방침을 변경할 수 있으며, 변경 내용은 서비스 내 또는 웹사이트에 게시됩니다.</li>
        </ul>
        <p id="d21b71f2-342d-4b05-930c-324b138809a1" class="">
        </p>
        <h3 id="957bee35-158c-4cf9-89fa-14d53b9bc233" class="">2. 적용 범위</h3>
        <hr id="ae67209e-de5c-4284-8b8e-90e991cf5f39" />
        <ul id="51ef642d-9f71-4359-99db-f8a23a7e2189" class="bulleted-list">
            <li style="list-style-type:disc">본 개인정보 처리방침은 Deeep에서 운영하는 모든 웹사이트, 앱, 이벤트 및 기타 서비스에 적용됩니다. 일부 서비스는 별도의 개인정보 처리방침이 있을 수 있으며, 이 경우 해당 방침이 우선 적용됩니다.</li>
        </ul>
        <p id="78d8ef5a-e12a-477d-bc67-e0b4d80be162" class="">
        </p>
        <h3 id="5ede7abb-549f-49f9-9fb2-9f3d0f1c67dc" class="">3. 수집 정보</h3>
        <hr id="5bce4cea-93b8-49e1-bb0c-6cf613891a79" />
        <ul id="70516e67-3665-49e1-85f4-8915856e6eec" class="bulleted-list">
            <li style="list-style-type:disc">Deeep는 사용자에게 의미 있는 인연을 추천하고 서비스를 제공하기 위해 다양한 정보를 수집합니다.</li>
        </ul>
        <ul id="bfcc2eb5-b280-4d56-b0ae-70b1fcdb6515" class="bulleted-list">
            <li style="list-style-type:disc">Deeep 서비스 이용 시, 사용자는 다음과 같은 특정 정보를 제공할 수 있습니다.<ul id="59a52e61-9a53-4527-8bd0-4675b6c279c5" class="bulleted-list">
                    <li style="list-style-type:circle">계정 생성 시 필요한 이름, 전화번호, 이메일, 성별, 생년월일, 비밀번호 등</li>
                </ul>
                <ul id="c2e0b399-9d2f-4811-883f-1cbcefc1740e" class="bulleted-list">
                    <li style="list-style-type:circle">프로필 작성 시 필요한 닉네임, 성향, 지역, 경험, 플레이, 사진 등</li>
                </ul>
                <ul id="95ae8785-d7fe-460e-9424-c6844519ad63" class="bulleted-list">
                    <li style="list-style-type:circle">유료 서비스 이용 시 결제 정보</li>
                </ul>
                <ul id="b6063b14-4366-44a9-8848-bdfa9d1dbcc2" class="bulleted-list">
                    <li style="list-style-type:circle">설문조사, 이벤트 참여 시 제공하는 정보</li>
                </ul>
                <ul id="4046df60-d310-4ac8-9845-d7aca19a4eb3" class="bulleted-list">
                    <li style="list-style-type:circle">고객센터 문의 시 제공하는 정보</li>
                </ul>
                <ul id="59b3fa71-a979-4397-bf55-cb836713a07b" class="bulleted-list">
                    <li style="list-style-type:circle">사용자와 다른 회원 간의 채팅 내용, 게시된 콘텐츠</li>
                </ul>
            </li>
        </ul>
        <ul id="6601ef0c-1794-4811-94ba-0963efdca5ab" class="bulleted-list">
            <li style="list-style-type:disc">Deeep 서비스 이용 시, 다음과 같이 제3자가 제공하는 사용자의 정보가 수집될 수 있습니다.<ul id="4bd443aa-6d4f-47cd-be44-df80c34314e8" class="bulleted-list">
                    <li style="list-style-type:circle">다른 회원이 제공하는 사용자 정보</li>
                </ul>
                <ul id="a3e50a09-b04d-4596-a3dc-82ec20fedb62" class="bulleted-list">
                    <li style="list-style-type:circle">광고 파트너사의 정보 (예: 마케팅 캠페인 결과)</li>
                </ul>
                <ul id="6774eeb6-785e-43d0-9c2a-244d9bf37426" class="bulleted-list">
                    <li style="list-style-type:circle">불량회원 또는 불량회원으로 의심되는 자에 대한 정보</li>
                </ul>
            </li>
        </ul>
        <ul id="5ed52162-4e0f-4117-b25a-183dc115b7d0" class="bulleted-list">
            <li style="list-style-type:disc">다음은 사용자가 서비스를 사용할 때 생성되거나 자동 수집되는 정보입니다.<ul id="230227a5-07d2-4efc-9eac-e673190e9eeb" class="bulleted-list">
                    <li style="list-style-type:circle">서비스 이용 방식(이용 시간, 기능 이용 내역, 활동 내역, 조회한 정보, 참조 웹페이지 주소, 클릭한 광고)</li>
                </ul>
                <ul id="a28090df-cb41-4c01-b90a-34c7cb1f1775" class="bulleted-list">
                    <li style="list-style-type:circle">다른 회원과의 상호작용에 관한 사용자 활동 데이터</li>
                </ul>
                <ul id="b9ad1d1a-6e18-4604-a89c-ffdaa636e518" class="bulleted-list">
                    <li style="list-style-type:circle">사용자 기기 정보 (예: IP 주소, 기기 ID, 앱 설정, 쿠키)</li>
                </ul>
                <ul id="1b95964c-ddcf-412b-be52-db22e6474d3f" class="bulleted-list">
                    <li style="list-style-type:circle">본인 인증 데이터 (예: 이름, 연락처, 생년월일, 성별)</li>
                </ul>
                <ul id="eb162f73-4e5f-4235-afb2-87b89a9fbb2b" class="bulleted-list">
                    <li style="list-style-type:circle">정확한 위치 정보 (예: 사용자의 정확한 위치 정보(위도와 경도))</li>
                </ul>
            </li>
        </ul>
        <p id="2d731958-361c-47ff-a3da-654e079836ee" class="">
        </p>
        <h3 id="a153333b-9f17-4bd4-9ca8-4bdd458ddfd6" class=""><strong>4. 정보 사용 목적</strong></h3>
        <hr id="11913d01-f692-4bf8-bdc2-36c443a4f9e5" />
        <ul id="93db369a-4bc4-48b1-a449-1223bbbbc5df" class="bulleted-list">
            <li style="list-style-type:disc">당사는 회원가입 및 원활한 고객상담, 그리고 특화 서비스 제공을 위해 필수정보와 더불어 개인화 서비스와 기타 다양한 서비스를 제공하기 위하여 선택항목을 수집하고 있습니다.</li>
        </ul>
        <ul id="a0aa1cca-3744-4af5-832e-2e7521ce5ffa" class="bulleted-list">
            <li style="list-style-type:disc">Deeep는 수집된 정보를 다음과 같은 목적으로 사용합니다.<ul id="23eb964a-fd83-4206-9176-b5c6d0625b29" class="bulleted-list">
                    <li style="list-style-type:circle">서비스 제공: 프로필 생성 및 관리, 다른 사용자 연결, 채팅 기능 제공, 결제 처리, 고객 지원 제공</li>
                </ul>
                <ul id="53b4f78e-9b7f-4224-8710-3438928cd62d" class="bulleted-list">
                    <li style="list-style-type:circle">개인 맞춤형 서비스: 사용자에게 맞춤형 콘텐츠 및 광고 제공, 추천 기능 제공</li>
                </ul>
                <ul id="0398d238-69fa-472c-b7b3-06256e1e9a07" class="bulleted-list">
                    <li style="list-style-type:circle">서비스 개선: 서비스 분석 및 개선, 새로운 기능 개발</li>
                </ul>
                <ul id="fe12d512-c59a-4cf9-be17-f1815387f5bb" class="bulleted-list">
                    <li style="list-style-type:circle">안전 및 보안: 사기 방지, 불량회원 차단, 법적 의무 준수</li>
                </ul>
                <ul id="c07f82d8-5bff-4ae1-93d3-8bc416c132bd" class="bulleted-list">
                    <li style="list-style-type:circle">연구 및 개발: 시장 조사, 새로운 서비스 개발</li>
                </ul>
                <p id="dfbb61b2-97b2-45c6-b77c-88c176c3d822" class="">
                </p>
            </li>
        </ul>
        <h3 id="05e1064b-29fd-49b8-9d50-8e31b64dc2b2" class=""><strong>5. 정보 공유</strong></h3>
        <hr id="781a0f7c-c79c-4dad-a5ac-55c6df040a89" />
        <ul id="7886bca2-5811-49de-9121-1767f9bdd1c4" class="bulleted-list">
            <li style="list-style-type:disc">개인정보의 처리를 위탁하는 경우에는 위탁받은 업체가 개인정보보호법에 따라 개인정보를 안전하게 처리하도록 명확히 규정하고 당해 계약내용을 서면 또는 전자적으로 보관하고, 관리·감독하고 있습니다.</li>
        </ul>
        <ul id="a4be1022-1cea-4ac7-a3fe-028d27908c40" class="bulleted-list">
            <li style="list-style-type:disc">Deeep는 다음과 같은 경우에 사용자 정보를 공유할 수 있습니다.<ul id="551801fe-fa93-4c61-9138-17d697db0fd4" class="bulleted-list">
                    <li style="list-style-type:circle">서비스 제공에 필요한 경우: 서비스 운영 및 배포 업체, 결제 처리 업체, 광고 파트너, 데이터 분석 업체 등</li>
                </ul>
                <ul id="29d0503f-9e6e-406c-9ac4-a4fc93c0c3ff" class="bulleted-list">
                    <li style="list-style-type:circle">법령의 규정에 의거하거나, 수사 목적으로 적법한 절차에 따라 수사기관의 요구가 있는 경우</li>
                </ul>
            </li>
        </ul>
        <p id="82861327-e253-4ad2-8569-71834830e7df" class="">
        </p>
        <h3 id="63554916-eb70-434f-acd0-38548c9c39b1" class="">6. 사용자 권리</h3>
        <hr id="f0a44847-6876-453e-89c4-160fad7cc5e3" />
        <ul id="b06d3990-dcb7-4f0f-b82f-e70c1671cc09" class="bulleted-list">
            <li style="list-style-type:disc">Deeep는 다음과 같이 사용자가 자신의 정보를 자유롭게 관리할 수 있도록 다양한 옵션과 도구를 제공합니다.<ul id="48f0b659-2c32-4aeb-8484-71b6afa0d640" class="bulleted-list">
                    <li style="list-style-type:circle">프로필 및 계정 설정을 통해 개인 정보를 사용자가 직접 수정 및 삭제할 수 있습니다.</li>
                </ul>
                <ul id="7d247f31-8df5-4f18-a4b2-cbcf47388c97" class="bulleted-list">
                    <li style="list-style-type:circle">사진, 푸시 알림 등 특정 유형의 데이터 수집 및 알림을 허용 또는 거부할 수 있습니다.</li>
                </ul>
                <ul id="484ec92d-0e30-448a-9ef3-27020e8cfac7" class="bulleted-list">
                    <li style="list-style-type:circle">계정을 삭제하면 정보 수집이 중단됩니다.</li>
                </ul>
                <ul id="770fdb8d-4fb1-4631-b734-1fb9740d1483" class="bulleted-list">
                    <li style="list-style-type:circle">회원 탈퇴 기능을 이용해 계정을 바로 삭제할 수 있습니다.</li>
                </ul>
                <ul id="f68b3ffc-ab77-46e7-90ee-679e2bb1e46a" class="bulleted-list">
                    <li style="list-style-type:circle">고객 지원을 통해 사용자 개인정보를 삭제 요청할 수 있습니다.</li>
                </ul>
            </li>
        </ul>
        <ul id="ec4f2fe8-87f1-4df0-b73d-644bdfff7ccf" class="bulleted-list">
            <li style="list-style-type:disc">Deeep는 상기 요청에 응답하기 전 사용자의 신분 확인을 위해 개인 정보 전달을 요청할 수 있습니다.</li>
        </ul>
        <ul id="5ab5e120-44a4-4398-a364-b08955fb3d43" class="bulleted-list">
            <li style="list-style-type:disc">다른 회원과 관련된 정보는 해당 회원의 동의가 필요합니다.</li>
        </ul>
        <ul id="6383d4e6-2120-4d70-91fc-4a4262fbdcc4" class="bulleted-list">
            <li style="list-style-type:disc">Deeep는 개인정보 처리 반대 또는 제한 요청을 거부할 수 있습니다.</li>
        </ul>
        <p id="f20220dc-0220-4670-9b1b-fa5e1bbfb470" class="">
        </p>
        <h3 id="b62b8678-585d-413e-b453-a33f89aa9817" class=""><strong>7. 사용자 정보 보관 기간</strong></h3>
        <hr id="868707d3-ecf4-4e40-9854-b03ef7934ca4" />
        <ul id="1e68fc8e-2ab5-45f5-8c5f-55218aafff61" class="bulleted-list">
            <li style="list-style-type:disc">Deeep는 법률상의 데이터 보관 의무, 당사의 정당한 이익, 미해결의 또는 잠재적인 문제나 소송, 분쟁 등을 위해 정보를 보관합니다.</li>
        </ul>
        <ul id="311f83af-6b7b-440a-b279-ed58df96eeb5" class="bulleted-list">
            <li style="list-style-type:disc">사용자 활동이 오랜기간 감지되지 않을 경우 해당 계정을 삭제하지 않습니다.</li>
        </ul>
        <ul id="d5abca51-b442-440a-90cf-2761250b5a8b" class="bulleted-list">
            <li style="list-style-type:disc">계정 삭제 또는 계정 차단 후 피해 방지를 위한 차원에서 사용자 개인정보(활동내역, 전화번호 등)는 최대 5년간 보관될 수 있습니다.</li>
        </ul>
        <p id="782cccb4-b7ad-4b6e-a409-7b54f8729fa7" class="">
        </p>
        <h3 id="4c9968e5-ec1c-4875-a7a6-147cadbd885f" class="">8. 당사 연락 방법</h3>
        <hr id="39302633-c7dc-4b8a-a743-c7204ed1459c" />
        <ul id="70bc1a47-aeb9-4656-9760-26ea1ee4cd77" class="bulleted-list">
            <li style="list-style-type:disc">본 개인정보 처리방침과 관련해 궁금한 점이 있을 경우, 다음과 같은 방법으로 문의해주시기 바립니다.<ul id="141a3851-b67c-4f59-bdc7-8df0fce61d4a" class="bulleted-list">
                    <li style="list-style-type:circle">앱 내에서 제보문의 기능을 통해 쉽고 빠르게 문의할 수 있으며, 문의 내용 작성 후 제출하면 14일 이내에 회신을 받을 수 있습니다.</li>
                </ul>
                <ul id="030132c4-8e52-42be-8e39-91c4d17c120c" class="bulleted-list">
                    <li style="list-style-type:circle"><a href="mailto:bdsm.Deeep@gmail.com">bdsm.Deeep@gmail.com</a> 고객 지원팀에 직접 이메일을 보낼 수 있으며, 14일 이내에 회신을 받을 수 있습니다.</li>
                </ul>
            </li>
        </ul>
        <ul id="db5addb7-2bb2-4e3b-92ff-5944af66e5ee" class="bulleted-list">
            <li style="list-style-type:disc">문의 시, 내용을 명확하고 간결하게 작성해주시고, 문제 해결을 위해 필요한 정보(예: 계정 정보, 스크린샷 등)를 첨부해주시면 빠르고 정확하게 답변 받으실 수 있습니다.</li>
        </ul>
        <p id="0daee6d3-88db-4975-be3b-894ca9df37e9" class="">
        </p>
        <p id="e3d8cb8e-5acc-4f4e-907e-3d36a3d68c29" class="">
        </p>
        <p id="fee3704c-f3a1-4d22-b86d-ea46a5dec134" class="">
        </p>
        <p id="07024fcf-22d4-4a14-bed4-db18ac8959c5" class="">[ 발효일 : 2024년 10월 25일 ]</p>
        </div>
    </article><span class="sans" style="font-size:14px;padding-top:2em"></span>
    
  </v-container>
</template>
    
<script>
export default {
  name: 'PrivacyTermsView',
}
</script>

<style scoped>

html {
	-webkit-print-color-adjust: exact;
}
* {
	box-sizing: border-box;
	-webkit-print-color-adjust: exact;
}

html,
body {
	margin: 0;
	padding: 0;
}
@media only screen {
	body {
		margin: 2em auto;
		max-width: 900px;
		color: rgb(55, 53, 47);
	}
}

body {
	line-height: 1.5;
	white-space: pre-wrap;
}

a,
a.visited {
	color: inherit;
	text-decoration: underline;
}

.pdf-relative-link-path {
	font-size: 80%;
	color: #444;
}

h1,
h2,
h3 {
	letter-spacing: -0.01em;
	line-height: 1.2;
	font-weight: 600;
	margin-bottom: 0;
}

.page-title {
	font-size: 2.5rem;
	font-weight: 700;
	margin-top: 0;
	margin-bottom: 0.75em;
}

h1 {
	font-size: 1.875rem;
	margin-top: 1.875rem;
}

h2 {
	font-size: 1.5rem;
	margin-top: 1.5rem;
}

h3 {
	font-size: 1.25rem;
	margin-top: 1.25rem;
}

.source {
	border: 1px solid #ddd;
	border-radius: 3px;
	padding: 1.5em;
	word-break: break-all;
}

.callout {
	border-radius: 3px;
	padding: 1rem;
}

figure {
	margin: 1.25em 0;
	page-break-inside: avoid;
}

figcaption {
	opacity: 0.5;
	font-size: 85%;
	margin-top: 0.5em;
}

mark {
	background-color: transparent;
}

.indented {
	padding-left: 1.5em;
}

hr {
	margin-top: 12px;
	background: transparent;
	display: block;
	width: 100%;
	height: 1px;
	visibility: visible;
	border: none;
	border-bottom: 1px solid rgba(55, 53, 47, 0.09);
}

img {
	max-width: 100%;
}

@media only print {
	img {
		max-height: 100vh;
		object-fit: contain;
	}
}

@page {
	margin: 1in;
}

.collection-content {
	font-size: 0.875rem;
}

.column-list {
	display: flex;
	justify-content: space-between;
}

.column {
	padding: 0 1em;
}

.column:first-child {
	padding-left: 0;
}

.column:last-child {
	padding-right: 0;
}

.table_of_contents-item {
	display: block;
	font-size: 0.875rem;
	line-height: 1.3;
	padding: 0.125rem;
}

.table_of_contents-indent-1 {
	margin-left: 1.5rem;
}

.table_of_contents-indent-2 {
	margin-left: 3rem;
}

.table_of_contents-indent-3 {
	margin-left: 4.5rem;
}

.table_of_contents-link {
	text-decoration: none;
	opacity: 0.7;
	border-bottom: 1px solid rgba(55, 53, 47, 0.18);
}

table,
th,
td {
	border: 1px solid rgba(55, 53, 47, 0.09);
	border-collapse: collapse;
}

table {
	border-left: none;
	border-right: none;
}

th,
td {
	font-weight: normal;
	padding: 0.25em 0.5em;
	line-height: 1.5;
	min-height: 1.5em;
	text-align: left;
}

th {
	color: rgba(55, 53, 47, 0.6);
}

ol,
ul {
	margin: 0;
	margin-block-start: 0.6em;
	margin-block-end: 0.6em;
}

li > ol:first-child,
li > ul:first-child {
	margin-block-start: 0.6em;
}

ul > li {
	list-style: disc;
}

ul.to-do-list {
	padding-inline-start: 0;
}

ul.to-do-list > li {
	list-style: none;
}

.to-do-children-checked {
	text-decoration: line-through;
	opacity: 0.375;
}

ul.toggle > li {
	list-style: none;
}

ul {
	padding-inline-start: 1.7em;
}

ul > li {
	padding-left: 0.1em;
}

ol {
	padding-inline-start: 1.6em;
}

ol > li {
	padding-left: 0.2em;
}

.mono ol {
	padding-inline-start: 2em;
}

.mono ol > li {
	text-indent: -0.4em;
}

.toggle {
	padding-inline-start: 0em;
	list-style-type: none;
}

/* Indent toggle children */
.toggle > li > details {
	padding-left: 1.7em;
}

.toggle > li > details > summary {
	margin-left: -1.1em;
}

.selected-value {
	display: inline-block;
	padding: 0 0.5em;
	background: rgba(206, 205, 202, 0.5);
	border-radius: 3px;
	margin-right: 0.5em;
	margin-top: 0.3em;
	margin-bottom: 0.3em;
	white-space: nowrap;
}

.collection-title {
	display: inline-block;
	margin-right: 1em;
}

.page-description {
    margin-bottom: 2em;
}

.simple-table {
	margin-top: 1em;
	font-size: 0.875rem;
	empty-cells: show;
}
.simple-table td {
	height: 29px;
	min-width: 120px;
}

.simple-table th {
	height: 29px;
	min-width: 120px;
}

.simple-table-header-color {
	background: rgb(247, 246, 243);
	color: black;
}
.simple-table-header {
	font-weight: 500;
}

time {
	opacity: 0.5;
}

.icon {
	display: inline-block;
	max-width: 1.2em;
	max-height: 1.2em;
	text-decoration: none;
	vertical-align: text-bottom;
	margin-right: 0.5em;
}

img.icon {
	border-radius: 3px;
}

.user-icon {
	width: 1.5em;
	height: 1.5em;
	border-radius: 100%;
	margin-right: 0.5rem;
}

.user-icon-inner {
	font-size: 0.8em;
}

.text-icon {
	border: 1px solid #000;
	text-align: center;
}

.page-cover-image {
	display: block;
	object-fit: cover;
	width: 100%;
	max-height: 30vh;
}

.page-header-icon {
	font-size: 3rem;
	margin-bottom: 1rem;
}

.page-header-icon-with-cover {
	margin-top: -0.72em;
	margin-left: 0.07em;
}

.page-header-icon img {
	border-radius: 3px;
}

.link-to-page {
	margin: 1em 0;
	padding: 0;
	border: none;
	font-weight: 500;
}

p > .user {
	opacity: 0.5;
}

td > .user,
td > time {
	white-space: nowrap;
}

input[type="checkbox"] {
	transform: scale(1.5);
	margin-right: 0.6em;
	vertical-align: middle;
}

p {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.image {
	border: none;
	margin: 1.5em 0;
	padding: 0;
	border-radius: 0;
	text-align: center;
}

.code,
code {
	background: rgba(135, 131, 120, 0.15);
	border-radius: 3px;
	padding: 0.2em 0.4em;
	border-radius: 3px;
	font-size: 85%;
	tab-size: 2;
}

code {
	color: #eb5757;
}

.code {
	padding: 1.5em 1em;
}

.code-wrap {
	white-space: pre-wrap;
	word-break: break-all;
}

.code > code {
	background: none;
	padding: 0;
	font-size: 100%;
	color: inherit;
}

blockquote {
	font-size: 1.25em;
	margin: 1em 0;
	padding-left: 1em;
	border-left: 3px solid rgb(55, 53, 47);
}

.bookmark {
	text-decoration: none;
	max-height: 8em;
	padding: 0;
	display: flex;
	width: 100%;
	align-items: stretch;
}

.bookmark-title {
	font-size: 0.85em;
	overflow: hidden;
	text-overflow: ellipsis;
	height: 1.75em;
	white-space: nowrap;
}

.bookmark-text {
	display: flex;
	flex-direction: column;
}

.bookmark-info {
	flex: 4 1 180px;
	padding: 12px 14px 14px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.bookmark-image {
	width: 33%;
	flex: 1 1 180px;
	display: block;
	position: relative;
	object-fit: cover;
	border-radius: 1px;
}

.bookmark-description {
	color: rgba(55, 53, 47, 0.6);
	font-size: 0.75em;
	overflow: hidden;
	max-height: 4.5em;
	word-break: break-word;
}

.bookmark-href {
	font-size: 0.75em;
	margin-top: 0.25em;
}

.sans { font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol"; }
.code { font-family: "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace; }
.serif { font-family: Lyon-Text, Georgia, ui-serif, serif; }
.mono { font-family: iawriter-mono, Nitti, Menlo, Courier, monospace; }
.pdf .sans { font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK JP'; }
.pdf:lang(zh-CN) .sans { font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK SC'; }
.pdf:lang(zh-TW) .sans { font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK TC'; }
.pdf:lang(ko-KR) .sans { font-family: Inter, ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI Variable Display", "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol", 'Twemoji', 'Noto Color Emoji', 'Noto Sans CJK KR'; }
.pdf .code { font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK JP'; }
.pdf:lang(zh-CN) .code { font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK SC'; }
.pdf:lang(zh-TW) .code { font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK TC'; }
.pdf:lang(ko-KR) .code { font-family: Source Code Pro, "SFMono-Regular", Menlo, Consolas, "PT Mono", "Liberation Mono", Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK KR'; }
.pdf .serif { font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji', 'Noto Color Emoji', 'Noto Serif CJK JP'; }
.pdf:lang(zh-CN) .serif { font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji', 'Noto Color Emoji', 'Noto Serif CJK SC'; }
.pdf:lang(zh-TW) .serif { font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji', 'Noto Color Emoji', 'Noto Serif CJK TC'; }
.pdf:lang(ko-KR) .serif { font-family: PT Serif, Lyon-Text, Georgia, ui-serif, serif, 'Twemoji', 'Noto Color Emoji', 'Noto Serif CJK KR'; }
.pdf .mono { font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK JP'; }
.pdf:lang(zh-CN) .mono { font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK SC'; }
.pdf:lang(zh-TW) .mono { font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK TC'; }
.pdf:lang(ko-KR) .mono { font-family: PT Mono, iawriter-mono, Nitti, Menlo, Courier, monospace, 'Twemoji', 'Noto Color Emoji', 'Noto Sans Mono CJK KR'; }
.highlight-default {
	color: rgba(55, 53, 47, 1);
}
.highlight-gray {
	color: rgba(120, 119, 116, 1);
	fill: rgba(120, 119, 116, 1);
}
.highlight-brown {
	color: rgba(159, 107, 83, 1);
	fill: rgba(159, 107, 83, 1);
}
.highlight-orange {
	color: rgba(217, 115, 13, 1);
	fill: rgba(217, 115, 13, 1);
}
.highlight-yellow {
	color: rgba(203, 145, 47, 1);
	fill: rgba(203, 145, 47, 1);
}
.highlight-teal {
	color: rgba(68, 131, 97, 1);
	fill: rgba(68, 131, 97, 1);
}
.highlight-blue {
	color: rgba(51, 126, 169, 1);
	fill: rgba(51, 126, 169, 1);
}
.highlight-purple {
	color: rgba(144, 101, 176, 1);
	fill: rgba(144, 101, 176, 1);
}
.highlight-pink {
	color: rgba(193, 76, 138, 1);
	fill: rgba(193, 76, 138, 1);
}
.highlight-red {
	color: rgba(212, 76, 71, 1);
	fill: rgba(212, 76, 71, 1);
}
.highlight-default_background {
	color: rgba(55, 53, 47, 1);
}
.highlight-gray_background {
	background: rgba(241, 241, 239, 1);
}
.highlight-brown_background {
	background: rgba(244, 238, 238, 1);
}
.highlight-orange_background {
	background: rgba(251, 236, 221, 1);
}
.highlight-yellow_background {
	background: rgba(251, 243, 219, 1);
}
.highlight-teal_background {
	background: rgba(237, 243, 236, 1);
}
.highlight-blue_background {
	background: rgba(231, 243, 248, 1);
}
.highlight-purple_background {
	background: rgba(244, 240, 247, 0.8);
}
.highlight-pink_background {
	background: rgba(249, 238, 243, 0.8);
}
.highlight-red_background {
	background: rgba(253, 235, 236, 1);
}
.block-color-default {
	color: inherit;
	fill: inherit;
}
.block-color-gray {
	color: rgba(120, 119, 116, 1);
	fill: rgba(120, 119, 116, 1);
}
.block-color-brown {
	color: rgba(159, 107, 83, 1);
	fill: rgba(159, 107, 83, 1);
}
.block-color-orange {
	color: rgba(217, 115, 13, 1);
	fill: rgba(217, 115, 13, 1);
}
.block-color-yellow {
	color: rgba(203, 145, 47, 1);
	fill: rgba(203, 145, 47, 1);
}
.block-color-teal {
	color: rgba(68, 131, 97, 1);
	fill: rgba(68, 131, 97, 1);
}
.block-color-blue {
	color: rgba(51, 126, 169, 1);
	fill: rgba(51, 126, 169, 1);
}
.block-color-purple {
	color: rgba(144, 101, 176, 1);
	fill: rgba(144, 101, 176, 1);
}
.block-color-pink {
	color: rgba(193, 76, 138, 1);
	fill: rgba(193, 76, 138, 1);
}
.block-color-red {
	color: rgba(212, 76, 71, 1);
	fill: rgba(212, 76, 71, 1);
}
.block-color-default_background {
	color: inherit;
	fill: inherit;
}
.block-color-gray_background {
	background: rgba(241, 241, 239, 1);
}
.block-color-brown_background {
	background: rgba(244, 238, 238, 1);
}
.block-color-orange_background {
	background: rgba(251, 236, 221, 1);
}
.block-color-yellow_background {
	background: rgba(251, 243, 219, 1);
}
.block-color-teal_background {
	background: rgba(237, 243, 236, 1);
}
.block-color-blue_background {
	background: rgba(231, 243, 248, 1);
}
.block-color-purple_background {
	background: rgba(244, 240, 247, 0.8);
}
.block-color-pink_background {
	background: rgba(249, 238, 243, 0.8);
}
.block-color-red_background {
	background: rgba(253, 235, 236, 1);
}
.select-value-color-uiBlue { background-color: rgba(35, 131, 226, .07); }
.select-value-color-pink { background-color: rgba(245, 224, 233, 1); }
.select-value-color-purple { background-color: rgba(232, 222, 238, 1); }
.select-value-color-green { background-color: rgba(219, 237, 219, 1); }
.select-value-color-gray { background-color: rgba(227, 226, 224, 1); }
.select-value-color-transparentGray { background-color: rgba(227, 226, 224, 0); }
.select-value-color-translucentGray { background-color: rgba(0, 0, 0, 0.06); }
.select-value-color-orange { background-color: rgba(250, 222, 201, 1); }
.select-value-color-brown { background-color: rgba(238, 224, 218, 1); }
.select-value-color-red { background-color: rgba(255, 226, 221, 1); }
.select-value-color-yellow { background-color: rgba(253, 236, 200, 1); }
.select-value-color-blue { background-color: rgba(211, 229, 239, 1); }
.select-value-color-pageGlass { background-color: undefined; }
.select-value-color-washGlass { background-color: undefined; }

.checkbox {
	display: inline-flex;
	vertical-align: text-bottom;
	width: 16;
	height: 16;
	background-size: 16px;
	margin-left: 2px;
	margin-right: 5px;
}

.checkbox-on {
	background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%2358A9D7%22%2F%3E%0A%3Cpath%20d%3D%22M6.71429%2012.2852L14%204.9995L12.7143%203.71436L6.71429%209.71378L3.28571%206.2831L2%207.57092L6.71429%2012.2852Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E");
}

.checkbox-off {
	background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20x%3D%220.75%22%20y%3D%220.75%22%20width%3D%2214.5%22%20height%3D%2214.5%22%20fill%3D%22white%22%20stroke%3D%22%2336352F%22%20stroke-width%3D%221.5%22%2F%3E%0A%3C%2Fsvg%3E");
}

</style>
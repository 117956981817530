import api from '../../rest/api';

const initialState = {
  roomId: null,
  chats: [],
};

export default {
  state: { ...initialState },
  mutations: {
    setRoomId(state, value) {
      state.roomId = value;
    },
    setChats(state, chats) {
      state.chats = chats;
    },
  },
  getters: {
    getRoomId(state) {
      return state.roomId
    },
    getChats(state) {
      return state.chats;
    },
  },
  actions: {
    async chatNew( _ , { accessToken, uid, ouid }) {
      try {
        const response = await api.chatNew(accessToken, uid, ouid);
        const code = response.data.code;
        if(code == "200" || code == "201") {
          return { success: true, data: response.data.data };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed:', error);
        return { success: false, code: "500"};
      }
    },
    async chatSend(_, { accessToken, uid, ouid, roomId, contents, type }) {
      try {
        const response = await api.chatSend(accessToken, uid, ouid, roomId, contents, type);
        const code = response.data.code;
        if (code === "200") {
          return { success: true, data: response.data.data };
        } else {
          return { success: false, code: code };
        }
      } catch (error) {
        console.error('Failed to send message:', error);
        return { success: false, code: "500" };
      }
    },
    async chatGet(_, { accessToken, uid, roomId, offset }) {
      try {
        const response = await api.chatGet(accessToken, uid, roomId, offset);
        const code = response.data.code;
        if (response.data.code === "200") {
          return { success: true, data: response.data.data };
        } else {
          return { success: false, code: code };
        }
      } catch (error) {
        console.error('Failed to get chat messages:', error);
        return { success: false, code: "500" };
      }
    },
    async chatRead(_, { accessToken, uid, roomId }) {
      try {
        const response = await api.chatRead(accessToken, uid, roomId);
        const code = response.data.code;
        if (response.data.code === "200") {
          return { success: true };
        } else {
          return { success: false, code: code };
        }
      } catch (error) {
        console.error('Failed to read chat:', error);
        return { success: false, code: "500" };
      }
    },
    async chatList({ commit }, { accessToken, uid, offset }) {
      try {
        const response = await api.chatList(accessToken, uid, offset);
        const code = response.data.code;
        if(code == "200") {
          commit('setChats', response.data.data);
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed', error);
        return { success: false, code: "500"};
      }
    },
  },
};
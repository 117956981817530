<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1024px;">
        <v-app-bar 
          color="var(--black-1)" 
          :style="{ borderBottom: '1px solid var(--black-2)' }" 
          dark 
          flat 
          class="app-bar" 
          app
        >
          <v-btn icon @click="backBtnCkd">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <p>{{ $t('userReportTitle') }}</p>
        </v-app-bar>
        
        <v-container>

          <v-row>
            <v-col class="pb-1">
              <v-textarea 
                dark 
                :label="$t('userReportContents')"
                rows="5" 
                auto-grow 
                v-model="contents" 
                outlined 
                hide-details 
                color="white" 
                class="ipt mt-0"></v-textarea>
            </v-col>
          </v-row>

        </v-container>

        <v-container>
          <v-btn 
            large
            color="var(--red)" 
            class="mt-2 white--text font-weight-bold" 
            @click="completeBtnCkd" 
            block
          >
            {{ $t('userReportSubmit') }}
          </v-btn>
        </v-container>

        <v-snackbar 
          :min-width="0" 
          v-model="snackbar.visibility"
        >
          <div class="text-center">
            <span style="white-space: normal; word-break: break-word;">
              {{ snackbar.text }}
            </span>
          </div>
        </v-snackbar>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'userReportView',
  data() {
    return {
      ouid: '',
      contents: '',
      snackbar: {
        text: "",
        visibility: false,
      }
    };
  },
  methods: {
    completeBtnCkd() {
      if(this.contents == '') {
        this.snackbar.text = this.$t('userReportContentsEmpty');
        this.snackbar.visibility = true;
        return;
      }
      if(this.contents.length > 5000) {
        this.snackbar.text = this.$t('userReportContentsTooLong');
        this.snackbar.visibility = true;
        return;
      }
      this.$store.dispatch('userReport', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
        ouid: this.ouid,
        contents: this.contents,
      })
      .then((result) => {
        if (result.success) {
          this.backBtnCkd();
        }
        else {
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
        }
      });
    },
    backBtnCkd() {
      this.$router.go(-1);
    }
  },
  created() {
    if (this.$store.getters.isLoggedIn == null) {
      this.$router.push('/');
      return;
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.ouid = this.$store.getters.getOUID;
  },
};
</script>

<style scoped>
</style>
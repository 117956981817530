<template>
  <v-app>
    <v-main class="d-flex align-center justify-center full-size">
      <v-container style="max-width: 1024px;">

        <v-app-bar color="var(--black-1)" :style="{ borderBottom: '1px solid var(--black-2)' }" dark flat class="app-bar" app>
          <v-btn icon @click="backBtnCkd">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <p>{{$t('pwaTitle')}}</p>
        </v-app-bar>

        <v-container class="d-flex flex-column align-center text-center mb-4">
          <v-img :src="require('@/assets/ic_logo.png')" alt="Logo" width="60" height="60" contain></v-img>
          <p class="text-h6 mt-2 white--text" style="font-size: large;" v-html="$t('pwaSubTitle')"></p>
          <a class="mt-2 text-body-2" @click="copyLink">Deeep* 링크 복사하기</a>
        </v-container>
        
        <v-container class="rounded-container mb-5">

          <v-tabs v-model="tab" grow color="var(--black-1)" height="70" fixed-tabs class="rounded-top">
            <v-tab
              v-for="item in tabItems" 
              :key="item.text" 
              class="lowercase custom-tab"
              style="width: 33.33%;">
              <div class="tab-content">
                <v-icon class="os-image">{{ item.icon }}</v-icon>
                <span class="tab-text">{{ item.text }}</span>
              </div>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" class="rounded-bottom pa-0">
            
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <p class="mt-2 mb-4 text-left" style="color: var(--black-1)" v-html="$t('pwaAndroidBrowser')">
                  </p>
                  <v-row>
                    
                    <v-col class="mt-4" cols="12" sm="4">
                      <p class="text-left mb-4" style="color: var(--black-1)" v-html="$t('pwaAndroid1')">
                      </p>
                      <v-img :src="require('@/assets/pwa/android-1.png')" alt="Step 1 Screenshot" />
                    </v-col>
                    <v-col class="mt-4" cols="12" sm="4">
                      <p class="text-left mb-4" style="color: var(--black-1)" v-html="$t('pwaAndroid2')">
                      </p>
                      <v-img :src="require('@/assets/pwa/android-2.png')" alt="Step 2 Screenshot" />
                    </v-col>
                    <v-col class="mt-4" cols="12" sm="4">
                      <p class="text-left mb-4" style="color: var(--black-1)" v-html="$t('pwaAndroid3')">
                      </p>
                      <v-img :src="require('@/assets/pwa/android-3.png')" alt="Step 3 Screenshot" />
                    </v-col>
                    <v-col class="mt-4" cols="12" sm="4">
                      <p class="text-left mb-4" style="color: var(--black-1)" v-html="$t('pwaAndroid4')">
                      </p>
                      <v-img :src="require('@/assets/pwa/android-4.png')" alt="Step 3 Screenshot" />
                    </v-col>

                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-row>
                    
                      <v-col class="mt-4" cols="12" sm="4">
                        <p class="text-left mb-4" style="color: var(--black-1)" v-html="$t('pwaIPhone1')"></p>
                        <v-img :src="require('@/assets/pwa/iphone-1.png')" alt="Step 1 Screenshot" />
                      </v-col>
                      <v-col class="mt-4" cols="12" sm="4">
                        <p class="text-left mb-4" style="color: var(--black-1)" v-html="$t('pwaIPhone2')"></p>
                        <v-img :src="require('@/assets/pwa/iphone-2.png')" alt="Step 2 Screenshot" />
                      </v-col>
                      <v-col class="mt-4" cols="12" sm="4">
                        <p class="text-left mb-4" style="color: var(--black-1)" v-html="$t('pwaIPhone3')"></p>
                        <v-img :src="require('@/assets/pwa/iphone-3.png')" alt="Step 3 Screenshot" />
                      </v-col>

                    </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <p class="mt-2 mb-4 text-left" style="color: var(--black-1)" v-html="$t('pwaDesktopBrowser')">
                  </p>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-img :src="require('@/assets/pwa/desktop-1.png')" alt="Step 3 Screenshot" />
                    </v-col>
                    <v-col cols="12" sm="6" class="d-flex flex-column align-start">
                      <p class="text-left  mb-7" style="color: var(--black-1)" v-html="$t('pwaDesktop1')">
                      </p>
                      <v-btn class="btn-red white--text" style="width:100%" @click="installBtnCkd">
                        {{$t('pwaInstall')}}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-container>
              <p class="text-left mx-1 mb-3" v-html="$t('pwaOtherBrowser')" @click="showOtherBrowserGudie()"></p>
            </v-container>

            <v-container class="text-left" v-if="otherBrowserGuide" style="color: var(--black-1);">
              <p v-html="$t('pwaMobileTitle')" class="text-h6 font-weight-bold"></p>
              <v-container>
                <p v-html="$t('pwaSamsungAndroid')" class="font-weight-bold"></p>
                <p v-html="$t('pwaSamsungAndroid1')" class="mt-2"></p>
                <p v-html="$t('pwaSamsungAndroid2')" class="mt-1"></p>
                <p v-html="$t('pwaSamsungAndroid3')" class="mt-1"></p>
                <p v-html="$t('pwaSamsungAndroid4')" class="mt-1"></p>
              </v-container>
              <v-container>
                <p v-html="$t('pwaFirefoxAndroid')" class="font-weight-bold"></p>
                <p v-html="$t('pwaFirefoxAndroid1')" class="mt-2"></p>
                <p v-html="$t('pwaFirefoxAndroid2')" class="mt-1"></p>
                <p v-html="$t('pwaFirefoxAndroid3')" class="mt-1"></p>
              </v-container>
              <v-container>
                <p v-html="$t('pwaEdgeAndroid')" class="font-weight-bold"></p>
                <p v-html="$t('pwaEdgeAndroid1')" class="mt-2"></p>
                <p v-html="$t('pwaEdgeAndroid2')" class="mt-1"></p>
                <p v-html="$t('pwaEdgeAndroid3')" class="mt-1"></p>
              </v-container>
              <v-container>
                <p v-html="$t('pwaFirefoxIOS')" class="font-weight-bold"></p>
                <p v-html="$t('pwaFirefoxIOS1')" class="mt-2"></p>
                <p v-html="$t('pwaFirefoxIOS2')" class="mt-1"></p>
                <p v-html="$t('pwaFirefoxIOS3')" class="mt-1"></p>
              </v-container>
              <v-container>
                <p v-html="$t('pwaEdgeIOS')" class="font-weight-bold"></p>
                <p v-html="$t('pwaEdgeIOS1')" class="mt-2"></p>
                <p v-html="$t('pwaEdgeIOS2')" class="mt-1"></p>
                <p v-html="$t('pwaEdgeIOS3')" class="mt-1"></p>
              </v-container>
              <p v-html="$t('pwaDesktopTitle')" class="text-h6 font-weight-bold mt-3"></p>
              <v-container>
                <p v-html="$t('pwaEdgeDesktop')" class="font-weight-bold"></p>
                <p v-html="$t('pwaEdgeDesktop1')" class="mt-2"></p>
                <p v-html="$t('pwaEdgeDesktop2')" class="mt-1"></p>
              </v-container>
              <v-container>
                <p v-html="$t('pwaSafariMacOS')" class="font-weight-bold"></p>
                <p v-html="$t('pwaSafariMacOS1')" class="mt-2"></p>
                <p v-html="$t('pwaSafariMacOS2')" class="mt-1"></p>
                <p v-html="$t('pwaSafariMacOS3')" class="mt-1"></p>
              </v-container>
            </v-container>
            
          </v-tabs-items>

        </v-container>

        <v-snackbar 
          :min-width="0" 
          v-model="snackbar.visibility"
        >
          <div class="text-center">
            <span style="white-space: normal; word-break: break-word;">
              {{ snackbar.text }}
            </span>
          </div>
        </v-snackbar>
        
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import functions from '../rest/functions';
import { AnalyticsFunctions } from '../rest/analytics/functions.js';
import { AnalyticsParams } from '../rest/analytics/params.js';

export default {
  name: 'PWAView',
  data() {
    return {
      tab: 0,
      tabItems: [
        { icon: 'mdi-android', text: 'Android' },
        { icon: 'mdi-apple', text: 'iOS' },
        { icon: 'mdi-desktop-mac', text: 'Desktop' }
      ],
      deferredPrompt: null,
      otherBrowserGuide: false,
      snackbar: {
        text: "",
        visibility: false,
      }
    }
  },
  methods: {
    showOtherBrowserGudie() {
      this.otherBrowserGuide = !this.otherBrowserGuide;
    },
    copyLink() {
      const url = 'https://deeep.co.kr';
      navigator.clipboard.writeText(url).then(() => {
        this.snackbar.text = this.$t('pwaLinkCopied');
        this.snackbar.visibility = true;
      }).catch(err => {
        console.error(err);
        this.snackbar.text = this.$t('pwaLinkCopiedFailed');
        this.snackbar.visibility = true;
      });
    },
    installBtnCkd() {
      AnalyticsFunctions.logging(AnalyticsParams.PWA_INSTALL);
      if (!this.deferredPrompt) {
        this.snackbar.text = this.$t('pwaCannotInstall');
        this.snackbar.visibility = true;
        return;
      }
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('installing pwa granted');
        } else {
          console.log('installing pwa refused');
        }
        this.deferredPrompt = null;
      });
    },
    backBtnCkd() {
      this.$router.go(-1);
    }
  },
  mounted() {
    AnalyticsFunctions.logging(AnalyticsParams.PWA_GUIDE);
    window.scrollTo(0, 0);
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
    });
    switch (functions.getOS()) {
      case "android":
        this.tab = 0;
        break;
      case "ios":
        this.tab = 1;
        break;
      case "desktop":
        this.tab = 2;
        break;
      default:
        this.tab = 2;
    }
  },
}
</script>

<style scoped>

.lowercase {
  text-transform: none;
}

.custom-tab {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
}

.tab-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.tab-text {
  white-space: normal;
  text-align: center;
  font-size: 0.8rem;
  line-height: 1.2;
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.os-image {
  margin-bottom: 12px;
  font-size: 1.5rem !important;
}

.rounded-container {
  border-radius: 8px !important;
  overflow: hidden;
  background-color: white;
}

.rounded-top {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 16px !important;
}

.rounded-bottom {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

</style>
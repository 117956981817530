import api from '../../rest/api';

const initialState = {
  ouid: null,
  gender: null,
  nickname: null,
  profileImage: null,
  profileImage_thumbnail: null,
  orientationType: null,
  orientation: null,
  bio: null,
  birth: null,
  location: null,
  playPreferences: null,
  playExperiences: null,
  relationshipType: null,
};

export default {
  state: { ...initialState },
  mutations: {
    setOUID(state, data) {
      state.ouid = data;
    },
    setOGender(state, data) {
      state.gender = data;
    },
    setONickname(state, data) {
      state.nickname = data;
    },
    setOProfileImage(state, data) {
      state.profileImage = data;
    },
    setOProfileImageThumbnail(state, data) {
      state.profileImage_thumbnail = data;
    },
    setOOrientationType(state, data) {
      state.orientationType = data;
    },
    setOOrientation(state, data) {
      state.orientation = data;
    },
    setOBio(state, data) {
      state.bio = data;
    },
    setOBirth(state, value) {
      state.birth = value;
    },
    setOLocation(state, value) {
      state.location = value;
    },
    setOPlayPreferences(state, value) {
      state.playPreferences = value;
    },
    setOPlayExperiences(state, value) {
      state.playExperiences = value;
    },
    setORelationshipType(state, value) {
      state.relationshipType = value;
    },
  },
  getters: {
    getOUID(state) {
      return state.ouid
    },
    getOGender(state) {
      return state.gender;
    },
    getONickname(state) {
      return state.nickname;
    },
    getOProfileImage(state) {
      return state.profileImage;
    },
    getOProfileImageThumbnail(state) {
      return state.profileImage_thumbnail;
    },
    getOOrientationType(state) {
      return state.orientationType;
    },
    getOOrientation(state) {
      return state.orientation;
    },
    getOBio(state) {
      return state.bio;
    },
    getOBirth(state) {
      return state.birth;
    },
    getOLocation(state) {
      return state.location;
    },
    getOPlayPreferences(state) {
      return state.playPreferences;
    },
    getOPlayExperiences(state) {
      return state.playExperiences;
    },
    getORelationshipType(state) {
      return state.relationshipType;
    },
  },
  actions: {
    async userGet({ commit }, { accessToken, ouid }) {
      try {
        const response = await api.userGet(accessToken, ouid);
        const code = response.data.code;
        if(code == "200") {
          const { gender, nickname, profile_image, profile_image_thumbnail, orientation_type, orientation, bio, birth, location, play_preferences, play_experiences, relationship_type } = response.data.data;
          commit('setOUID', ouid);
          commit('setOGender', gender);
          commit('setONickname', nickname);
          commit('setOProfileImage', profile_image);
          commit('setOProfileImageThumbnail', profile_image_thumbnail);
          commit('setOOrientationType', orientation_type);
          commit('setOOrientation', orientation);
          commit('setOBio', bio);
          commit('setOBirth', birth);
          commit('setOLocation', location);
          commit('setOPlayPreferences', play_preferences);
          commit('setOPlayExperiences', play_experiences);
          commit('setORelationshipType', relationship_type);
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed:', error);
        return { success: false, code: "500"};
      }
    },
  },
};
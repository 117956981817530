<template>
  <v-app style="height: 100vh; overflow: auto;">

    <v-main class="d-flex align-center justify-center full-size">
      
      <v-container v-if="!isPWA" class="mb-1 position-relative" fluid app style="position: fixed; top: 0; left: 0; right: 0; z-index: 10;">
        <router-link to="/pwa" class="text-decoration-none">
          <v-row align="center" justify="center" no-gutters class="py-3 ma-0" style="color:white;background-color: var(--black-2);">
            <v-col cols="auto" class="mr-3 d-flex align-center">
              <v-img class="app-icon app-icon-elevation" :src="require('@/assets/ic_app_icon.png')" alt="Logo" width="35" height="35" contain></v-img>
            </v-col>
            <v-col cols="auto" class="d-flex align-center">
              <p class="font-weight-bold caption ma-0">{{ $t('pwaBannerTitle') }}</p>
            </v-col>
          </v-row>
        </router-link>
      </v-container>
    
      <v-container style="max-width: 1024px;">
        <v-row justify="center">

          <v-col cols="12" class="text-center mb-7">
            <v-img :src="require('@/assets/ic_logo.png')" alt="Logo" height="80" width="80" max-width="150" class="mx-auto mb-2" contain></v-img>
            <h2 class="white--text font-weight-bold">
              {{$t('appSubTitle')}}
            </h2>
          </v-col>

          <p class="text-body-2 white--text mb-2">현재 <b style="color:var(--red)">{{ connected }}</b>명의 회원님이 활동 중입니다.</p>

          <v-col cols="12" class="text-center">
            <v-text-field
              v-model="email"
              dark 
              :placeholder="$t('loginEmail')" 
              outlined 
              hide-details 
              color="white" 
              class="ipt mb-4 login-email-ipt"/>
              <v-text-field
              v-model="pw"
              dark 
              :placeholder="$t('loginPassword')" 
              outlined 
              hide-details 
              type="password" 
              color="white" 
              class="ipt mb-4 login-pw-ipt"/>
            <v-btn
              class="btn-red white--text font-weight-bold" 
              block 
              rounded 
              large
              v-on:click="completeBtnCkd">
              {{$t('loginTitle')}}
            </v-btn>
          </v-col>

          <v-col cols="12" class="text-center pa-0">
            <v-btn to="/register" class="white--text" text>
              {{$t('loginRegister')}}
            </v-btn>
          </v-col>

          <v-snackbar 
            :min-width="0" 
            v-model="snackbar.visibility"
          >
            <div class="text-center">
              <span style="white-space: normal; word-break: break-word;">
                {{ snackbar.text }}
              </span>
            </div>
          </v-snackbar>

        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { AnalyticsFunctions } from '../rest/analytics/functions.js';
import { AnalyticsParams } from '../rest/analytics/params.js';
import functions from '../rest/functions';

export default {
  name: 'LoginView',
  data() {
    return {
      isPWA: false,
      connected: 0,
      email: "",
      pw: "",
      snackbar: {
        text: "",
        visibility: false,
      }
    }
  },
  methods: {
    getConnected() {
      this.$store.dispatch('userConnected', {
      })
      .then(result => {
        if(result.success) {
          this.connected = this.$store.getters.getConnected;
        }
      })
    },
    completeBtnCkd() {
      window.scrollTo(0, 0);
      if (this.email === "") {
        this.snackbar.text = this.$t('emailEmpty');
        this.snackbar.visibility = true;
        return;
      }
      if (!functions.isValidEmail(this.email)) {
        this.snackbar.text = this.$t('emailInvalid');
        this.snackbar.visibility = true;
        return;
      }
      if (this.pw === "") {
        this.snackbar.text = this.$t('pwEmpty');
        this.snackbar.visibility = true;
        return;
      }
      this.$store.dispatch('userLogin', {
        user_name: this.email,
        password: this.pw
      })
      .then(result => {
        if (result.success) {
          AnalyticsFunctions.logging(AnalyticsParams.LOGIN);
          this.$router.push('/main');
        } else {
          switch (result.code) {
            case '401':
              this.snackbar.text = this.$t('userInvalid');
              break;
            case '404':
              this.snackbar.text = this.$t('userNotExist');
              break;
            default:
              this.snackbar.text = this.$t('serverError');
              break;
          }
          this.snackbar.visibility = true;
        }
      })
      .catch(error => {
        console.error('Login error:', error);
        this.snackbar.text = this.$t('serverError');
        this.snackbar.visibility = true;
      });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.isPWA = functions.isPWA();
    this.getConnected();
  }
}
</script>

<style scoped>
@media (max-width: 600px) {
  .v-main {
    padding-top: 80px;
  }
}
</style>
import axios from 'axios';

const TOKEN_REFRESH = "https://deeep.co.kr/internal/api/token/refresh.php"

const USER_LOGIN = "https://deeep.co.kr/internal/api/user/login.php";
const USER_REGISTER = "https://deeep.co.kr/internal/api/user/register.php";
const USER_INIT = "https://deeep.co.kr/internal/api/user/init.php";
const USER_EDIT = "https://deeep.co.kr/internal/api/user/edit.php";
const USER_ME = "https://deeep.co.kr/internal/api/user/me.php";
const USER_GET = "https://deeep.co.kr/internal/api/user/get.php";
const USER_UPDATE_FCM_TOKEN = "https://deeep.co.kr/internal/api/user/update_fcm_token.php";
const USER_UPDATE = "https://deeep.co.kr/internal/api/user/update.php";
const USER_REPORT = "https://deeep.co.kr/internal/api/user/report.php";
const USER_DELETE = "https://deeep.co.kr/internal/api/user/delete.php";
const USER_CONNECTED = "https://deeep.co.kr/internal/api/user/connected.php";

const FEED_GET = "https://deeep.co.kr/internal/api/feed/get.php"

const THREAD_GET = "https://deeep.co.kr/internal/api/thread/get.php"
const THREAD_LIKE = "https://deeep.co.kr/internal/api/thread/like.php"
const THREAD_ADD = "https://deeep.co.kr/internal/api/thread/add.php"
const THREAD_REPORT = "https://deeep.co.kr/internal/api/thread/report.php"
const THREAD_DELETE = "https://deeep.co.kr/internal/api/thread/delete.php"

export const CHAT_SERVER = "https://deeep.co.kr:3000";
const CHAT_LIST = "https://deeep.co.kr/internal/api/chat/list.php";
const CHAT_GET = "https://deeep.co.kr/internal/api/chat/get.php";
const CHAT_NEW = "https://deeep.co.kr/internal/api/chat/new.php";
const CHAT_SEND = "https://deeep.co.kr/internal/api/chat/send.php";
const CHAT_READ = "https://deeep.co.kr/internal/api/chat/read.php";

const INQUIRY_WRITE = "https://deeep.co.kr/internal/api/inquiry/write.php";

const BILLINGS_GET_SKUS = "https://deeep.co.kr/internal/api/billings/get_skus.php";
const BILLINGS_PAY = "https://deeep.co.kr/internal/api/billings/pay.php";
const BILLINGS_GET = "https://deeep.co.kr/internal/api/billings/get.php";
const BILLINGS_CANCEL_REQUEST = "https://deeep.co.kr/internal/api/billings/cancel_request.php";

export default {
  tokenRefresh(accessToken, uid) {
    const formData = new FormData();
    formData.append('uid', uid);
    return axios.post(TOKEN_REFRESH, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  userLogin(user_name, pw) {
    const formData = new FormData();
    formData.append('user_name', user_name);
    formData.append('pw', pw);
    return axios.post(USER_LOGIN, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  userRegister(os, user_name, pw) {
    const formData = new FormData();
    formData.append('os', os);
    formData.append('user_name', user_name);
    formData.append('pw', pw);
    return axios.post(USER_REGISTER, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  userInit(accessToken, uid, gender, nickname, orientation_type, orientation, bio, birth, location, playPreferences, playExperiences, relationshipType, profileImage) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('gender', gender);
    formData.append('nickname', nickname);
    formData.append('orientation_type', orientation_type);
    formData.append('orientation', orientation);
    formData.append('bio', bio);
    formData.append('birth', birth);
    formData.append('location', location);
    formData.append('play_preferences', playPreferences);
    formData.append('play_experiences', playExperiences);
    formData.append('relationship_type', relationshipType);
    formData.append('profile_image', profileImage);
    return axios.post(USER_INIT, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  userEdit(accessToken, uid, gender, nickname, orientation_type, orientation, bio, birth, location, playPreferences, playExperiences, relationshipType, profileImage) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('gender', gender);
    formData.append('nickname', nickname);
    formData.append('orientation_type', orientation_type);
    formData.append('orientation', orientation);
    formData.append('bio', bio);
    formData.append('birth', birth);
    formData.append('location', location);
    formData.append('play_preferences', playPreferences);
    formData.append('play_experiences', playExperiences);
    formData.append('relationship_type', relationshipType);
    formData.append('profile_image', profileImage);
    return axios.post(USER_EDIT, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  userMe(accessToken, uid) {
    const formData = new FormData();
    formData.append('uid', uid);
    return axios.post(USER_ME, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  userGet(accessToken, ouid) {
    const formData = new FormData();
    formData.append('ouid', ouid);
    return axios.post(USER_GET, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  userUpdateFCMToken(accessToken, uid, fcmToken) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('fcm_token', fcmToken);
    return axios.post(USER_UPDATE_FCM_TOKEN, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  userUpdate(accessToken, uid) {
    const formData = new FormData();
    formData.append('uid', uid);
    return axios.post(USER_UPDATE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  userReport(accessToken, uid, ouid, contents) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('ouid', ouid);
    formData.append('contents', contents);
    return axios.post(USER_REPORT, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  userDelete(accessToken, uid) {
    const formData = new FormData();
    formData.append('uid', uid);
    return axios.post(USER_DELETE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  userConnected() {
    return axios.post(USER_CONNECTED)
  },
  feedGet(accessToken, uid, filter, order, offset) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('filter', filter);
    formData.append('order', order);
    formData.append('offset', offset);
    return axios.post(FEED_GET, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  threadGet(accessToken, uid, offset) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('offset', offset);
    return axios.post(THREAD_GET, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  threadLike(accessToken, uid, ouid, threadId) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('ouid', ouid);
    formData.append('thread_id', threadId);
    return axios.post(THREAD_LIKE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  threadAdd(accessToken, uid, image) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('image', image);
    return axios.post(THREAD_ADD, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  threadReport(accessToken, uid, threadId) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('thread_id', threadId);
    return axios.post(THREAD_REPORT, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  threadDelete(accessToken, uid, threadId) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('thread_id', threadId);
    return axios.post(THREAD_DELETE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  chatList(accessToken, uid, offset) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('offset', offset);
    return axios.post(CHAT_LIST, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  chatGet(accessToken, uid, roomId, offset) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('room_id', roomId);
    formData.append('offset', offset);
    return axios.post(CHAT_GET, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  chatNew(accessToken, uid, ouid) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('ouid', ouid);
    return axios.post(CHAT_NEW, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  chatSend(accessToken, uid, ouid, roomId, contents, type) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('ouid', ouid);
    formData.append('room_id', roomId);
    formData.append('contents', contents);
    formData.append('type', type);
    return axios.post(CHAT_SEND, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  chatRead(accessToken, uid, roomId) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('room_id', roomId);
    return axios.post(CHAT_READ, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  inquiryWrite(accessToken, uid, email, contents) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('email', email);
    formData.append('contents', contents);
    return axios.post(INQUIRY_WRITE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`,
      }
    })
  },
  billingsGetSKUs() {
    const formData = new FormData();
    return axios.post(BILLINGS_GET_SKUS, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
  },
  billingsPay(accessToken, uid, sku, price, name, contact, card, expiryDate, pw) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('sku', sku);
    formData.append('price', price);
    formData.append('name', name);
    formData.append('contact', contact);
    formData.append('card', card);
    formData.append('expiryDate', expiryDate);
    formData.append('cardPw', pw);
    return axios.post(BILLINGS_PAY, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`
      }
    })
  },
  billingsGet(accessToken, uid) {
    const formData = new FormData();
    formData.append('uid', uid);
    return axios.post(BILLINGS_GET, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`
      }
    })
  },
  billingsCancelRequest(accessToken, uid, oid) {
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('oid', oid);
    return axios.post(BILLINGS_CANCEL_REQUEST, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`
      }
    })
  },
};
<template>
  <v-app class="d-flex justify-center full-size">
    <v-container class="pa-0" style="max-width: 1024px;">
      
      <div class="asd" style="height: 500px;">
        <v-app-bar color="var(--black-1)" :style="{ borderBottom: '1px solid var(--black-2)' }" dark flat class="app-bar">
          <v-btn icon @click="backBtnCkd">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn icon small class="mr-0" @click="profileBtnCkd">
            <v-img :src="other.profileImage" class="rounded-circle" alt="my profile image" width="30" height="30" cover></v-img>
          </v-btn>
          <p class="ml-2 chat-name-view text-body-2" style="font-weight: bold;">{{ other.nickname }}</p>
        </v-app-bar>

        <div class="chat-message-container hide-scrollbar px-2 py-2" ref="chatContainer">
          <div class="pa-2 px-2 mt-2 mb-2" style="max-width: 70%;" v-for="(msg, index) in chat.messages" :key="index" :class="['chat-message', { 'chat-message-other': msg.uid !== uid }]">
            <v-img v-if="msg.type === 'image'" :src="msg.contents" max-width="200" @click="showFullImage(msg.contents)"></v-img>
            <p class="white--text text-left text-body-2" v-else>{{ msg.contents }}</p>
          </div>
        </div>

        <v-footer class="chat-footer" color="var(--black-1)" style="background-color: var(--black-2); margin-bottom: 300px !important;">
          <v-textarea
            ref="chatMessageInput"
            class="text-body-2 black--text chat-message-ipt flex-grow-1 my-1 mr-2 pb-3"
            v-model="chat.newMessage"
            hide-details
            placeholder="메시지 입력"
            rows="1"
            auto-grow
            flat
            solo
            background-color="white"
            style="border-radius: 16px;"
          />
          <v-btn 
            min-width="40" 
            height="40" 
            icon 
            @click="chatSendBtnCkd"
            :disabled="!chat.newMessage.trim()"
            class="flex-shrink-0 mb-3"
            style="margin-right: -8px;"
          >
            <v-icon color="white">mdi-send</v-icon>
          </v-btn>
        </v-footer>
      </div>

      <v-snackbar 
        :min-width="0" 
        v-model="snackbar.visibility"
      >
        <div class="text-center">
          <span style="white-space: normal; word-break: break-word;">
            {{ snackbar.text }}
          </span>
        </div>
      </v-snackbar>

    </v-container>
  </v-app>
</template>

<script>
import {CHAT_SERVER} from '../rest/api'
import io from 'socket.io-client';

export default {
  data() {
    return {
      socket: null,
      uid: '',
      ouid: '',
      roomId: '',
      chat: {
        isInitial: true,
        isLoading: false,
        isDataEnd: false,
        scrollTop: 0,
        scrollHeight: 0,
        newMessage: '',
        messages: [],
        offset: '0',
      },
      other: {
        nickname: '',
        profileImage: require('@/assets/ic_profile_placeholder.png'),
      },
      snackbar: {
        text: "",
        visibility: false,
      }
    };
  },
  methods: {
    getOther() {
      this.$store.dispatch('userGet', {
        accessToken: this.$store.getters.getAccessToken,
        ouid: this.ouid
      })
      .then(result => {
        if(result.success) {
            this.other.nickname = this.$store.getters.getONickname;
            this.other.profileImage = this.$store.getters.getOProfileImageThumbnail || require('@/assets/ic_profile_placeholder.png');
        }
      })
    },
    chatGet() {
      this.chat.isLoading = true
      if(!this.chat.isInitial) {
        this.chat.scrollHeight = this.$refs.chatContainer.scrollHeight;
        this.chat.scrollTop = this.$refs.chatContainer.scrollTop;
      }
      this.$store.dispatch('chatGet', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.uid,
        roomId: this.roomId,
        offset: this.chat.offset
      })
      .then(result => {
          if(result.success) {
            if(result.data != "" && result.data != null) {
              this.chat.messages.unshift(...result.data.reverse());
              this.chat.offset = this.chat.messages[0].id;
            }
            else {
              this.chat.isDataEnd = true;
            }
            if(this.chat.isInitial) {
              this.chat.isInitial = false;
              this.scrollToBottom();
            }
            else {
              this.$nextTick(() => {
                const newScrollHeight = this.$refs.chatContainer.scrollHeight;
                const scrollHeightDiff = newScrollHeight - this.chat.scrollHeight;
                const newScrollTop = this.chat.scrollTop + scrollHeightDiff - 50;
                this.$refs.chatContainer.scrollTop = Math.max(newScrollTop, 0);
              });
            }
            setTimeout(() => {
              this.chat.isLoading = false;
            }, 1000);
          }
      })
      .catch(() => {
        this.chat.isLoading = false;
      });
    },
    chatSendBtnCkd() {
      if (this.chat.newMessage.trim()) {
        const message = {
          uid: this.uid,
          room_id: this.roomId,
          contents: this.chat.newMessage,
          type: 'text'
        };
        this.chat.messages.push(message);
        this.scrollToBottom();
        try {
          this.$store.dispatch('chatSend', {
            accessToken: this.$store.getters.getAccessToken,
            uid: this.uid,
            ouid: this.ouid,
            roomId: this.roomId,
            contents: this.chat.newMessage,
            type: 'text'
          })
          .then(result => {
            if (result.success) {
              this.socket.emit('chat', result.data);
            } else {
              this.snackbar.text = this.$t('serverError');
              this.snackbar.visibility = true;
            }
          });
          this.chat.newMessage = '';
          this.$refs.chatMessageInput.focus();
        } catch (error) {
          console.error('chat send failed: ', error);
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
        }
      }
    },
    chatRead() {
      this.$store.dispatch('chatRead', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.uid,
        roomId: this.roomId
      })
      .then(result => {
        if(result.success) {
            console.log("chat read success")
        }
      })
    },
    setSocket() {
      this.socket = io(CHAT_SERVER, { forceNew: true });
      this.socket.on('connect', () => {
        console.log('Connected to server');
        this.socket.emit('join', this.roomId);
      });
      this.socket.on('chat', (data) => {
        if (data.room_id === this.roomId && data.uid !== this.uid) {
          this.chatRead()
          this.chat.messages.push(data);
          this.scrollToBottom();
        }
      });
    },
    profileBtnCkd() {
      this.$store.commit('setOUID', this.$store.getters.getOUID);
      this.$router.push('/profile');
    },
    backBtnCkd() {
      this.$router.go(-1);
    },
    // rest
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.chatContainer;
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      });
    },
    scrolled() {
      const container = this.$refs.chatContainer;
      if (container.scrollTop <= 10 && !this.chat.isLoading && !this.chat.isDataEnd) {
        this.chat.isLoading = true
        setTimeout(() => {
          this.chatGet();
        }, 1000);
      }
    },
  },
  created() {
    if(this.$store.getters.isLoggedIn == null) {
      this.$router.push('/');
      return;
    }
    this.uid = this.$store.getters.getUID;
    this.ouid = this.$store.getters.getOUID;
    this.roomId = this.$store.getters.getRoomId;
    this.getOther();
    this.chatRead();
    this.setSocket();
    this.chatGet();
  },
  mounted() {
    window.scrollTo(0, document.body.scrollHeight);
    this.$refs.chatContainer.addEventListener('scroll', this.scrolled);
  },
  beforeDestroy() {
    this.$refs.chatContainer.removeEventListener('scroll', this.scrolled);
    if (this.socket) {
      this.socket.off('chat');
      this.socket.disconnect();
    }
  }
};
</script>

<style scoped>

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

textarea {
  resize:none;
}

.chat-footer {
  max-width: 1024px !important;
  background-color: var(--black-2) !important;
}
.chat-message {
  max-width: 256px;
  overflow-wrap: break-word;
  background-color: var(--black-2);
  border-radius: 10px;
  align-self: flex-end;
  display: block;
}
.chat-message-other {
  max-width: 256px;
  overflow-wrap: break-word;
  background-color: var(--black-3);
  border-radius: 10px;
  align-self: flex-start;
  display: block;
}
.chat-message-container {
  height: calc(100vh - 150px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
@media (max-width: 600px) {
  .chat-message-container {
    height: calc(100vh - 135px);
  }
}

</style>
<template>
  <v-app>
    <v-main class="d-flex align-center justify-center full-size">
      <v-container style="max-width: 1024px;">
        <v-row justify="center">

          <v-col cols="12" class="text-center mb-5">
            <v-img :src="require('@/assets/ic_logo.png')" alt="Logo" height="80" width="80" max-width="150" class="mx-auto mb-2" contain></v-img>
            <h2 class="white--text font-weight-light">
              {{$t('appSubTitle')}}
            </h2>
          </v-col>

          <v-col cols="12" class="text-center">
            <v-text-field
              v-model="email"
              dark 
              :placeholder="$t('registerNewEmail')" 
              outlined hide-details 
              color="white" 
              class="ipt mb-4 register-email-ipt"/>
            <v-text-field 
              v-model="pw"
              dark 
              :placeholder="$t('registerNewPassword')" 
              outlined hide-details 
              type="password" 
              color="white" 
              class="ipt mb-4 register-pw-ipt" />
            <v-text-field 
              v-model="rePW"
              dark 
              :placeholder="$t('registerRePassword')" 
              outlined hide-details 
              type="password" 
              color="white" 
              class="ipt mb-4 register-pw-re-ipt" />
            
            <v-col cols="12">
              <v-row align="center" justify="end" class="mb-2">
                <v-checkbox dark v-model="termsAccepted" color="var(--red)" hide-details class="checkbox mr-0 mb-1"></v-checkbox>
                <router-link to="/terms" class="white--text text-decoration-none">
                  <span class="text-body-2">{{$t('registerTerms')}}</span>
                </router-link>
              </v-row>
              <v-row align="center" justify="end">
                <v-checkbox dark v-model="privacyAccepted" color="var(--red)" hide-details class="checkbox mr-0 mb-1"></v-checkbox>
                <router-link to="/terms" class="white--text text-decoration-none">
                  <span class="text-body-2">{{$t('registerPrivacy')}}</span>
                </router-link>
              </v-row>
            </v-col>

            <v-btn 
              class="btn-red white--text mt-4 font-weight-bold" 
              block 
              rounded
              large
              v-on:click="completeBtnCkd">
              {{$t('registerTitle')}}
            </v-btn>
          </v-col>

          <v-col cols="12" class="text-center pa-0">
            <v-btn to="/login" class="white--text" text>{{$t('registerBack')}}</v-btn>
          </v-col>

          <v-snackbar 
          :min-width="0" 
          v-model="snackbar.visibility"
        >
          <div class="text-center">
            <span style="white-space: normal; word-break: break-word;">
              {{ snackbar.text }}
            </span>
          </div>
        </v-snackbar>

        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import functions from '../rest/functions';
import { AnalyticsFunctions } from '../rest/analytics/functions.js';
import { AnalyticsParams } from '../rest/analytics/params.js';

export default {
  name: 'RegisterView',
  data() {
    return {
      email: "",
      pw: "",
      rePW: "",
      termsAccepted: true,
      privacyAccepted: true,
      snackbar: {
        text: "",
        visibility: false,
      }
    }
  },
  methods: {
    async completeBtnCkd() {
      if (this.email === "") {
        this.snackbar.text = this.$t('emailEmpty');
        this.snackbar.visibility = true;
        return;
      }
      if (!functions.isValidEmail(this.email)) {
        this.snackbar.text = this.$t('emailInvalid');
        this.snackbar.visibility = true;
        return;
      }
      if (this.pw === "" || this.rePW === "") {
        this.snackbar.text = this.$t('pwEmpty');
        this.snackbar.visibility = true;
        return;
      }
      if (this.pw !== this.rePW) {
        this.snackbar.text = this.$t('pwUnmatched');
        this.snackbar.visibility = true;
        return;
      }
      if (!this.termsAccepted) {
        this.snackbar.text = this.$t('termsRequired');
        this.snackbar.visibility = true;
        return;
      }
      if (!this.privacyAccepted) {
        this.snackbar.text = this.$t('privacyRequired');
        this.snackbar.visibility = true;
        return;
      }
      this.$store.dispatch('userRegister', {
        os: functions.getOS(),
        user_name: this.email,
        password: this.pw
      })
      .then(result => {
        if (result.success) {
          AnalyticsFunctions.logging(AnalyticsParams.REGISTER);
          this.$router.push('/main');
        } else {
          switch (result.code) {
            case '409':
              this.snackbar.text = this.$t('userExist');
              break;
            default:
              this.snackbar.text = this.$t('serverError');
              break;
          }
          this.snackbar.visibility = true;
        }
      })
      .catch(error => {
        console.error('Registration error:', error);
        this.snackbar.text = this.$t('serverError');
        this.snackbar.visibility = true;
      });
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    if(this.$store.getters.isLoggedIn != null) {
      this.$router.push('/');
      return;
    }
  },
}
</script>

<style scoped>
</style>
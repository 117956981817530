import api from '../../rest/api';

const initialState = {
  feed: {
    users: [],
    filter: '',
  },
};

export default {
  state: { ...initialState },
  mutations: {
    setUsers(state, users) {
      state.feed.users = users;
    },
    setFilter(state, filter) {
      state.feed.filter = filter;
    },
  },
  getters: {
    getUsers(state) {
      return state.feed.users;
    },
    getFilter(state) {
      return state.feed.filter;
    },
  },
  actions: {
    async feedGet({ commit }, { accessToken, uid, filter, order, offset }) {
      try {
        const response = await api.feedGet(accessToken, uid, filter, order, offset);
        const code = response.data.code;
        if(code == "200") {
          commit('setUsers', response.data.data);
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed', error);
        return { success: false, code: "500"};
      }
    },
  },
};
import { render, staticRenderFns } from "./ThreadView.vue?vue&type=template&id=129a2d3f&scoped=true"
import script from "./ThreadView.vue?vue&type=script&lang=js"
export * from "./ThreadView.vue?vue&type=script&lang=js"
import style0 from "./ThreadView.vue?vue&type=style&index=0&id=129a2d3f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "129a2d3f",
  null
  
)

export default component.exports
<template>
  <v-overlay :value="isLoading" z-index="9999">
    <v-img
      :src="require('@/assets/ic_spinner.png')"
      max-height="32"
      max-width="32"
      contain
    ></v-img>
  </v-overlay>
</template>
  
  <script>
  export default {
    name: 'LoadingView',
    props: {
      isLoading: {
        type: Boolean,
        default: false
      }
    }
  }
  </script>
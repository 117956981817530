<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1024px;">

        <v-app-bar color="var(--black-1)" :style="{ borderBottom: '1px solid var(--black-2)' }" dark flat class="app-bar" app>
          <v-btn icon @click="backBtnCkd">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <p>{{$t('profileTitle')}}</p>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-menu
              bottom
              left
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="reportBtnCkd()"
                >
                  <v-list-item-title class="text-body-2">
                    {{ $t('profileReportUser') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-app-bar>

        <v-container class="mt-0">
          <v-row justify="center">
            <v-col cols="auto" class="pa-0">
              <v-img
                class="rounded-circle"
                :src="profileImage"
                width="150"
                height="150"
                cover/>
            </v-col>
          </v-row>

          <v-container class="mt-4">
            <p class="text-h6 profile-nickname-view text-center" style="color: white; font-weight: bold;">{{nickname}}</p>
            <p class="text-body-2 grey--text profile-orientation-view text-center" style="font-weight: bold;">{{ getComputedOrientation(orientation) + ' · ' + getComputedGender(gender) + getComputedAge(birth) }}</p>
          </v-container>

        </v-container>

        <v-container class="px-6 pb-5 d-flex flex-column align-center" style="background-color: var(--black-2); border-radius: 8px;" fluid>

          <v-container class="mt-4 ma-0 pa-0 text-left" fluid>
            <v-chip
                class="mb-3 caption py-1 font-weight-bold"
                color="var(--black-1)"
                text-color="white"
                label
              >
                {{$t('profileBio')}}
              </v-chip>
              <p class="pl-3 profile-bio-view text-left" style="color: white;">{{bio}}</p>
          </v-container>

          <v-container class="mt-4 ma-0 pa-0 text-left" fluid>
            <v-chip
                class="mb-3 caption py-1 font-weight-bold"
                color="var(--black-1)"
                text-color="white"
                label
              >
                {{$t('profileLocation')}}
              </v-chip>
              <p class="pl-3 text-left" style="color: white;">{{location}}</p>
          </v-container>

          <v-container class="mt-6 ma-0 pa-0 text-left" fluid>
            <v-chip
                class="mb-3 caption py-1 font-weight-bold"
                color="var(--black-1)"
                text-color="white"
                label
              >
                {{$t('profilePlayPreferences')}}
              </v-chip>
              <p class="pl-3 text-left" style="color: white;">{{ getComputedPlays(playPreferences) }}</p>
          </v-container>

          <v-container class="mt-6 ma-0 pa-0 text-left" fluid>
            <v-chip
                class="mb-3 caption py-1 font-weight-bold"
                color="var(--black-1)"
                text-color="white"
                label
              >
                {{$t('profilePlayExperiences')}}
              </v-chip>
              <p class="pl-3 text-left" style="color: white;">{{ getComputedPlays(playExperiences) }}</p>
          </v-container>

          <v-container class="mt-6 ma-0 pa-0 text-left" fluid>
            <v-chip
                class="mb-3 caption py-1 font-weight-bold"
                color="var(--black-1)"
                text-color="white"
                label
              >
                {{$t('profileRelationType')}}
              </v-chip>
              <p class="pl-3 text-left" style="color: white;">{{getComputedRelationshipType(relationshipType)}}</p>
          </v-container>
          
        </v-container>

        <v-btn
            large
            class="font-weight-bold white--text btn-red mt-4 mb-6"
            style="width: 100%;margin-bottom: 60px !important;"
            @click.stop="chatBtnCkd()"
          >
          <b>{{ $t('profileViewChat') }}</b>
        </v-btn>

        <v-snackbar 
          :min-width="0" 
          v-model="snackbar.visibility"
        >
          <div class="text-center">
            <span style="white-space: normal; word-break: break-word;">
              {{ snackbar.text }}
            </span>
          </div>
        </v-snackbar>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { AnalyticsFunctions } from '../rest/analytics/functions.js';
import { AnalyticsParams } from '../rest/analytics/params.js';
import functions from '@/rest/functions/index.js';

export default {
  name: 'ProfileView',
  data() {
    return {
      ouid: null,
      profileImage: require('@/assets/ic_profile_placeholder.png'),
      gender: null,
      nickname: null,
      orientation: null,
      bio: null,
      birth: null,
      location: null,
      playPreferences: null,
      playExperiences: null,
      relationshipType: null,
      snackbar: {
        text: "",
        visibility: false,
      }
    }
  },
  computed: {
    getComputedOrientation() {
      return (orientation) => {
        const translated = this.$t(`orientation.${orientation}`);
        return typeof translated === 'string' ? translated : orientation;
      }
    },
    getComputedGender() {
      return (gender) => {
        const translated = this.$t(`gender.${gender}`);
        return typeof translated === 'string' ? translated : gender;
      }
    },
    getComputedAge() {
      return (birth) => {
        if(birth) {
          if(birth == "-") {
            return "";
          }
          else {
            return ' · ' + functions.calculateAge(birth);
          }
        }
      }
    },
    getComputedPlays() {
      return (preferencesString) => {
        if (preferencesString === "-" || preferencesString == "null" || !preferencesString) {
          return "-"; // 기본값 처리
        } else {
          const preferencesArray = preferencesString.split(','); // 쉼표로 구분된 문자열을 배열로 변환
          return preferencesArray
            .map((preference) => {
              switch (preference.trim()) { // 각 항목을 확인
                case "abuse":
                  return this.$t('abuse');
                case "massage":
                  return this.$t('massage');
                case "worship":
                  return this.$t('worship');
                case "biting":
                  return this.$t('biting');
                case "grooming":
                  return this.$t('grooming');
                case "online":
                  return this.$t('online');
                case "cosplay":
                  return this.$t('cosplay');
                case "roleplay":
                  return this.$t('roleplay');
                case "clown":
                  return this.$t('clown');
                case "mannequin":
                  return this.$t('mannequin');
                case "animal":
                  return this.$t('animal');
                case "furniture":
                  return this.$t('furniture');
                case "food":
                  return this.$t('food');
                case "mission":
                  return this.$t('mission');
                case "shame":
                  return this.$t('shame');
                case "neglect":
                  return this.$t('neglect');
                case "resist":
                  return this.$t('resist');
                case "breath_control":
                  return this.$t('breathControl');
                case "orgasm_control":
                  return this.$t('orgasmControl');
                case "mind_control":
                  return this.$t('mindControl');
                case "blind":
                  return this.$t('blind');
                case "bondage":
                  return this.$t('bondage');
                case "hanging":
                  return this.$t('hanging');
                case "spank":
                  return this.$t('spank');
                case "fingering":
                  return this.$t('fingering');
                case "anal":
                  return this.$t('anal');
                case "enema":
                  return this.$t('enema');
                case "spit":
                  return this.$t('spit');
                case "golden_shower":
                  return this.$t('goldenShower');
                case "scat":
                  return this.$t('scat');
                default:
                  return preference;
              }
            })
            .join(', ');
        }
      };
    },
    getComputedRelationshipType() {
      return (relationshipType) => {
        if (relationshipType === "-") {
          return "-";
        } else {
          switch (relationshipType) {
            case "friend":
              return this.$t('friend');
            case "partner":
              return this.$t('partner');
            case "couple":
              return this.$t('couple');
            default:
              return relationshipType; // fallback if the type is not recognized
          }
        }
      };
    },
  },
  methods: {
    getOther() {
      this.$store.dispatch('userGet', {
        accessToken: this.$store.getters.getAccessToken,
        ouid: this.ouid
      })
      .then((result) => {
        if(result.success) {
          var profileImage = this.$store.getters.getOProfileImageThumbnail
          if(profileImage && profileImage !== "null" && profileImage !== "") {
            this.profileImage = profileImage
          }
          this.gender = this.$store.getters.getOGender;
          this.nickname = this.$store.getters.getONickname;
          this.orientation = this.$store.getters.getOOrientation;
          this.bio = this.$store.getters.getOBio;
          this.birth = this.$store.getters.getOBirth;
          this.location = this.$store.getters.getOLocation;
          this.playPreferences = this.$store.getters.getOPlayPreferences;
          this.playExperiences = this.$store.getters.getOPlayExperiences;
          this.relationshipType = this.$store.getters.getORelationshipType;
        }
        else {
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
        }
      });
    },
    reportBtnCkd() {
      this.$router.push('user-report');
    },
    chatBtnCkd() {
      this.$store.dispatch('chatNew', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
        ouid: this.ouid
      })
      .then((result) => {
        if (result.success) {
          AnalyticsFunctions.logging(AnalyticsParams.CHAT_NEW);
          this.$store.commit('setRoomId', result.data);
          this.$store.commit('setOUID', this.ouid);
          this.$router.push('/chat');
        }
        else {
          switch (result.code) {
            case '5002':
              this.snackbar.text = this.$t('chatIntervalTooShort');
              break;
            case '5003':
              this.$router.push('/store')
              break;
            default:
              this.snackbar.text = this.$t('serverError');
              break;
          }
          this.snackbar.visibility = true;
        }
      });
    },
    backBtnCkd() {
      this.$router.go(-1);
    }
  },
  created() {
    window.scrollTo(0, 0);
    if(this.$store.getters.isLoggedIn == null) {
      this.$router.push('/');
      return;
    }
  },
  mounted() {
    this.ouid = this.$store.getters.getOUID;
    this.getOther()
  },
};
</script>

<style scoped>

.profile-bio-view {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

</style>
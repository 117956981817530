<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container class="pa-0" style="max-width: 1024px;">

        <v-app-bar color="var(--black-1)" :style="{ borderBottom: '1px solid var(--black-2)' }" dark flat class="app-bar" app>
          <v-btn icon @click="backBtnCkd">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <p>{{ $t('payTitle') }}</p>
        </v-app-bar>

        <v-container style="margin-bottom: 60px !important;" fluid>

          <v-container class="pa-0 mb-3" fluid>
            <v-card class="container--black2 text-left pa-0">
              <v-card-text class="d-flex align-center justify-space-between" style="color:white; height: 100%;">
                <div class="d-flex align-center">
                  <v-img
                    :src="require('@/assets/ic_coin.png')"
                    max-height="24"
                    max-width="24"
                    contain
                    class="d-inline-flex align-center mr-1"
                  ></v-img>
                  <span class="text-h6"><b>{{ sku.name }}</b></span>
                </div>
                <div class="d-flex align-center">
                  <span class="mr-3">{{ formatNumber(sku.price) }}₩</span>
                </div>
              </v-card-text>
            </v-card>
          </v-container>

          <p class="white--text text-right caption mr-2">{{ $t('payPromotionTxt') }}</p>

          <p class="caption mt-0 text-left mb-3" style="color: white;">{{ $t('payInformationTitle') }}</p>

          <v-text-field
            v-model="billing.name"
            :placeholder="$t('payPlaceholder1')" 
            hide-details
            style="max-width:400px !important;background-color: var(--black-2);"
            rounded
            dark
            class="ipt mb-5 py-2"/>

          <v-text-field
            v-model="billing.contact"
            :placeholder="$t('payPlaceholder2')" 
            hide-details
            style="max-width:400px !important;background-color: var(--gray);"
            rounded 
            dark
            class="ipt mb-5 py-2"/>

          <p class="caption mt-0 text-left mb-3" style="color: white;">{{ $t('payBillingTitle') }}</p>

          <v-text-field
            v-model="billing.card"
            :placeholder="$t('payBillingPlaceholder1')" 
            hide-details
            style="max-width:400px !important;background-color: var(--gray);"
            rounded 
            dark
            class="ipt mb-5 py-2"/>

          <v-text-field
            v-model="billing.expiryDate"
            :placeholder="$t('payBillingPlaceholder2')" 
            hide-details
            style="max-width:400px !important;background-color: var(--gray);"
            rounded
            dark
            class="ipt mb-5 py-2"/>

          <v-text-field
            v-model="billing.pw"
            :placeholder="$t('payBillingPlaceholder3')" 
            hide-details
            style="max-width:400px !important;background-color: var(--gray);"
            rounded
            dark
            class="ipt mb-5 py-2"/>

            <v-col cols="12">
              <v-row align="center" justify="end" class="mb-2">
                <v-checkbox 
                dark
                v-model="billingInfoSave"
                color="var(--red)" 
                hide-details 
                class="checkbox mr-0">
                </v-checkbox>
                <span class="text-body-2 white--text mt-2">{{$t('billingsInfoSave')}}</span>
              </v-row>
            </v-col>

            <v-btn
              color="var(--red)"
              block 
              rounded 
              large
              v-on:click="completeBtnCkd"
              class="white--text font-weight-bold"
            >
              <v-icon left>mdi-lock</v-icon>
              {{$t('payComplete')}}
            </v-btn>

        </v-container>

        <v-snackbar 
          :min-width="0" 
          v-model="snackbar.visibility"
        >
          <div class="text-center">
            <span style="white-space: normal; word-break: break-word;">
              {{ snackbar.text }}
            </span>
          </div>
        </v-snackbar>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import functions from '../rest/functions';
import { AnalyticsFunctions } from '../rest/analytics/functions.js';
import { AnalyticsParams } from '../rest/analytics/params.js';

export default {
  name: 'PayView',
  props: ['encodedSku'],
  data() {
    return {
      sku: null,
      billing: {
        name: '',
        contact: '',
        card: '',
        expiryDate: '',
        pw: '',
      },
      billingInfoSave: true,
      snackbar: {
        text: "",
        visibility: false,
      }
    };
  },
  methods: {
    completeBtnCkd() {

      if(!/^[가-힣a-zA-Z]{2,20}$/.test(this.billing.name)) {
        this.snackbar.text = this.$t('payNameInvalid');
        this.snackbar.visibility = true;
        return;
      }

      if(!/^01[016789]\d{7,8}$/.test(this.billing.contact)) {
        this.snackbar.text = this.$t('payContactInvalid');
        this.snackbar.visibility = true;
        return;
      }

      if(!/^\d{15,16}$/.test(this.billing.card)) {
        this.snackbar.text = this.$t('payCardInvalid');
        this.snackbar.visibility = true;
        return;
      }

      if(!/^\d{4}$/.test(this.billing.expiryDate)) {
        this.snackbar.text = this.$t('payExpiryDateInvalid');
        this.snackbar.visibility = true;
        return;
      }

      if(!/^\d{2}$/.test(this.billing.pw)) {
        this.snackbar.text = this.$t('payPWInvalid');
        this.snackbar.visibility = true;
        return;
      }

      this.$store.dispatch('setLoading', true);
      this.$store.dispatch('billingsPay', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
        sku: this.sku.sku,
        price: this.sku.price,
        name: this.billing.name,
        contact: this.billing.contact,
        card: this.billing.card,
        expiryDate: this.convertExpiryDate(this.billing.expiryDate),
        pw: this.billing.pw,
      })
      .then((result) => {
        if (result.success) {
          switch (this.sku.sku) {
            case "coin100":
              AnalyticsFunctions.logging(AnalyticsParams.PAY_COIN_100);
              break;
            case "coin300":
              AnalyticsFunctions.logging(AnalyticsParams.PAY_COIN_300);
              break;
            case "coin700":
              AnalyticsFunctions.logging(AnalyticsParams.PAY_COIN_700);
              break;
            case "coin1000":
              AnalyticsFunctions.logging(AnalyticsParams.PAY_COIN_1000);
              break;
            case "coin2000":
              AnalyticsFunctions.logging(AnalyticsParams.PAY_COIN_2000);
              break;
            default:
              // default
              break;
          }
          if (this.billingInfoSave) {
            this.$store.commit('setBillingInfo', {
              name: this.billing.name,
              contact: this.billing.contact,
              card: this.billing.card,
              expiryDate: this.billing.expiryDate,
              pw: this.billing.pw,
            });
          }
          this.$router.push('/pay-complete')
        }
        else {
          switch (result.code) {
            case '1146':
              this.snackbar.text = this.$t('payResponse1146');
              break;
            case '3002':
              this.snackbar.text = this.$t('payResponse3002');
              break;
            case '3011':
              this.snackbar.text = this.$t('payResponse3011');
              break;
            case '3021':
              this.snackbar.text = this.$t('payResponse3021');
              break;
            case '3054':
              this.snackbar.text = this.$t('payResponse3054');
              break;
            case '3098':
              this.snackbar.text = this.$t('payResponse3098');
              break;
            case '9998':
              this.snackbar.text = this.$t('payResponse9998');
              break;
            default:
              this.snackbar.text = `${this.$t('serverError')} code: ${result.code}`;
              break;
          }
          this.snackbar.visibility = true;
        }

      })
      .finally(() => {
        this.$store.dispatch('setLoading', false);
      });
    },
    formatNumber(num) {
      return functions.formatNumber(num);
    },
    convertExpiryDate(mmyy) {
      const mm = mmyy.substr(0, 2);
      const yy = mmyy.substr(2, 2);
      return yy + mm;
    },
    backBtnCkd() {
      this.$router.go(-1);
    }
  },
  created() {
    if(this.$store.getters.isLoggedIn == null || !(this.$route.query.sku)) {
      this.$router.push('/');
      return;
    }
    try {
      const decodedSku = decodeURIComponent(this.$route.query.sku);
      this.sku = JSON.parse(decodedSku);
    } catch (error) {
      console.error('Error parsing SKU data:', error);
      this.$router.push('/');
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    if(this.$store.getters.getBillingInfo != []) {
      this.billing = this.$store.getters.getBillingInfo
    }
  },
};
</script>

<style scoped>

</style>
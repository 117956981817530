export const AnalyticsParams = {
	LOGIN: "login",
	REGISTER: "register",
	CHAT_NEW: "chat_new",
	DELETE: "delete",
	PWA_GUIDE: "pwa_guide",
	PWA_INSTALL: "pwa_install",
	PAY_COIN_100: "pay_coin_100",
	PAY_COIN_300: "pay_coin_300",
	PAY_COIN_700: "pay_coin_700",
	PAY_COIN_1000: "pay_coin_1000",
	PAY_COIN_2000: "pay_coin_2000",
};
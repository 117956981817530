export default {
  isPWA() {
    return window.matchMedia('(display-mode: standalone)').matches || 
      window.navigator.standalone || 
      document.referrer.includes('android-app://');
  },
  calculateAge(birthDate) {
    const year = parseInt(birthDate.substring(0, 4));
    const month = parseInt(birthDate.substring(4, 6));
    const day = parseInt(birthDate.substring(6, 8));
    const today = new Date();
    const birthDateObj = new Date(year, month - 1, day); // 월은 0부터 시작하므로 1을 뺍니다
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
      age--;
    }
    return age;
  },
  formatNumber(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  getOS() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const platform = window.navigator.platform.toLowerCase();
    if (/android/.test(userAgent)) {
      return 'android';
    }
    if (/ipad|iphone|ipod/.test(userAgent) && !window.MSStream) {
      return 'ios';
    }
    const isChrome = /chrome/.test(userAgent) && /google inc/.test(navigator.vendor);
    if (isChrome && !/mobile|android|ipad|iphone|ipod/.test(userAgent)) {
      return 'desktop';
    }
    if (/win/.test(platform) || /mac/.test(platform) || /linux/.test(platform)) {
      return 'desktop';
    }
    return 'other';
  },
  isValidEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  },
};
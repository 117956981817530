import api from '../../rest/api';

const initialState = {
  SKUs: [],
  billings: [],
  info: {
    name: '',
    contact: '',
    card: '',
    expiryDate: '',
    pw: '',
  },
};

export default {
  state: { ...initialState },
  mutations: {
    setSKUs(state, SKUs) {
      state.SKUs = SKUs;
    },
    setBillings(state, billings) {
      state.billings = billings;
    },
    setBillingInfo(state, info) {
      state.info = { ...state.info, ...info };
    }
  },
  getters: {
    getSKUs(state) {
      return state.SKUs;
    },
    getBillings(state) {
      return state.billings;
    },
    getBillingInfo(state) {
      return state.info;
    },
  },
  actions: {
    async billingsGetSKUs( {commit} ) {
      try {
        const response = await api.billingsGetSKUs();
        const code = response.data.code;
        if(code == "200") {
          commit('setSKUs', response.data.data);
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed:', error);
        return { success: false, code: "500"};
      }
    },
    async billingsPay( _, { accessToken, uid, sku, price, name, contact, card, expiryDate, pw }) {
      try {
        const response = await api.billingsPay(accessToken, uid, sku, price, name, contact, card, expiryDate, pw);
        const code = response.data.code;
        if(code == "200") {
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed:', error);
        return { success: false, code: "500"};
      }
    },
    async billingsGet( {commit}, { accessToken, uid }) {
      try {
        const response = await api.billingsGet(accessToken, uid);
        const code = response.data.code;
        if(code == "200") {
          commit('setBillings', response.data.data);
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed:', error);
        return { success: false, code: "500"};
      }
    },
    async billingsCancelRequest( _, { accessToken, uid, oid }) {
      try {
        const response = await api.billingsCancelRequest(accessToken, uid, oid);
        const code = response.data.code;
        if(code == "200") {
          return { success: true };
        }
        else {
          return { success: false, code: code};
        }
      } catch (error) {
        console.error('Failed:', error);
        return { success: false, code: "500"};
      }
    },
  }
};
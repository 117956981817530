<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1024px;">

        <v-app-bar color="var(--black-1)" :style="{ borderBottom: '1px solid var(--black-2)' }" dark flat class="app-bar" app>
          <v-btn icon @click="backBtnCkd">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <p>{{$t('termsTitle')}}</p>
        </v-app-bar>

        <v-container class="pa-0" fluid>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-row no-gutters align="center" justify="space-between" style="width: 100%;">
                  <v-col cols="auto">
                    <span class="font-weight-bold">{{$t('termsServiceTitle')}}</span>
                  </v-col>
                  <v-col cols="auto">
                    <span class="text-right mr-2 dark-gray--text" style="font-size:12px;">updated: 2024.10.25</span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>

                <service-terms-view></service-terms-view>

              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>

        <v-container class="mt-3 pa-0" fluid>
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-row no-gutters align="center" justify="space-between" style="width: 100%;">
                  <v-col cols="auto">
                    <span class="font-weight-bold">{{$t('termsPrivacyTitle')}}</span>
                  </v-col>
                  <v-col cols="auto">
                    <span class="text-right mr-2 dark-gray--text" style="font-size:12px;">updated: 2024.10.25</span>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                
                <privacy-terms-view></privacy-terms-view>

              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import ServiceTermsView from './elements/ServiceTermsView.vue'
import PrivacyTermsView from './elements/PrivacyTermsView.vue'

export default {
  name: 'TermsView',
  data() {
    return {
    };
  },
  components: {
    ServiceTermsView,
    PrivacyTermsView
  },
  methods: {
    myBtnCkd() {
      if(this.$store.getters.isLoggedIn) {
        this.$router.push('/me');
      }
      else {
        this.$router.push('/login');
      }
    },
    backBtnCkd() {
      this.$router.go(-1);
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>

</style>
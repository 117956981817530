<template>
  <v-app>
    <v-main class="d-flex justify-center full-size">
      <v-container style="max-width: 1024px;">

        <v-app-bar color="var(--black-1)" :style="{ borderBottom: '1px solid var(--black-2)' }" dark flat class="app-bar" app>
          <v-btn icon @click="backBtnCkd">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <p>
            {{$t('chatTitle')}}
          </p>
        </v-app-bar>

        <v-row 
          v-for="(chat, index) in chat.chats" 
          :key="chat.id" 
          style="cursor: pointer;"
          :class="[
            'align-center mx-0 chat-list-cell',
            index === 0 ? 'mt-0' : 'mt-6',
            { 'unread-chat': chat.sender != $store.getters.getUID && chat.checked == 'false' }
          ]"
          @click="chatBtnCkd(chat.room_id, chat.ouid)"
        >
          <v-col class="d-flex justify-end pr-0 pa-1" cols="auto">
            <v-img 
              :src="chat.profile_image_thumbnail || require('@/assets/ic_profile_placeholder.png')"
              alt="Profile" 
              width="40" 
              height="40"
              class="chat-list-cell-profile-img-view rounded-circle"
            ></v-img>
          </v-col>
          <v-col class="d-flex flex-column text-left pa-1" cols="auto">
            <p class="white--text chat-list-cell-nickname-view text-body-2" style="font-weight: bold;">
              {{ chat.nickname }}
            </p>
            <p class="chat-list-cell-latest-chat-view text-body-2" style="color: white;">
              {{ chat.contents.length > 20 ? chat.contents.slice(0, 10) + '...' : chat.contents }}
            </p>
          </v-col>
        </v-row>

        <v-snackbar 
          :min-width="0" 
          v-model="snackbar.visibility"
        >
          <div class="text-center">
            <span style="white-space: normal; word-break: break-word;">
              {{ snackbar.text }}
            </span>
          </div>
        </v-snackbar>

      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {CHAT_SERVER} from '../rest/api'
import io from 'socket.io-client';

const loadCount = 10;

export default {
  name: 'ChatListView',
  data() {
    return {
      socket: null,
      chat: {
        isLoading: false,
        isDataEnd: false,
        chats: [],
        offset: 0,
      },
      snackbar: {
        text: "",
        visibility: false,
      }
    };
  },
  methods: {
    chatListGet() {
      this.chat.isLoading = true;
      this.$store.dispatch('chatList', {
        accessToken: this.$store.getters.getAccessToken,
        uid: this.$store.getters.getUID,
        offset: this.chat.offset
      })
      .then((result) => {
        if (result.success) {
          const newChats = this.$store.getters.getChats;
          if(newChats.length == 0) {
            this.chat.isDataEnd = true;
          }
          else {
            this.chat.chats = [...this.chat.chats, ...newChats];
            this.chat.offset += loadCount;
          }
        }
        else {
          this.snackbar.text = this.$t('serverError');
          this.snackbar.visibility = true;
        }
      });
      setTimeout(() => {
        this.chat.isLoading = false;
      }, 1500);
    },
    setSocket() {
      this.socket = io(CHAT_SERVER, { forceNew: true });
      this.socket.on('connect', () => {
        console.log('Connected to server');
        this.socket.emit('join', `uid${this.$store.getters.getUID}`);
      });
      this.socket.on('chat', () => {
        this.reload();
      });
    },
    reload() {
      this.isDataEnd = false;
      this.chat.offset = 0;
      this.chat.chats = [];
      this.chatListGet();
    },
    chatBtnCkd(roomId, ouid) {
      this.$store.commit('setRoomId', roomId);
      this.$store.commit('setOUID', ouid);
      this.$router.push('/chat');
    },
    scrolled() {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      if (documentHeight - (scrollPosition + windowHeight) < 100 && !this.chat.isLoading && !this.chat.isDataEnd) {
        this.chatListGet();
      }
    },
    backBtnCkd() {
      this.$router.go(-1);
    }
  },
  created() {
    if(this.$store.getters.isLoggedIn == null) {
      this.$router.push('/');
      return;
    }
    this.setSocket()
    this.chatListGet();
  },
  mounted() {
    window.scrollTo(0, 0);
    window.addEventListener('scroll', this.scrolled);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.scrolled);
    if (this.socket) {
      this.socket.off('chat');
      this.socket.disconnect();
    }
  },
};
</script>

<style scoped>
.chat-list-cell {
 background-color: var(--black-2);
 padding: 10px;
 border-radius: 16px;
}
.unread-chat {
  background-color: var(--black-4);
}
</style>